// import {
//     PROFESSION_LIST_FAIL,
//     PROFESSION_LIST_REQUEST,
//     PROFESSION_LIST_SUCCESS,
//     PROFESSION_CREATE_FAIL,
//     PROFESSION_CREATE_REQUEST,
//     PROFESSION_CREATE_SUCCESS
//   } from "../constants/professionConstants";
import axios from "axios";
 

export const getList = (title,approve,page,active,sort ) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .get(`/api/v1/jobs/list?joblocation=&jobtype=&jobtitle=${title}&slug=&approve=${approve}&page=${page}&ishome=&active=${active}&sort=${sort}&showhome=&showclinet=&id=`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(res => {
      res.data.status = 'success'
      return res.data
    }).catch(err => {
      return {
        error: 'Please login again!',
        status: 'failed',
        message: err.message
      }
    })
}


export const getListdashboard = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .get(`/api/v1/jobs/list?joblocation=&jobtype=&jobtitle=&slug=&approve=&page=&ishome=&active=&sort=&showhome=true&showclinet=&id=`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(res => {
      res.data.status = 'success'
      return res.data
    }).catch(err => {
      return {
        error: 'Please login again!',
        status: 'failed',
        message: err.message
      }
    })
}



export const getListcandidate = (id) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .get(`/api/v1/candidate/findbyjob/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(res => {
      res.data.status = 'success'
      return res.data
    }).catch(err => {
      return {
        error: 'Please login again!',
        status: 'failed',
        message: err.message
      }
    })
}

export const updateStatusCandiate = job => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/candidate/update/${job.id}`,
      {
        status: job.status
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}

export const getjobListclient = () => {
  return axios
    .get('/api/v1/jobsclient/list?home=true&active=true&admin=true', {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => {
      res.data.status = 'success'
      return res.data
    }).catch(err => {
      return {
        error: 'Please login again!',
        status: 'failed',
        message: err.message
      }
    })
}

export const addToList = job => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .post(
      '/api/v1/jobs/add',
      {
        jobtitle: job.jobtitle,
        jobtype: job.jobtype,
        postdate: job.postdate,
        expiredate: job.expiredate,
        companyname: job.companyname,
        joblocation: job.joblocation,
        jobdescription: job.jobdescription,
        priority: job.priority,
        url: job.url,
        ishome: job.ishome,
        active: job.active,
        slug: job.slug,
        psychotest: job.psychotest,
        isapply:job.isapply,
        isapprove:"Disetujui"
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}


export const updateItem = job => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/jobs/update/${job.id}`,
      {
        jobtitle: job.jobtitle,
        jobtype: job.jobtype,
        postdate: job.postdate,
        expiredate: job.expiredate,
        companyname: job.companyname,
        joblocation: job.joblocation,
        jobdescription: job.jobdescription,
        priority: job.priority,
        url: job.url,
        ishome: job.ishome,
        active: job.active,
        slug:job.slug,
        psychotest: job.psychotest,
        isapply:job.isapply
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}

export const updateApprovejobs = job => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/jobs/approve/${job.id}`,
      {
        isapprove: job.isapprove
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}

export const deleteItem = (id) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .delete(`/api/v1/jobs/delete/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}


export const getCity = () => {
  return axios
    .get(`https://resepdigital.prosehat.com/city.php`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}

export const Sendmailblast = (url,jobtitle,jobtype,companyname,joblocation,jobdescription) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  var email
   axios
    .get(`/api/v1/users/list`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(function (response) {
      for (let index = 0; index < response.data['data'].length; index++) {
        email = response.data['data'][index]['email']
        axios.post(
          `/api/v1/events/sendmail`,
          {
            email:email,
            subject:"Job Opportunity",
            body:`
            <!doctype html>
                          <html>
                            <head>
                              <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                              <title>Simple Transactional Email</title>
                              <style>
                                /* -------------------------------------
                                    GLOBAL RESETS
                                ------------------------------------- */
                                
                                /*All the styling goes here*/
                                
                                img {
                                  border: none;
                                  -ms-interpolation-mode: bicubic;
                                  max-width: 100%; 
                                }
                                
                                [data-f-id="pbf"] {
                                  display: none;
                                }
                          
                                body {
                                  background-color: #f6f6f6;
                                  font-family: sans-serif;
                                  -webkit-font-smoothing: antialiased;
                                  font-size: 14px;
                                  line-height: 1.4;
                                  margin: 0;
                                  padding: 0;
                                  -ms-text-size-adjust: 100%;
                                  -webkit-text-size-adjust: 100%; 
                                }
                          
                                table {
                                  border-collapse: separate;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  width: 100%; }
                                  table td {
                                    font-family: sans-serif;
                                    font-size: 14px;
                                    vertical-align: top; 
                                }
                          
                                /* -------------------------------------
                                    BODY & CONTAINER
                                ------------------------------------- */
                          
                                .body {
                                  background-color: #f6f6f6;
                                  width: 100%; 
                                }
                          
                                /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
                                .container {
                                  display: block;
                                  margin: 0 auto !important;
                                  /* makes it centered */
                                  max-width: 580px;
                                  padding: 10px;
                                  width: 580px; 
                                }
                          
                                /* This should also be a block element, so that it will fill 100% of the .container */
                                .content {
                                  box-sizing: border-box;
                                  display: block;
                                  margin: 0 auto;
                                  max-width: 580px;
                                  padding: 10px; 
                                }
                          
                                /* -------------------------------------
                                    HEADER, FOOTER, MAIN
                                ------------------------------------- */
                                .main {
                                  background: #ffffff;
                                  border-radius: 3px;
                                  width: 100%; 
                                }
                          
                                .wrapper {
                                  box-sizing: border-box;
                                  padding: 20px; 
                                }
                          
                                .content-block {
                                  padding-bottom: 10px;
                                  padding-top: 10px;
                                }
                          
                                .footer {
                                  clear: both;
                                  margin-top: 10px;
                                  text-align: center;
                                  width: 100%; 
                                }
                                  .footer td,
                                  .footer p,
                                  .footer span,
                                  .footer a {
                                    color: #999999;
                                    font-size: 12px;
                                    text-align: center; 
                                }
                          
                                /* -------------------------------------
                                    TYPOGRAPHY
                                ------------------------------------- */
                                h1,
                                h2,
                                h3,
                                h4 {
                                  color: #000000;
                                  font-family: sans-serif;
                                  font-weight: 400;
                                  line-height: 1.4;
                                  margin: 0;
                                  margin-bottom: 30px; 
                                }
                          
                                h1 {
                                  font-size: 35px;
                                  font-weight: 300;
                                  text-align: center;
                                  text-transform: capitalize; 
                                }
                          
                                p,
                                ul,
                                ol {
                                  font-family: sans-serif;
                                  font-size: 14px;
                                  font-weight: normal;
                                  margin: 0;
                                  margin-bottom: 15px; 
                                }
                                  p li,
                                  ul li,
                                  ol li {
                                    list-style-position: inside;
                                    margin-left: 5px; 
                                }
                          
                                a {
                                  color: #3498db;
                                  text-decoration: underline; 
                                }
                          
                                /* -------------------------------------
                                    BUTTONS
                                ------------------------------------- */
                                .btn {
                                  box-sizing: border-box;
                                  width: 100%; }
                                  .btn > tbody > tr > td {
                                    padding-bottom: 15px; }
                                  .btn table {
                                    width: auto; 
                                }
                                  .btn table td {
                                    background-color: #ffffff;
                                    border-radius: 5px;
                                    text-align: center; 
                                }
                                  .btn a {
                                    background-color: #ffffff;
                                    border: solid 1px #3498db;
                                    border-radius: 5px;
                                    box-sizing: border-box;
                                    color: #3498db;
                                    cursor: pointer;
                                    display: inline-block;
                                    font-size: 14px;
                                    font-weight: bold;
                                    margin: 0;
                                    padding: 12px 25px;
                                    text-decoration: none;
                                    text-transform: capitalize; 
                                }
                          
                                .btn-primary table td {
                                  background-color: #3498db; 
                                }
                          
                                .btn-primary a {
                                  background-color: #3498db;
                                  border-color: #3498db;
                                  color: #ffffff; 
                                }
                          
                                /* -------------------------------------
                                    OTHER STYLES THAT MIGHT BE USEFUL
                                ------------------------------------- */
                                .last {
                                  margin-bottom: 0; 
                                }
                          
                                .first {
                                  margin-top: 0; 
                                }
                          
                                .align-center {
                                  text-align: center; 
                                }
                          
                                .align-right {
                                  text-align: right; 
                                }
                          
                                .align-left {
                                  text-align: left; 
                                }
                          
                                .clear {
                                  clear: both; 
                                }
                          
                                .mt0 {
                                  margin-top: 0; 
                                }
                          
                                .mb0 {
                                  margin-bottom: 0; 
                                }
                          
                                .preheader {
                                  color: transparent;
                                  display: none;
                                  height: 0;
                                  max-height: 0;
                                  max-width: 0;
                                  opacity: 0;
                                  overflow: hidden;
                                  mso-hide: all;
                                  visibility: hidden;
                                  width: 0; 
                                }
                          
                                .powered-by a {
                                  text-decoration: none; 
                                }
                          
                                hr {
                                  border: 0;
                                  border-bottom: 1px solid #f6f6f6;
                                  margin: 20px 0; 
                                }
                          
                                /* -------------------------------------
                                    RESPONSIVE AND MOBILE FRIENDLY STYLES
                                ------------------------------------- */
                                @media only screen and (max-width: 620px) {
                                  table.body h1 {
                                    font-size: 28px !important;
                                    margin-bottom: 10px !important; 
                                  }
                                  table.body p,
                                  table.body ul,
                                  table.body ol,
                                  table.body td,
                                  table.body span,
                                  table.body a {
                                    font-size: 16px !important; 
                                  }
                                  table.body .wrapper,
                                  table.body .article {
                                    padding: 10px !important; 
                                  }
                                  table.body .content {
                                    padding: 0 !important; 
                                  }
                                  table.body .container {
                                    padding: 0 !important;
                                    width: 100% !important; 
                                  }
                                  table.body .main {
                                    border-left-width: 0 !important;
                                    border-radius: 0 !important;
                                    border-right-width: 0 !important; 
                                  }
                                  table.body .btn table {
                                    width: 100% !important; 
                                  }
                                  table.body .btn a {
                                    width: 100% !important; 
                                  }
                                  table.body .img-responsive {
                                    height: auto !important;
                                    max-width: 100% !important;
                                    width: auto !important; 
                                  }
                                }
                          
                                /* -------------------------------------
                                    PRESERVE THESE STYLES IN THE HEAD
                                ------------------------------------- */
                                @media all {
                                  .ExternalClass {
                                    width: 100%; 
                                  }
                                  .ExternalClass,
                                  .ExternalClass p,
                                  .ExternalClass span,
                                  .ExternalClass font,
                                  .ExternalClass td,
                                  .ExternalClass div {
                                    line-height: 100%; 
                                  }
                                  .apple-link a {
                                    color: inherit !important;
                                    font-family: inherit !important;
                                    font-size: inherit !important;
                                    font-weight: inherit !important;
                                    line-height: inherit !important;
                                    text-decoration: none !important; 
                                  }
                                  #MessageViewBody a {
                                    color: inherit;
                                    text-decoration: none;
                                    font-size: inherit;
                                    font-family: inherit;
                                    font-weight: inherit;
                                    line-height: inherit;
                                  }
                                  .btn-primary table td:hover {
                                    background-color: #34495e !important; 
                                  }
                                  .btn-primary a:hover {
                                    background-color: #34495e !important;
                                    border-color: #34495e !important; 
                                  } 
                                }
                          
                              </style>
                            </head>
                            <body class="">
                              <span class="preheader">This is preheader text. Some clients will show this text as a preview.</span>
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
                                <tr>
                                  <td>&nbsp;</td>
                                  <td class="container">
                                    <div class="content">
                          
                                      <!-- START CENTERED WHITE CONTAINER -->
                                      <table role="presentation" class="main">
                          
                                        <!-- START MAIN CONTENT AREA -->
                                        <tr>
                                          <td class="wrapper">
                                            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                              <tr>
                                                <td>
                                                <center><img width='50%' src='https://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/event/ea423e8d-6f8e-4c50-89f1-6bf5cbea7da3.png'><img/><br/><br/><br/>
                                                <strong>Hai Member DigiHealth</strong><p>Saat ini DigiHealth memiliki lowongan pekerjaan kepada Member DigiHealth maupun rekan Member lainnya.</p>
                                                <strong>Job Title : ${jobtitle}</strong>
                                                <strong>Job Type : ${jobtype}</strong><br>
                                                <strong>Company Name : ${companyname}</strong><br>
                                                <strong>Job Location : ${joblocation}</strong><br>
                                                <strong>Job Description : ${jobdescription}</strong><br>
                                                <p>Silahkan click tombol di bawah ini untuk melihat informasi Job Opportunity.</p>
                                                <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary">
                                                <tbody>
                                                  <tr>
                                                    <td align="center">
                                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                                        <tbody>
                                                          <tr>
                                                            <td> <center><a href="https://digihealth.id/jobs/${url}" target="_blank">Detail Job Opportunity</a> </center></td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                                </table>
                                                <strong>DigiHealth <br> LEARN AND EARN TOGETHER</strong></center>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                          
                                      <!-- END MAIN CONTENT AREA -->
                                      </table>
                                      <!-- END CENTERED WHITE CONTAINER -->
                          
                                      <!-- START FOOTER -->
                                      <div class="footer">
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                          <tr>
                                            <td class="content-block">
                                              <span class="apple-link">Jl. Palmerah Barat No 45 a Grogol Utara, Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12210</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="content-block powered-by">
                                              Powered by <a href="https://digihealth.id">Digihealth.id</a>.
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                      <!-- END FOOTER -->
                          
                                    </div>
                                  </td>
                                  <td>&nbsp;</td>
                                </tr>
                              </table>
                            </body>
                          </html>`
          },
          {
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': token['data']['token']
            }
          }
        )

      }

    })
}

export const SendmailLamaranditinjau = (gelardepan, gelarbelakang, nama, email) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  var job = JSON.parse(localStorage.getItem('payloadjob'));

  return axios
    .post(
      `/api/v1/events/sendmail`,
      {
        email:email,
        subject:"Lamaran Ditinjau",
        body:`
        <!doctype html>
                      <html>
                        <head>
                          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                          <title>Simple Transactional Email</title>
                          <style>
                            /* -------------------------------------
                                GLOBAL RESETS
                            ------------------------------------- */
                            
                            /*All the styling goes here*/
                            
                            img {
                              border: none;
                              -ms-interpolation-mode: bicubic;
                              max-width: 100%; 
                            }
                      
                            body {
                              background-color: #f6f6f6;
                              font-family: sans-serif;
                              -webkit-font-smoothing: antialiased;
                              font-size: 14px;
                              line-height: 1.4;
                              margin: 0;
                              padding: 0;
                              -ms-text-size-adjust: 100%;
                              -webkit-text-size-adjust: 100%; 
                            }
                      
                            table {
                              border-collapse: separate;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              width: 100%; }
                              table td {
                                font-family: sans-serif;
                                font-size: 14px;
                                vertical-align: top; 
                            }
                      
                            /* -------------------------------------
                                BODY & CONTAINER
                            ------------------------------------- */
                      
                            .body {
                              background-color: #f6f6f6;
                              width: 100%; 
                            }
                      
                            /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
                            .container {
                              display: block;
                              margin: 0 auto !important;
                              /* makes it centered */
                              max-width: 580px;
                              padding: 10px;
                              width: 580px; 
                            }
                      
                            /* This should also be a block element, so that it will fill 100% of the .container */
                            .content {
                              box-sizing: border-box;
                              display: block;
                              margin: 0 auto;
                              max-width: 580px;
                              padding: 10px; 
                            }
                      
                            /* -------------------------------------
                                HEADER, FOOTER, MAIN
                            ------------------------------------- */
                            .main {
                              background: #ffffff;
                              border-radius: 3px;
                              width: 100%; 
                            }
                      
                            .wrapper {
                              box-sizing: border-box;
                              padding: 20px; 
                            }
                      
                            .content-block {
                              padding-bottom: 10px;
                              padding-top: 10px;
                            }
                      
                            .footer {
                              clear: both;
                              margin-top: 10px;
                              text-align: center;
                              width: 100%; 
                            }
                              .footer td,
                              .footer p,
                              .footer span,
                              .footer a {
                                color: #999999;
                                font-size: 12px;
                                text-align: center; 
                            }
                      
                            /* -------------------------------------
                                TYPOGRAPHY
                            ------------------------------------- */
                            h1,
                            h2,
                            h3,
                            h4 {
                              color: #000000;
                              font-family: sans-serif;
                              font-weight: 400;
                              line-height: 1.4;
                              margin: 0;
                              margin-bottom: 30px; 
                            }
                      
                            h1 {
                              font-size: 35px;
                              font-weight: 300;
                              text-align: center;
                              text-transform: capitalize; 
                            }
                      
                            p,
                            ul,
                            ol {
                              font-family: sans-serif;
                              font-size: 14px;
                              font-weight: normal;
                              margin: 0;
                              margin-bottom: 15px; 
                            }
                              p li,
                              ul li,
                              ol li {
                                list-style-position: inside;
                                margin-left: 5px; 
                            }
                      
                            a {
                              color: #3498db;
                              text-decoration: underline; 
                            }
                      
                            /* -------------------------------------
                                BUTTONS
                            ------------------------------------- */
                            .btn {
                              box-sizing: border-box;
                              width: 100%; }
                              .btn > tbody > tr > td {
                                padding-bottom: 15px; }
                              .btn table {
                                width: auto; 
                            }
                              .btn table td {
                                background-color: #ffffff;
                                border-radius: 5px;
                                text-align: center; 
                            }
                              .btn a {
                                background-color: #ffffff;
                                border: solid 1px #3498db;
                                border-radius: 5px;
                                box-sizing: border-box;
                                color: #3498db;
                                cursor: pointer;
                                display: inline-block;
                                font-size: 14px;
                                font-weight: bold;
                                margin: 0;
                                padding: 12px 25px;
                                text-decoration: none;
                                text-transform: capitalize; 
                            }
                      
                            .btn-primary table td {
                              background-color: #3498db; 
                            }
                      
                            .btn-primary a {
                              background-color: #3498db;
                              border-color: #3498db;
                              color: #ffffff; 
                            }
                      
                            /* -------------------------------------
                                OTHER STYLES THAT MIGHT BE USEFUL
                            ------------------------------------- */
                            .last {
                              margin-bottom: 0; 
                            }
                      
                            .first {
                              margin-top: 0; 
                            }
                      
                            .align-center {
                              text-align: center; 
                            }
                      
                            .align-right {
                              text-align: right; 
                            }
                      
                            .align-left {
                              text-align: left; 
                            }
                      
                            .clear {
                              clear: both; 
                            }
                      
                            .mt0 {
                              margin-top: 0; 
                            }
                      
                            .mb0 {
                              margin-bottom: 0; 
                            }
                      
                            .preheader {
                              color: transparent;
                              display: none;
                              height: 0;
                              max-height: 0;
                              max-width: 0;
                              opacity: 0;
                              overflow: hidden;
                              mso-hide: all;
                              visibility: hidden;
                              width: 0; 
                            }
                      
                            .powered-by a {
                              text-decoration: none; 
                            }
                      
                            hr {
                              border: 0;
                              border-bottom: 1px solid #f6f6f6;
                              margin: 20px 0; 
                            }
                      
                            /* -------------------------------------
                                RESPONSIVE AND MOBILE FRIENDLY STYLES
                            ------------------------------------- */
                            @media only screen and (max-width: 620px) {
                              table.body h1 {
                                font-size: 28px !important;
                                margin-bottom: 10px !important; 
                              }
                              table.body p,
                              table.body ul,
                              table.body ol,
                              table.body td,
                              table.body span,
                              table.body a {
                                font-size: 16px !important; 
                              }
                              table.body .wrapper,
                              table.body .article {
                                padding: 10px !important; 
                              }
                              table.body .content {
                                padding: 0 !important; 
                              }
                              table.body .container {
                                padding: 0 !important;
                                width: 100% !important; 
                              }
                              table.body .main {
                                border-left-width: 0 !important;
                                border-radius: 0 !important;
                                border-right-width: 0 !important; 
                              }
                              table.body .btn table {
                                width: 100% !important; 
                              }
                              table.body .btn a {
                                width: 100% !important; 
                              }
                              table.body .img-responsive {
                                height: auto !important;
                                max-width: 100% !important;
                                width: auto !important; 
                              }
                            }
                      
                            /* -------------------------------------
                                PRESERVE THESE STYLES IN THE HEAD
                            ------------------------------------- */
                            @media all {
                              .ExternalClass {
                                width: 100%; 
                              }
                              .ExternalClass,
                              .ExternalClass p,
                              .ExternalClass span,
                              .ExternalClass font,
                              .ExternalClass td,
                              .ExternalClass div {
                                line-height: 100%; 
                              }
                              .apple-link a {
                                color: inherit !important;
                                font-family: inherit !important;
                                font-size: inherit !important;
                                font-weight: inherit !important;
                                line-height: inherit !important;
                                text-decoration: none !important; 
                              }
                              #MessageViewBody a {
                                color: inherit;
                                text-decoration: none;
                                font-size: inherit;
                                font-family: inherit;
                                font-weight: inherit;
                                line-height: inherit;
                              }
                              .btn-primary table td:hover {
                                background-color: #34495e !important; 
                              }
                              .btn-primary a:hover {
                                background-color: #34495e !important;
                                border-color: #34495e !important; 
                              } 
                            }
                      
                          </style>
                        </head>
                        <body class="">
                          <span class="preheader">This is preheader text. Some clients will show this text as a preview.</span>
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
                            <tr>
                              <td>&nbsp;</td>
                              <td class="container">
                                <div class="content">
                      
                                  <!-- START CENTERED WHITE CONTAINER -->
                                  <table role="presentation" class="main">
                      
                                    <!-- START MAIN CONTENT AREA -->
                                    <tr>
                                      <td class="wrapper">
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                          <tr>
                                            <td>
                                            <center><img width='50%' src='https://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/event/ea423e8d-6f8e-4c50-89f1-6bf5cbea7da3.png'><img/><br/><br/><br/>
                                            <strong>Lamaran Ditinjau</strong><p>Hi<strong> ${gelardepan ? gelardepan + '.' : ''} ${nama}, ${gelarbelakang ? gelarbelakang + '.' : ''}  </strong>
                                            <p>Terima kasih karena telah mendaftar pada posisi:</p><p>${job[0]['name']}</p><p>${job[0]['company']} - ${job[0]['location']}</p><p>${job[0]['type']}</p><p>Saat ini lamaran Anda sedang kami tinjau, untuk hasil peninjauan lamaran Anda akan kami beritahukan paling lama 14 hari kerja sejak tanggal Anda mengajukan lamaran.</p>
                                            <br>${job[0]['psychotest'] !== '-' && job[0]['psychotest'] !== undefined ? "<p>Silahkan klik tombol di bawah ini untuk melakukan psikotes</p><a href='"+job[0]['psychotest']+"' style='background-color: #008CBA; /* Green */ border: none;color: white;padding: 15px 32px;text-align: center;text-decoration: none;display: inline-block;font-size: 16px;' target='_blank' >Psikotes</a>" :''}<br><br><br>
                                            <strong>DigiHealth <br> LEARN AND EARN TOGETHER</strong></center>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                      
                                  <!-- END MAIN CONTENT AREA -->
                                  </table>
                                  <!-- END CENTERED WHITE CONTAINER -->
                      
                                  <!-- START FOOTER -->
                                  <div class="footer">
                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td class="content-block">
                                          <span class="apple-link">Jl. Palmerah Barat No 45 a Grogol Utara, Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12210</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="content-block powered-by">
                                          Powered by <a href="https://digihealth.id">Digihealth.id</a>.
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                  <!-- END FOOTER -->
                      
                                </div>
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                          </table>
                        </body>
                      </html>`
                          },
      {
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': token['data']['token']
        }
      }
    )
}

export const SendmailDiundangwawancara = (gelardepan, gelarbelakang, nama, email) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  var job = JSON.parse(localStorage.getItem('payloadjob'));

  return axios
    .post(
      `/api/v1/events/sendmail`,
      {
        email:email,
        subject:"Diundang Wawancara",
        body:`
        <!doctype html>
                      <html>
                        <head>
                          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                          <title>Simple Transactional Email</title>
                          <style>
                            /* -------------------------------------
                                GLOBAL RESETS
                            ------------------------------------- */
                            
                            /*All the styling goes here*/
                            
                            img {
                              border: none;
                              -ms-interpolation-mode: bicubic;
                              max-width: 100%; 
                            }
                      
                            body {
                              background-color: #f6f6f6;
                              font-family: sans-serif;
                              -webkit-font-smoothing: antialiased;
                              font-size: 14px;
                              line-height: 1.4;
                              margin: 0;
                              padding: 0;
                              -ms-text-size-adjust: 100%;
                              -webkit-text-size-adjust: 100%; 
                            }
                      
                            table {
                              border-collapse: separate;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              width: 100%; }
                              table td {
                                font-family: sans-serif;
                                font-size: 14px;
                                vertical-align: top; 
                            }
                      
                            /* -------------------------------------
                                BODY & CONTAINER
                            ------------------------------------- */
                      
                            .body {
                              background-color: #f6f6f6;
                              width: 100%; 
                            }
                      
                            /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
                            .container {
                              display: block;
                              margin: 0 auto !important;
                              /* makes it centered */
                              max-width: 580px;
                              padding: 10px;
                              width: 580px; 
                            }
                      
                            /* This should also be a block element, so that it will fill 100% of the .container */
                            .content {
                              box-sizing: border-box;
                              display: block;
                              margin: 0 auto;
                              max-width: 580px;
                              padding: 10px; 
                            }
                      
                            /* -------------------------------------
                                HEADER, FOOTER, MAIN
                            ------------------------------------- */
                            .main {
                              background: #ffffff;
                              border-radius: 3px;
                              width: 100%; 
                            }
                      
                            .wrapper {
                              box-sizing: border-box;
                              padding: 20px; 
                            }
                      
                            .content-block {
                              padding-bottom: 10px;
                              padding-top: 10px;
                            }
                      
                            .footer {
                              clear: both;
                              margin-top: 10px;
                              text-align: center;
                              width: 100%; 
                            }
                              .footer td,
                              .footer p,
                              .footer span,
                              .footer a {
                                color: #999999;
                                font-size: 12px;
                                text-align: center; 
                            }
                      
                            /* -------------------------------------
                                TYPOGRAPHY
                            ------------------------------------- */
                            h1,
                            h2,
                            h3,
                            h4 {
                              color: #000000;
                              font-family: sans-serif;
                              font-weight: 400;
                              line-height: 1.4;
                              margin: 0;
                              margin-bottom: 30px; 
                            }
                      
                            h1 {
                              font-size: 35px;
                              font-weight: 300;
                              text-align: center;
                              text-transform: capitalize; 
                            }
                      
                            p,
                            ul,
                            ol {
                              font-family: sans-serif;
                              font-size: 14px;
                              font-weight: normal;
                              margin: 0;
                              margin-bottom: 15px; 
                            }
                              p li,
                              ul li,
                              ol li {
                                list-style-position: inside;
                                margin-left: 5px; 
                            }
                      
                            a {
                              color: #3498db;
                              text-decoration: underline; 
                            }
                      
                            /* -------------------------------------
                                BUTTONS
                            ------------------------------------- */
                            .btn {
                              box-sizing: border-box;
                              width: 100%; }
                              .btn > tbody > tr > td {
                                padding-bottom: 15px; }
                              .btn table {
                                width: auto; 
                            }
                              .btn table td {
                                background-color: #ffffff;
                                border-radius: 5px;
                                text-align: center; 
                            }
                              .btn a {
                                background-color: #ffffff;
                                border: solid 1px #3498db;
                                border-radius: 5px;
                                box-sizing: border-box;
                                color: #3498db;
                                cursor: pointer;
                                display: inline-block;
                                font-size: 14px;
                                font-weight: bold;
                                margin: 0;
                                padding: 12px 25px;
                                text-decoration: none;
                                text-transform: capitalize; 
                            }
                      
                            .btn-primary table td {
                              background-color: #3498db; 
                            }
                      
                            .btn-primary a {
                              background-color: #3498db;
                              border-color: #3498db;
                              color: #ffffff; 
                            }
                      
                            /* -------------------------------------
                                OTHER STYLES THAT MIGHT BE USEFUL
                            ------------------------------------- */
                            .last {
                              margin-bottom: 0; 
                            }
                      
                            .first {
                              margin-top: 0; 
                            }
                      
                            .align-center {
                              text-align: center; 
                            }
                      
                            .align-right {
                              text-align: right; 
                            }
                      
                            .align-left {
                              text-align: left; 
                            }
                      
                            .clear {
                              clear: both; 
                            }
                      
                            .mt0 {
                              margin-top: 0; 
                            }
                      
                            .mb0 {
                              margin-bottom: 0; 
                            }
                      
                            .preheader {
                              color: transparent;
                              display: none;
                              height: 0;
                              max-height: 0;
                              max-width: 0;
                              opacity: 0;
                              overflow: hidden;
                              mso-hide: all;
                              visibility: hidden;
                              width: 0; 
                            }
                      
                            .powered-by a {
                              text-decoration: none; 
                            }
                      
                            hr {
                              border: 0;
                              border-bottom: 1px solid #f6f6f6;
                              margin: 20px 0; 
                            }
                      
                            /* -------------------------------------
                                RESPONSIVE AND MOBILE FRIENDLY STYLES
                            ------------------------------------- */
                            @media only screen and (max-width: 620px) {
                              table.body h1 {
                                font-size: 28px !important;
                                margin-bottom: 10px !important; 
                              }
                              table.body p,
                              table.body ul,
                              table.body ol,
                              table.body td,
                              table.body span,
                              table.body a {
                                font-size: 16px !important; 
                              }
                              table.body .wrapper,
                              table.body .article {
                                padding: 10px !important; 
                              }
                              table.body .content {
                                padding: 0 !important; 
                              }
                              table.body .container {
                                padding: 0 !important;
                                width: 100% !important; 
                              }
                              table.body .main {
                                border-left-width: 0 !important;
                                border-radius: 0 !important;
                                border-right-width: 0 !important; 
                              }
                              table.body .btn table {
                                width: 100% !important; 
                              }
                              table.body .btn a {
                                width: 100% !important; 
                              }
                              table.body .img-responsive {
                                height: auto !important;
                                max-width: 100% !important;
                                width: auto !important; 
                              }
                            }
                      
                            /* -------------------------------------
                                PRESERVE THESE STYLES IN THE HEAD
                            ------------------------------------- */
                            @media all {
                              .ExternalClass {
                                width: 100%; 
                              }
                              .ExternalClass,
                              .ExternalClass p,
                              .ExternalClass span,
                              .ExternalClass font,
                              .ExternalClass td,
                              .ExternalClass div {
                                line-height: 100%; 
                              }
                              .apple-link a {
                                color: inherit !important;
                                font-family: inherit !important;
                                font-size: inherit !important;
                                font-weight: inherit !important;
                                line-height: inherit !important;
                                text-decoration: none !important; 
                              }
                              #MessageViewBody a {
                                color: inherit;
                                text-decoration: none;
                                font-size: inherit;
                                font-family: inherit;
                                font-weight: inherit;
                                line-height: inherit;
                              }
                              .btn-primary table td:hover {
                                background-color: #34495e !important; 
                              }
                              .btn-primary a:hover {
                                background-color: #34495e !important;
                                border-color: #34495e !important; 
                              } 
                            }
                      
                          </style>
                        </head>
                        <body class="">
                          <span class="preheader">This is preheader text. Some clients will show this text as a preview.</span>
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
                            <tr>
                              <td>&nbsp;</td>
                              <td class="container">
                                <div class="content">
                      
                                  <!-- START CENTERED WHITE CONTAINER -->
                                  <table role="presentation" class="main">
                      
                                    <!-- START MAIN CONTENT AREA -->
                                    <tr>
                                      <td class="wrapper">
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                          <tr>
                                            <td>
                                            <center><img width='50%' src='https://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/event/ea423e8d-6f8e-4c50-89f1-6bf5cbea7da3.png'><img/><br/><br/><br/>
                                            <strong>Diundang Wawancara</strong><p>Hi<strong> ${gelardepan ? gelardepan + '.' : ''} ${nama}, ${gelarbelakang ? gelarbelakang + '.' : ''}  </strong>
                                            <p>Terima kasih karena telah mendaftar pada posisi:</p><p>${job[0]['name']}</p><p>${job[0]['company']} - ${job[0]['location']}</p><p>${job[0]['type']}</p>
                                            <p>Saat ini lamaran Anda maju ke tahapan Wawancara. Anda akan dihubungi melalui email atau WhatsApp oleh HRD kami.</p>
                                            <br><br>
                                            <strong>DigiHealth <br> LEARN AND EARN TOGETHER</strong></center>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                      
                                  <!-- END MAIN CONTENT AREA -->
                                  </table>
                                  <!-- END CENTERED WHITE CONTAINER -->
                      
                                  <!-- START FOOTER -->
                                  <div class="footer">
                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td class="content-block">
                                          <span class="apple-link">Jl. Palmerah Barat No 45 a Grogol Utara, Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12210</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="content-block powered-by">
                                          Powered by <a href="https://digihealth.id">Digihealth.id</a>.
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                  <!-- END FOOTER -->
                      
                                </div>
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                          </table>
                        </body>
                      </html>`
      },
      {
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': token['data']['token']
        }
      }
    )
}

export const SendmailLamaranditolak = (gelardepan, gelarbelakang, nama, email) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  var job = JSON.parse(localStorage.getItem('payloadjob'));

  return axios
    .post(
      `/api/v1/events/sendmail`,
      {
        email:email,
        subject:"Lamaran Ditolak",
        body:`
        <!doctype html>
                      <html>
                        <head>
                          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                          <title>Simple Transactional Email</title>
                          <style>
                            /* -------------------------------------
                                GLOBAL RESETS
                            ------------------------------------- */
                            
                            /*All the styling goes here*/
                            
                            img {
                              border: none;
                              -ms-interpolation-mode: bicubic;
                              max-width: 100%; 
                            }
                      
                            body {
                              background-color: #f6f6f6;
                              font-family: sans-serif;
                              -webkit-font-smoothing: antialiased;
                              font-size: 14px;
                              line-height: 1.4;
                              margin: 0;
                              padding: 0;
                              -ms-text-size-adjust: 100%;
                              -webkit-text-size-adjust: 100%; 
                            }
                      
                            table {
                              border-collapse: separate;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              width: 100%; }
                              table td {
                                font-family: sans-serif;
                                font-size: 14px;
                                vertical-align: top; 
                            }
                      
                            /* -------------------------------------
                                BODY & CONTAINER
                            ------------------------------------- */
                      
                            .body {
                              background-color: #f6f6f6;
                              width: 100%; 
                            }
                      
                            /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
                            .container {
                              display: block;
                              margin: 0 auto !important;
                              /* makes it centered */
                              max-width: 580px;
                              padding: 10px;
                              width: 580px; 
                            }
                      
                            /* This should also be a block element, so that it will fill 100% of the .container */
                            .content {
                              box-sizing: border-box;
                              display: block;
                              margin: 0 auto;
                              max-width: 580px;
                              padding: 10px; 
                            }
                      
                            /* -------------------------------------
                                HEADER, FOOTER, MAIN
                            ------------------------------------- */
                            .main {
                              background: #ffffff;
                              border-radius: 3px;
                              width: 100%; 
                            }
                      
                            .wrapper {
                              box-sizing: border-box;
                              padding: 20px; 
                            }
                      
                            .content-block {
                              padding-bottom: 10px;
                              padding-top: 10px;
                            }
                      
                            .footer {
                              clear: both;
                              margin-top: 10px;
                              text-align: center;
                              width: 100%; 
                            }
                              .footer td,
                              .footer p,
                              .footer span,
                              .footer a {
                                color: #999999;
                                font-size: 12px;
                                text-align: center; 
                            }
                      
                            /* -------------------------------------
                                TYPOGRAPHY
                            ------------------------------------- */
                            h1,
                            h2,
                            h3,
                            h4 {
                              color: #000000;
                              font-family: sans-serif;
                              font-weight: 400;
                              line-height: 1.4;
                              margin: 0;
                              margin-bottom: 30px; 
                            }
                      
                            h1 {
                              font-size: 35px;
                              font-weight: 300;
                              text-align: center;
                              text-transform: capitalize; 
                            }
                      
                            p,
                            ul,
                            ol {
                              font-family: sans-serif;
                              font-size: 14px;
                              font-weight: normal;
                              margin: 0;
                              margin-bottom: 15px; 
                            }
                              p li,
                              ul li,
                              ol li {
                                list-style-position: inside;
                                margin-left: 5px; 
                            }
                      
                            a {
                              color: #3498db;
                              text-decoration: underline; 
                            }
                      
                            /* -------------------------------------
                                BUTTONS
                            ------------------------------------- */
                            .btn {
                              box-sizing: border-box;
                              width: 100%; }
                              .btn > tbody > tr > td {
                                padding-bottom: 15px; }
                              .btn table {
                                width: auto; 
                            }
                              .btn table td {
                                background-color: #ffffff;
                                border-radius: 5px;
                                text-align: center; 
                            }
                              .btn a {
                                background-color: #ffffff;
                                border: solid 1px #3498db;
                                border-radius: 5px;
                                box-sizing: border-box;
                                color: #3498db;
                                cursor: pointer;
                                display: inline-block;
                                font-size: 14px;
                                font-weight: bold;
                                margin: 0;
                                padding: 12px 25px;
                                text-decoration: none;
                                text-transform: capitalize; 
                            }
                      
                            .btn-primary table td {
                              background-color: #3498db; 
                            }
                      
                            .btn-primary a {
                              background-color: #3498db;
                              border-color: #3498db;
                              color: #ffffff; 
                            }
                      
                            /* -------------------------------------
                                OTHER STYLES THAT MIGHT BE USEFUL
                            ------------------------------------- */
                            .last {
                              margin-bottom: 0; 
                            }
                      
                            .first {
                              margin-top: 0; 
                            }
                      
                            .align-center {
                              text-align: center; 
                            }
                      
                            .align-right {
                              text-align: right; 
                            }
                      
                            .align-left {
                              text-align: left; 
                            }
                      
                            .clear {
                              clear: both; 
                            }
                      
                            .mt0 {
                              margin-top: 0; 
                            }
                      
                            .mb0 {
                              margin-bottom: 0; 
                            }
                      
                            .preheader {
                              color: transparent;
                              display: none;
                              height: 0;
                              max-height: 0;
                              max-width: 0;
                              opacity: 0;
                              overflow: hidden;
                              mso-hide: all;
                              visibility: hidden;
                              width: 0; 
                            }
                      
                            .powered-by a {
                              text-decoration: none; 
                            }
                      
                            hr {
                              border: 0;
                              border-bottom: 1px solid #f6f6f6;
                              margin: 20px 0; 
                            }
                      
                            /* -------------------------------------
                                RESPONSIVE AND MOBILE FRIENDLY STYLES
                            ------------------------------------- */
                            @media only screen and (max-width: 620px) {
                              table.body h1 {
                                font-size: 28px !important;
                                margin-bottom: 10px !important; 
                              }
                              table.body p,
                              table.body ul,
                              table.body ol,
                              table.body td,
                              table.body span,
                              table.body a {
                                font-size: 16px !important; 
                              }
                              table.body .wrapper,
                              table.body .article {
                                padding: 10px !important; 
                              }
                              table.body .content {
                                padding: 0 !important; 
                              }
                              table.body .container {
                                padding: 0 !important;
                                width: 100% !important; 
                              }
                              table.body .main {
                                border-left-width: 0 !important;
                                border-radius: 0 !important;
                                border-right-width: 0 !important; 
                              }
                              table.body .btn table {
                                width: 100% !important; 
                              }
                              table.body .btn a {
                                width: 100% !important; 
                              }
                              table.body .img-responsive {
                                height: auto !important;
                                max-width: 100% !important;
                                width: auto !important; 
                              }
                            }
                      
                            /* -------------------------------------
                                PRESERVE THESE STYLES IN THE HEAD
                            ------------------------------------- */
                            @media all {
                              .ExternalClass {
                                width: 100%; 
                              }
                              .ExternalClass,
                              .ExternalClass p,
                              .ExternalClass span,
                              .ExternalClass font,
                              .ExternalClass td,
                              .ExternalClass div {
                                line-height: 100%; 
                              }
                              .apple-link a {
                                color: inherit !important;
                                font-family: inherit !important;
                                font-size: inherit !important;
                                font-weight: inherit !important;
                                line-height: inherit !important;
                                text-decoration: none !important; 
                              }
                              #MessageViewBody a {
                                color: inherit;
                                text-decoration: none;
                                font-size: inherit;
                                font-family: inherit;
                                font-weight: inherit;
                                line-height: inherit;
                              }
                              .btn-primary table td:hover {
                                background-color: #34495e !important; 
                              }
                              .btn-primary a:hover {
                                background-color: #34495e !important;
                                border-color: #34495e !important; 
                              } 
                            }
                      
                          </style>
                        </head>
                        <body class="">
                          <span class="preheader">This is preheader text. Some clients will show this text as a preview.</span>
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
                            <tr>
                              <td>&nbsp;</td>
                              <td class="container">
                                <div class="content">
                      
                                  <!-- START CENTERED WHITE CONTAINER -->
                                  <table role="presentation" class="main">
                      
                                    <!-- START MAIN CONTENT AREA -->
                                    <tr>
                                      <td class="wrapper">
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                          <tr>
                                            <td>
                                            <center><img width='50%' src='https://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/event/ea423e8d-6f8e-4c50-89f1-6bf5cbea7da3.png'><img/><br/><br/><br/>
                                            <strong>Lamaran Ditolak</strong><p>Hi<strong> ${gelardepan ? gelardepan + '.' : ''} ${nama}, ${gelarbelakang ? gelarbelakang + '.' : ''}  </strong>
                                            <p>Terima kasih karena telah mendaftar pada posisi:</p><p>${job[0]['name']}</p><p>${job[0]['company']} - ${job[0]['location']}</p><p>${job[0]['type']}</p>
                                            <p>Berdasarkan hasil peninjauan kami, untuk saat ini kami tidak bisa meneruskan lamaran Anda pada posisi yang sudah Anda lamar. Semoga kita bisa bekerjasama di kesempatan lainnya. Pantau terus peluang pekerjaan yang ditawarkan melalui DigiHealth.</p>
                                            <br><br>
                                            <strong>DigiHealth <br> LEARN AND EARN TOGETHER</strong></center>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                      
                                  <!-- END MAIN CONTENT AREA -->
                                  </table>
                                  <!-- END CENTERED WHITE CONTAINER -->
                      
                                  <!-- START FOOTER -->
                                  <div class="footer">
                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td class="content-block">
                                          <span class="apple-link">Jl. Palmerah Barat No 45 a Grogol Utara, Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12210</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="content-block powered-by">
                                          Powered by <a href="https://digihealth.id">Digihealth.id</a>.
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                  <!-- END FOOTER -->
                      
                                </div>
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                          </table>
                        </body>
                      </html>`
      },
      {
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': token['data']['token']
        }
      }
    )
}

export const SendmailLamaranditerima = (gelardepan, gelarbelakang, nama, email) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  var job = JSON.parse(localStorage.getItem('payloadjob'));

  return axios
    .post(
      `/api/v1/events/sendmail`,
      {
        email:email,
        subject:"Lamaran Diterima",
        body:`
        <!doctype html>
                      <html>
                        <head>
                          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                          <title>Simple Transactional Email</title>
                          <style>
                            /* -------------------------------------
                                GLOBAL RESETS
                            ------------------------------------- */
                            
                            /*All the styling goes here*/
                            
                            img {
                              border: none;
                              -ms-interpolation-mode: bicubic;
                              max-width: 100%; 
                            }
                      
                            body {
                              background-color: #f6f6f6;
                              font-family: sans-serif;
                              -webkit-font-smoothing: antialiased;
                              font-size: 14px;
                              line-height: 1.4;
                              margin: 0;
                              padding: 0;
                              -ms-text-size-adjust: 100%;
                              -webkit-text-size-adjust: 100%; 
                            }
                      
                            table {
                              border-collapse: separate;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              width: 100%; }
                              table td {
                                font-family: sans-serif;
                                font-size: 14px;
                                vertical-align: top; 
                            }
                      
                            /* -------------------------------------
                                BODY & CONTAINER
                            ------------------------------------- */
                      
                            .body {
                              background-color: #f6f6f6;
                              width: 100%; 
                            }
                      
                            /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
                            .container {
                              display: block;
                              margin: 0 auto !important;
                              /* makes it centered */
                              max-width: 580px;
                              padding: 10px;
                              width: 580px; 
                            }
                      
                            /* This should also be a block element, so that it will fill 100% of the .container */
                            .content {
                              box-sizing: border-box;
                              display: block;
                              margin: 0 auto;
                              max-width: 580px;
                              padding: 10px; 
                            }
                      
                            /* -------------------------------------
                                HEADER, FOOTER, MAIN
                            ------------------------------------- */
                            .main {
                              background: #ffffff;
                              border-radius: 3px;
                              width: 100%; 
                            }
                      
                            .wrapper {
                              box-sizing: border-box;
                              padding: 20px; 
                            }
                      
                            .content-block {
                              padding-bottom: 10px;
                              padding-top: 10px;
                            }
                      
                            .footer {
                              clear: both;
                              margin-top: 10px;
                              text-align: center;
                              width: 100%; 
                            }
                              .footer td,
                              .footer p,
                              .footer span,
                              .footer a {
                                color: #999999;
                                font-size: 12px;
                                text-align: center; 
                            }
                      
                            /* -------------------------------------
                                TYPOGRAPHY
                            ------------------------------------- */
                            h1,
                            h2,
                            h3,
                            h4 {
                              color: #000000;
                              font-family: sans-serif;
                              font-weight: 400;
                              line-height: 1.4;
                              margin: 0;
                              margin-bottom: 30px; 
                            }
                      
                            h1 {
                              font-size: 35px;
                              font-weight: 300;
                              text-align: center;
                              text-transform: capitalize; 
                            }
                      
                            p,
                            ul,
                            ol {
                              font-family: sans-serif;
                              font-size: 14px;
                              font-weight: normal;
                              margin: 0;
                              margin-bottom: 15px; 
                            }
                              p li,
                              ul li,
                              ol li {
                                list-style-position: inside;
                                margin-left: 5px; 
                            }
                      
                            a {
                              color: #3498db;
                              text-decoration: underline; 
                            }
                      
                            /* -------------------------------------
                                BUTTONS
                            ------------------------------------- */
                            .btn {
                              box-sizing: border-box;
                              width: 100%; }
                              .btn > tbody > tr > td {
                                padding-bottom: 15px; }
                              .btn table {
                                width: auto; 
                            }
                              .btn table td {
                                background-color: #ffffff;
                                border-radius: 5px;
                                text-align: center; 
                            }
                              .btn a {
                                background-color: #ffffff;
                                border: solid 1px #3498db;
                                border-radius: 5px;
                                box-sizing: border-box;
                                color: #3498db;
                                cursor: pointer;
                                display: inline-block;
                                font-size: 14px;
                                font-weight: bold;
                                margin: 0;
                                padding: 12px 25px;
                                text-decoration: none;
                                text-transform: capitalize; 
                            }
                      
                            .btn-primary table td {
                              background-color: #3498db; 
                            }
                      
                            .btn-primary a {
                              background-color: #3498db;
                              border-color: #3498db;
                              color: #ffffff; 
                            }
                      
                            /* -------------------------------------
                                OTHER STYLES THAT MIGHT BE USEFUL
                            ------------------------------------- */
                            .last {
                              margin-bottom: 0; 
                            }
                      
                            .first {
                              margin-top: 0; 
                            }
                      
                            .align-center {
                              text-align: center; 
                            }
                      
                            .align-right {
                              text-align: right; 
                            }
                      
                            .align-left {
                              text-align: left; 
                            }
                      
                            .clear {
                              clear: both; 
                            }
                      
                            .mt0 {
                              margin-top: 0; 
                            }
                      
                            .mb0 {
                              margin-bottom: 0; 
                            }
                      
                            .preheader {
                              color: transparent;
                              display: none;
                              height: 0;
                              max-height: 0;
                              max-width: 0;
                              opacity: 0;
                              overflow: hidden;
                              mso-hide: all;
                              visibility: hidden;
                              width: 0; 
                            }
                      
                            .powered-by a {
                              text-decoration: none; 
                            }
                      
                            hr {
                              border: 0;
                              border-bottom: 1px solid #f6f6f6;
                              margin: 20px 0; 
                            }
                      
                            /* -------------------------------------
                                RESPONSIVE AND MOBILE FRIENDLY STYLES
                            ------------------------------------- */
                            @media only screen and (max-width: 620px) {
                              table.body h1 {
                                font-size: 28px !important;
                                margin-bottom: 10px !important; 
                              }
                              table.body p,
                              table.body ul,
                              table.body ol,
                              table.body td,
                              table.body span,
                              table.body a {
                                font-size: 16px !important; 
                              }
                              table.body .wrapper,
                              table.body .article {
                                padding: 10px !important; 
                              }
                              table.body .content {
                                padding: 0 !important; 
                              }
                              table.body .container {
                                padding: 0 !important;
                                width: 100% !important; 
                              }
                              table.body .main {
                                border-left-width: 0 !important;
                                border-radius: 0 !important;
                                border-right-width: 0 !important; 
                              }
                              table.body .btn table {
                                width: 100% !important; 
                              }
                              table.body .btn a {
                                width: 100% !important; 
                              }
                              table.body .img-responsive {
                                height: auto !important;
                                max-width: 100% !important;
                                width: auto !important; 
                              }
                            }
                      
                            /* -------------------------------------
                                PRESERVE THESE STYLES IN THE HEAD
                            ------------------------------------- */
                            @media all {
                              .ExternalClass {
                                width: 100%; 
                              }
                              .ExternalClass,
                              .ExternalClass p,
                              .ExternalClass span,
                              .ExternalClass font,
                              .ExternalClass td,
                              .ExternalClass div {
                                line-height: 100%; 
                              }
                              .apple-link a {
                                color: inherit !important;
                                font-family: inherit !important;
                                font-size: inherit !important;
                                font-weight: inherit !important;
                                line-height: inherit !important;
                                text-decoration: none !important; 
                              }
                              #MessageViewBody a {
                                color: inherit;
                                text-decoration: none;
                                font-size: inherit;
                                font-family: inherit;
                                font-weight: inherit;
                                line-height: inherit;
                              }
                              .btn-primary table td:hover {
                                background-color: #34495e !important; 
                              }
                              .btn-primary a:hover {
                                background-color: #34495e !important;
                                border-color: #34495e !important; 
                              } 
                            }
                      
                          </style>
                        </head>
                        <body class="">
                          <span class="preheader">This is preheader text. Some clients will show this text as a preview.</span>
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
                            <tr>
                              <td>&nbsp;</td>
                              <td class="container">
                                <div class="content">
                      
                                  <!-- START CENTERED WHITE CONTAINER -->
                                  <table role="presentation" class="main">
                      
                                    <!-- START MAIN CONTENT AREA -->
                                    <tr>
                                      <td class="wrapper">
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                          <tr>
                                            <td>
                                            <center><img width='50%' src='https://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/event/ea423e8d-6f8e-4c50-89f1-6bf5cbea7da3.png'><img/><br/><br/><br/>
                                            <strong>Lamaran Diterima</strong><p>Hi<strong> ${gelardepan ? gelardepan + '.' : ''} ${nama}, ${gelarbelakang ? gelarbelakang + '.' : ''}  </strong>
                                            <p>Terima kasih karena telah mendaftar pada posisi:</p><p>${job[0]['name']}</p><p>${job[0]['company']} - ${job[0]['location']}</p><p>${job[0]['type']}</p>
                                            <p>Berdasarkan hasil peninjauan dan proses penilaian yang kami lakukan, Anda memiliki kompetensi yang sesuai dengan posisi yang ditawarkan. Untuk selanjutnya Anda akan dihubungi melalui Email atau WhatsApp oleh HRD kami.</p>
                                            <br><br>
                                            <strong>DigiHealth <br> LEARN AND EARN TOGETHER</strong></center>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                      
                                  <!-- END MAIN CONTENT AREA -->
                                  </table>
                                  <!-- END CENTERED WHITE CONTAINER -->
                      
                                  <!-- START FOOTER -->
                                  <div class="footer">
                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                      <tr>
                                        <td class="content-block">
                                          <span class="apple-link">Jl. Palmerah Barat No 45 a Grogol Utara, Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12210</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="content-block powered-by">
                                          Powered by <a href="https://digihealth.id">Digihealth.id</a>.
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                  <!-- END FOOTER -->
                      
                                </div>
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                          </table>
                        </body>
                      </html>`
      },
      {
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': token['data']['token']
        }
      }
    )
}

export const Sendmailreject= (email, gelardepan, nama, gelarbelakang, jobtype, jobtitle, postdate, expairedate) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  axios.post(
    `/api/v1/events/sendmail`,
    {
      email:email,
      subject: "Job Reject",
      body:`<!doctype html>
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <title>Simple Transactional Email</title>
          <style>
            /* -------------------------------------
                GLOBAL RESETS
            ------------------------------------- */
            
            /*All the styling goes here*/
            
            img {
              border: none;
              -ms-interpolation-mode: bicubic;
              max-width: 100%; 
            }
      
            body {
              background-color: #f6f6f6;
              font-family: sans-serif;
              -webkit-font-smoothing: antialiased;
              font-size: 14px;
              line-height: 1.4;
              margin: 0;
              padding: 0;
              -ms-text-size-adjust: 100%;
              -webkit-text-size-adjust: 100%; 
            }
      
            table {
              border-collapse: separate;
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
              width: 100%; }
              table td {
                font-family: sans-serif;
                font-size: 14px;
                vertical-align: top; 
            }
      
            /* -------------------------------------
                BODY & CONTAINER
            ------------------------------------- */
      
            .body {
              background-color: #f6f6f6;
              width: 100%; 
            }
      
            /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
            .container {
              display: block;
              margin: 0 auto !important;
              /* makes it centered */
              max-width: 580px;
              padding: 10px;
              width: 580px; 
            }
      
            /* This should also be a block element, so that it will fill 100% of the .container */
            .content {
              box-sizing: border-box;
              display: block;
              margin: 0 auto;
              max-width: 580px;
              padding: 10px; 
            }
      
            /* -------------------------------------
                HEADER, FOOTER, MAIN
            ------------------------------------- */
            .main {
              background: #ffffff;
              border-radius: 3px;
              width: 100%; 
            }
      
            .wrapper {
              box-sizing: border-box;
              padding: 20px; 
            }
      
            .content-block {
              padding-bottom: 10px;
              padding-top: 10px;
            }
      
            .footer {
              clear: both;
              margin-top: 10px;
              text-align: center;
              width: 100%; 
            }
              .footer td,
              .footer p,
              .footer span,
              .footer a {
                color: #999999;
                font-size: 12px;
                text-align: center; 
            }
      
            /* -------------------------------------
                TYPOGRAPHY
            ------------------------------------- */
            h1,
            h2,
            h3,
            h4 {
              color: #000000;
              font-family: sans-serif;
              font-weight: 400;
              line-height: 1.4;
              margin: 0;
              margin-bottom: 30px; 
            }
      
            h1 {
              font-size: 35px;
              font-weight: 300;
              text-align: center;
              text-transform: capitalize; 
            }
      
            p,
            ul,
            ol {
              font-family: sans-serif;
              font-size: 14px;
              font-weight: normal;
              margin: 0;
              margin-bottom: 15px; 
            }
              p li,
              ul li,
              ol li {
                list-style-position: inside;
                margin-left: 5px; 
            }
      
            a {
              color: #3498db;
              text-decoration: underline; 
            }
      
            /* -------------------------------------
                BUTTONS
            ------------------------------------- */
            .btn {
              box-sizing: border-box;
              width: 100%; }
              .btn > tbody > tr > td {
                padding-bottom: 15px; }
              .btn table {
                width: auto; 
            }
              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center; 
            }
              .btn a {
                background-color: #ffffff;
                border: solid 1px #3498db;
                border-radius: 5px;
                box-sizing: border-box;
                color: #3498db;
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
                font-weight: bold;
                margin: 0;
                padding: 12px 25px;
                text-decoration: none;
                text-transform: capitalize; 
            }
      
            .btn-primary table td {
              background-color: #3498db; 
            }
      
            .btn-primary a {
              background-color: #3498db;
              border-color: #3498db;
              color: #ffffff; 
            }
      
            /* -------------------------------------
                OTHER STYLES THAT MIGHT BE USEFUL
            ------------------------------------- */
            .last {
              margin-bottom: 0; 
            }
      
            .first {
              margin-top: 0; 
            }
      
            .align-center {
              text-align: center; 
            }
      
            .align-right {
              text-align: right; 
            }
      
            .align-left {
              text-align: left; 
            }
      
            .clear {
              clear: both; 
            }
      
            .mt0 {
              margin-top: 0; 
            }
      
            .mb0 {
              margin-bottom: 0; 
            }
      
            .preheader {
              color: transparent;
              display: none;
              height: 0;
              max-height: 0;
              max-width: 0;
              opacity: 0;
              overflow: hidden;
              mso-hide: all;
              visibility: hidden;
              width: 0; 
            }
      
            .powered-by a {
              text-decoration: none; 
            }
      
            hr {
              border: 0;
              border-bottom: 1px solid #f6f6f6;
              margin: 20px 0; 
            }
      
            /* -------------------------------------
                RESPONSIVE AND MOBILE FRIENDLY STYLES
            ------------------------------------- */
            @media only screen and (max-width: 620px) {
              table.body h1 {
                font-size: 28px !important;
                margin-bottom: 10px !important; 
              }
              table.body p,
              table.body ul,
              table.body ol,
              table.body td,
              table.body span,
              table.body a {
                font-size: 16px !important; 
              }
              table.body .wrapper,
              table.body .article {
                padding: 10px !important; 
              }
              table.body .content {
                padding: 0 !important; 
              }
              table.body .container {
                padding: 0 !important;
                width: 100% !important; 
              }
              table.body .main {
                border-left-width: 0 !important;
                border-radius: 0 !important;
                border-right-width: 0 !important; 
              }
              table.body .btn table {
                width: 100% !important; 
              }
              table.body .btn a {
                width: 100% !important; 
              }
              table.body .img-responsive {
                height: auto !important;
                max-width: 100% !important;
                width: auto !important; 
              }
            }
      
            /* -------------------------------------
                PRESERVE THESE STYLES IN THE HEAD
            ------------------------------------- */
            @media all {
              .ExternalClass {
                width: 100%; 
              }
              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%; 
              }
              .apple-link a {
                color: inherit !important;
                font-family: inherit !important;
                font-size: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
                text-decoration: none !important; 
              }
              #MessageViewBody a {
                color: inherit;
                text-decoration: none;
                font-size: inherit;
                font-family: inherit;
                font-weight: inherit;
                line-height: inherit;
              }
              .btn-primary table td:hover {
                background-color: #34495e !important; 
              }
              .btn-primary a:hover {
                background-color: #34495e !important;
                border-color: #34495e !important; 
              } 
            }
      
          </style>
        </head>
        <body class="">
          <span class="preheader">This is preheader text. Some clients will show this text as a preview.</span>
          <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
            <tr>
              <td>&nbsp;</td>
              <td class="container">
                <div class="content">
      
                  <!-- START CENTERED WHITE CONTAINER -->
                  <table role="presentation" class="main">
      
                    <!-- START MAIN CONTENT AREA -->
                    <tr>
                      <td class="wrapper">
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                          <tr>
                            <td>
                            <center><img width='50%' src='https://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/event/ea423e8d-6f8e-4c50-89f1-6bf5cbea7da3.png'><img/><br/><br/><br/>
                            <h3><strong>Permintaan Penayangan Kegiatan <br> <br> Hai ${gelardepan ? gelardepan : ''}, ${nama}. ${gelarbelakang ? gelarbelakang : ''}</h3></strong></center>
                            <center><p>Untuk permintaan penayangan lowongan pekerjaan berikut:</p></center>
                            <center><p>${jobtype}<br>${jobtitle}<br>${postdate} - ${expairedate} </p>
                            <p>Team DigiHealth memohon maaf karena saat ini permintaan ini ditolak <strong>(Rejected)</strong> karena beberapa alasan. Silahkan untuk melakukan permintaan penayangan lowongan pekerjaan lainnya.</p><br><center>
                            <strong>DigiHealth <br> LEARN AND EARN TOGETHER</strong></center>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
      
                  <!-- END MAIN CONTENT AREA -->
                  </table>
                  <!-- END CENTERED WHITE CONTAINER -->
      
                  <!-- START FOOTER -->
                  <div class="footer">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <td class="content-block">
                          <span class="apple-link">Jl. Palmerah Barat No 45 a Grogol Utara, Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12210</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="content-block powered-by">
                          Powered by <a href="https://digihealth.id">Digihealth.id</a>.
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- END FOOTER -->
      
                </div>
              </td>
              <td>&nbsp;</td>
            </tr>
          </table>
        </body>
      </html>`
    },
    {
      headers: { 
          'Content-Type': 'application/json', 
          'Authorization': token['data']['token']
      }
    }
  )  
}

  export const Sendmailapprove = (email, gelardepan, nama, gelarbelakang, jobtype, jobtitle, jobdate, expiredate) => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    axios.post(
      `/api/v1/events/sendmail`,
      {
        email:email,
        subject: "Job Approved",
        body:`<!doctype html>
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <title>Simple Transactional Email</title>
            <style>
              /* -------------------------------------
                  GLOBAL RESETS
              ------------------------------------- */
              
              /*All the styling goes here*/
              
              img {
                border: none;
                -ms-interpolation-mode: bicubic;
                max-width: 100%; 
              }
        
              body {
                background-color: #f6f6f6;
                font-family: sans-serif;
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                line-height: 1.4;
                margin: 0;
                padding: 0;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%; 
              }
        
              table {
                border-collapse: separate;
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                width: 100%; }
                table td {
                  font-family: sans-serif;
                  font-size: 14px;
                  vertical-align: top; 
              }
        
              /* -------------------------------------
                  BODY & CONTAINER
              ------------------------------------- */
        
              .body {
                background-color: #f6f6f6;
                width: 100%; 
              }
        
              /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
              .container {
                display: block;
                margin: 0 auto !important;
                /* makes it centered */
                max-width: 580px;
                padding: 10px;
                width: 580px; 
              }
        
              /* This should also be a block element, so that it will fill 100% of the .container */
              .content {
                box-sizing: border-box;
                display: block;
                margin: 0 auto;
                max-width: 580px;
                padding: 10px; 
              }
        
              /* -------------------------------------
                  HEADER, FOOTER, MAIN
              ------------------------------------- */
              .main {
                background: #ffffff;
                border-radius: 3px;
                width: 100%; 
              }
        
              .wrapper {
                box-sizing: border-box;
                padding: 20px; 
              }
        
              .content-block {
                padding-bottom: 10px;
                padding-top: 10px;
              }
        
              .footer {
                clear: both;
                margin-top: 10px;
                text-align: center;
                width: 100%; 
              }
                .footer td,
                .footer p,
                .footer span,
                .footer a {
                  color: #999999;
                  font-size: 12px;
                  text-align: center; 
              }
        
              /* -------------------------------------
                  TYPOGRAPHY
              ------------------------------------- */
              h1,
              h2,
              h3,
              h4 {
                color: #000000;
                font-family: sans-serif;
                font-weight: 400;
                line-height: 1.4;
                margin: 0;
                margin-bottom: 30px; 
              }
        
              h1 {
                font-size: 35px;
                font-weight: 300;
                text-align: center;
                text-transform: capitalize; 
              }
        
              p,
              ul,
              ol {
                font-family: sans-serif;
                font-size: 14px;
                font-weight: normal;
                margin: 0;
                margin-bottom: 15px; 
              }
                p li,
                ul li,
                ol li {
                  list-style-position: inside;
                  margin-left: 5px; 
              }
        
              a {
                color: #3498db;
                text-decoration: underline; 
              }
        
              /* -------------------------------------
                  BUTTONS
              ------------------------------------- */
              .btn {
                box-sizing: border-box;
                width: 100%; }
                .btn > tbody > tr > td {
                  padding-bottom: 15px; }
                .btn table {
                  width: auto; 
              }
                .btn table td {
                  background-color: #ffffff;
                  border-radius: 5px;
                  text-align: center; 
              }
                .btn a {
                  background-color: #ffffff;
                  border: solid 1px #3498db;
                  border-radius: 5px;
                  box-sizing: border-box;
                  color: #3498db;
                  cursor: pointer;
                  display: inline-block;
                  font-size: 14px;
                  font-weight: bold;
                  margin: 0;
                  padding: 12px 25px;
                  text-decoration: none;
                  text-transform: capitalize; 
              }
        
              .btn-primary table td {
                background-color: #3498db; 
              }
        
              .btn-primary a {
                background-color: #3498db;
                border-color: #3498db;
                color: #ffffff; 
              }
        
              /* -------------------------------------
                  OTHER STYLES THAT MIGHT BE USEFUL
              ------------------------------------- */
              .last {
                margin-bottom: 0; 
              }
        
              .first {
                margin-top: 0; 
              }
        
              .align-center {
                text-align: center; 
              }
        
              .align-right {
                text-align: right; 
              }
        
              .align-left {
                text-align: left; 
              }
        
              .clear {
                clear: both; 
              }
        
              .mt0 {
                margin-top: 0; 
              }
        
              .mb0 {
                margin-bottom: 0; 
              }
        
              .preheader {
                color: transparent;
                display: none;
                height: 0;
                max-height: 0;
                max-width: 0;
                opacity: 0;
                overflow: hidden;
                mso-hide: all;
                visibility: hidden;
                width: 0; 
              }
        
              .powered-by a {
                text-decoration: none; 
              }
        
              hr {
                border: 0;
                border-bottom: 1px solid #f6f6f6;
                margin: 20px 0; 
              }
        
              /* -------------------------------------
                  RESPONSIVE AND MOBILE FRIENDLY STYLES
              ------------------------------------- */
              @media only screen and (max-width: 620px) {
                table.body h1 {
                  font-size: 28px !important;
                  margin-bottom: 10px !important; 
                }
                table.body p,
                table.body ul,
                table.body ol,
                table.body td,
                table.body span,
                table.body a {
                  font-size: 16px !important; 
                }
                table.body .wrapper,
                table.body .article {
                  padding: 10px !important; 
                }
                table.body .content {
                  padding: 0 !important; 
                }
                table.body .container {
                  padding: 0 !important;
                  width: 100% !important; 
                }
                table.body .main {
                  border-left-width: 0 !important;
                  border-radius: 0 !important;
                  border-right-width: 0 !important; 
                }
                table.body .btn table {
                  width: 100% !important; 
                }
                table.body .btn a {
                  width: 100% !important; 
                }
                table.body .img-responsive {
                  height: auto !important;
                  max-width: 100% !important;
                  width: auto !important; 
                }
              }
        
              /* -------------------------------------
                  PRESERVE THESE STYLES IN THE HEAD
              ------------------------------------- */
              @media all {
                .ExternalClass {
                  width: 100%; 
                }
                .ExternalClass,
                .ExternalClass p,
                .ExternalClass span,
                .ExternalClass font,
                .ExternalClass td,
                .ExternalClass div {
                  line-height: 100%; 
                }
                .apple-link a {
                  color: inherit !important;
                  font-family: inherit !important;
                  font-size: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
                  text-decoration: none !important; 
                }
                #MessageViewBody a {
                  color: inherit;
                  text-decoration: none;
                  font-size: inherit;
                  font-family: inherit;
                  font-weight: inherit;
                  line-height: inherit;
                }
                .btn-primary table td:hover {
                  background-color: #34495e !important; 
                }
                .btn-primary a:hover {
                  background-color: #34495e !important;
                  border-color: #34495e !important; 
                } 
              }
        
            </style>
          </head>
          <body class="">
            <span class="preheader">This is preheader text. Some clients will show this text as a preview.</span>
            <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
              <tr>
                <td>&nbsp;</td>
                <td class="container">
                  <div class="content">
        
                    <!-- START CENTERED WHITE CONTAINER -->
                    <table role="presentation" class="main">
        
                      <!-- START MAIN CONTENT AREA -->
                      <tr>
                        <td class="wrapper">
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                            <tr>
                              <td>
                              <center><img width='50%' src='https://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/event/ea423e8d-6f8e-4c50-89f1-6bf5cbea7da3.png'><img/><br/><br/><br/>
                              <h3><strong>Permintaan Penayangan Lowongan Pekerjaan <br> <br> Hai ${gelardepan ? gelardepan : ''}, ${nama}. ${gelarbelakang ? gelarbelakang : ''}</h3></strong></center>
                              <center><p>Untuk permintaan penayangan kegiatan berikut:</p></center>
                              <center><p>${jobtype}<br>${jobtitle}<br>${jobdate} - ${expiredate} </p>
                              <p>Telah disetujui <strong>(Approved)</strong> oleh team kami dan akan ditayangkan sesuai dengan jadwal permintaan penayangan yang Anda ajukan.</p><br><center>
                              <strong>DigiHealth <br> LEARN AND EARN TOGETHER</strong></center>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
        
                    <!-- END MAIN CONTENT AREA -->
                    </table>
                    <!-- END CENTERED WHITE CONTAINER -->
        
                    <!-- START FOOTER -->
                    <div class="footer">
                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td class="content-block">
                            <span class="apple-link">Jl. Palmerah Barat No 45 a Grogol Utara, Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12210</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="content-block powered-by">
                            Powered by <a href="https://digihealth.id">Digihealth.id</a>.
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!-- END FOOTER -->
        
                  </div>
                </td>
                <td>&nbsp;</td>
              </tr>
            </table>
          </body>
        </html>`
      },
      {
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': token['data']['token']
        }
      }
    )  
  }

