import React, { Component } from 'react';
import { checkviewpaginet } from "../../actions/repotelibraryAction";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import ReactPaginate from 'react-paginate';

export default class App extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            offset: 1,
            perPage: 25,
            pageCount:0,
            currentPage: 1,
            data: [],
            id:"",
            date:"",
            type:"",
            loading:false,
            uploadload:false,
        };

    }
    

     saveAsExcel() {
        let header = ["Email", "Fullname","Nomor Telphone","STR"];
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = this.getSheetData(this.state.data, header);
          const totalColumns = sheetData[0].length;
          sheet1.cell("A1").value(sheetData);
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style("bold", true);
          sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
          range.style("border", true);
          return workbook.outputAsync().then((res) => {
            saveAs(res, this.state.date+"repotelibrary.xls");
          });
        });
      }
      
      getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
      }

      onDetail(id, e) {
        let recent = [];
        recent.push({
            'id': id
        });
        localStorage.setItem('recentmember', JSON.stringify(recent));
        this.props.history.push(`/memberdetailprofile`)
    
    }

    receivedData() {
        let params = window.location.pathname
        const myArray = params.split("/Repotelibdetail/"); // 6216495d6000a3bf6f1910a0#2022-2-24+download
        let query = myArray[1] ? myArray[1] : []
        let getdate = query.split('@')[1]
        let getid = query.split('@')[0]
        let dates = getdate.split('+')[0]
        let gettype = getdate.split('+')[1]
        this.setState({date:dates, type:gettype})
        checkviewpaginet(getid, gettype,dates, this.state.currentPage).then(res => {
           let data = res['data']['data'] ? res['data']['data'] : []
           const pagination = res['data']['pagination']['count'] ? res['data']['pagination']['count'] : 0
           var postData = data.map((one, index) => (
                <tr className="application-item" key={index}>
                    <td><a href="memberdetailprofile#" onClick={this.onDetail.bind(this, one.memberid)}>{one.email}</a></td>
                    <td>{one.name}</td>
                    <td>{one.phone}</td>
                    <td>{one.str}</td>
                </tr>
            ))
            this.setState({
                data:[...data],
                pageCount: Math.ceil(pagination / this.state.perPage),
                postData
            })
        })
    }
    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
        } else {
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage+1,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };


    render() {
        return (

            <div className="page-content">

                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Apps</a></li>
                            <li className="breadcrumb-item active" aria-current="page">List E-Library Document Metric Report</li>
                        </ol>
                    </nav>
                </div>
                <div className="main-wrapper">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">E-Library Document Metric Report - Per Day Detail<a href="/#" onClick={()=> this.saveAsExcel()} style={{color:"white"}} className="btn btn-primary card-title-helper blockui-transactions">Download As Xls</a></h5>
                                    <span>{localStorage.getItem('name')? localStorage.getItem('name') : ''} - Activity Date : {this.state.date}  Type : {this.state.type}</span>
                                    <br />
                                    <hr />
                                    <div className="table-responsive">
                                        <table className="table" id="tree-table">
                                            <thead>
                                                <tr>
                                                    <th>Email</th>
                                                    <th>Fullname</th>
                                                    <th>Nomor Telphone</th>
                                                    <th>STR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.postData}
                                            </tbody>
                                        </table>
                                        <div className="row">
                                            <div className="col-12">
                                                <ReactPaginate
                                                previousLabel={<i className="fa fa-angle-left"></i>}
                                                nextLabel={<i className="fa fa-angle-right"></i>}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={1}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={"pagination justify-content-end asrt-pagination"}
                                                subContainerClassName={"page pagination"}
                                                activeClassName={"active"} />
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
