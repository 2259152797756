import React, { Component } from 'react';
import { deleteItem, addToList, updateItem, getList, addEventXml, deleteItemxml } from "../../actions/pagemanajemenAction";
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import FormValidator from '../../components/FormValidator'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const bucket = process.env.REACT_APP_API_KEY

const style = {
    color: 'red',
};

export default class App extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
                field: 'cmstitle',
                method: 'isEmpty',
                validWhen: false,
                message: 'title is required'
            },
            {
                field: 'url',
                method: 'isEmpty',
                validWhen: false,
                message: 'url is required'
            }
        ]);
        this.state = {
            offset: 0,
            data: [],
            perPage: 25,
            title: "",
            cmstitle: "",
            description: "",
            url: "",
            active: true,
            currentPage: 0,
            searchTitle: '',
            searchActive: '',
            isUpdate: false,
            alert: false,
            message: "",
            errorMessage: '',
            slug: "",
            selectOptions: [],
            validation: this.validator.valid(),
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCreate = this.onCreate.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleModelChange(model) {
        this.setState({
            description: model
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    onCreate(e) {
        e.preventDefault()
        this.setState({
            title: "Add New Page",
            cmstitle: "",
            description: "",
            url: "",
            active: true,
            isUpdate: false
        });
    }

    receivedData() {
        getList().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {
                const data = res['data'] ? res['data'] : [];
                var active = (this.state.searchActive ? this.state.searchActive === "true" : null);
                var result
                var slice
                var filterData

                if (active !== null) {
                    result = data.filter(item => (item.active === active) && (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title))
                    slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (item.active === active) && (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title))
                } else {
                    result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title))
                    slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title))
                }

                var postData = filterData
                    .sort((a, b) => a.priority > b.priority ? 1 : -1)
                    .map((one, index) => (
                        <tr className="application-item" key={index}>
                            <td>{one.title}</td>
                            <td>{one.url}</td>
                            <td>
                                {one.active === true ?
                                    <div className="productText">
                                        Active
                                    </div>
                                    : null}
                                {one.active === false ?
                                    <div className="productText">
                                        Nonactive
                                    </div>
                                    : null}
                            </td>
                            <td><button className="btn btn-danger btn-sm " onClick={this.onDelete.bind(this, one.id)} style={{ width: '50px' }}><i className="fas fa-trash"></i> </button>
                                <button className="btn btn-warning btn-sm " data-toggle="modal" data-target="#paycard" style={{ width: '50px' }} onClick={this.onEdit.bind(this, one.id, one.title, one.description, one.url, one.active)}><i className="fas fa-edit"></i></button></td>
                        </tr>
                    ))

                this.setState({
                    pageCount: Math.ceil(result.length / this.state.perPage),
                    data: [...data],
                    postData
                })
            }
        })

    }
    Filter = (e) => {

        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
        } else {
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const validation = this.validator.validate(this.state);
        this.setState({ validation });

        if (validation.isValid) {
            const cmsRequest = {
                title: this.state.cmstitle,
                description: this.state.description,
                url: this.state.url,
                ishome: this.state.ishome,
                active: this.state.active
            }
            const xml =
            {
                url: this.state.url,
                type: 'general',
            }
            addEventXml(xml)
            addToList(cmsRequest).then(() => {
                this.receivedData()
                // Syncgeneral()
                $(".close").trigger("click");
            }).catch(err => {
                this.setState({ editDisabled: false, errorMessage: err.message })
            })
            // this.setState({ editDisabled: false })
        }

    }

    onDelete = (val, e) => {
        e.preventDefault()
         //delete xml
         const master = this.state.data.filter(item => ( item.id === val) )
         const old = master[0]['url']
         deleteItemxml(old)

        deleteItem(val).then(() => {
            // this.handlePageClick(1)
            // Syncgeneral()
            window.location.reload();
        }).catch(err => {
            this.setState({ editDisabled: false, errorMessage: err.message })
        })
    }

    onEdit = (item_id, title, description, url, active, e) => {
        this.setState({
            id: item_id,
            title: "Edit Page",
            cmstitle: title,
            isUpdate: true,
            description: description,
            url: url,
            active: active,
        })
    }

    onUpdate = e => {
        e.preventDefault()
        const validation = this.validator.validate(this.state);
        this.setState({ validation });

        if (validation.isValid) {
            //delete xml
            const master = this.state.data.filter(item => ( item.id === this.state.id) )
            const old = master[0]['url']
            deleteItemxml(old)
            const xml =
            {
                url: this.state.url,
                type: 'general',
            }
            addEventXml(xml)
            const UpdateRequest = {
                id: this.state.id,
                cmstitle: this.state.cmstitle,
                description: this.state.description,
                url: this.state.url,
                active: this.state.active
            }
            updateItem(UpdateRequest).then(() => {
                // Syncgeneral()
                this.receivedData();
                $(".close").trigger("click");
            }).catch(err => {
                this.setState({ isUpdate: false, errorMessage: err.message })
            })
            

            this.setState({ isUpdate: false })
        }
    }

    render() {
        return (

            <div className="page-content">

                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Apps</a></li>
                            <li className="breadcrumb-item active" aria-current="page">List Page Manajemen</li>
                        </ol>
                    </nav>

                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Page Management<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group row">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.searchTitle}
                                                    name="searchTitle"
                                                    placeholder="enter title"
                                                    onChange={this.onChange}
                                                />
                                            </div></div>
                                        <div className="col-md-4">
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Status</label>
                                                <div className="col-sm-9">
                                                    <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="true">Aktif</option>
                                                        <option value="false">Tidak Aktif</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group row">
                                                <button className="btn btn-primary btn-sm btn-block" data-dismiss="modal" aria-label="Close" onClick={this.Filter.bind(this)}><i className="fas fa-search"></i> Filter</button>
                                            </div>
                                        </div>
                                        &nbsp;&nbsp;
                                        <div className="col-md-2">
                                            <div className="form-group row">
                                                <button className="btn btn-success btn-sm btn-block" data-toggle="modal" data-target="#paycard" onClick={this.onCreate.bind(this)}><i className="fas fa-plus"></i> Add</button>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    <div className="table-responsive">
                                        <table className="table" id="tree-table">
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Url Path / Pages</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.postData}
                                            </tbody>
                                        </table>
                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-left"></i>}
                                            nextLabel={<i className="fa fa-angle-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-end asrt-pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label"> Title  <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.cmstitle}
                                                name="cmstitle"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style} >{this.state.validation.cmstitle.message}</span>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label"> Description  <span>*</span></label>
                                        <div className="col-md-10">

                                            <CKEditor

                                                editor={ClassicEditor}
                                                data={this.state.description}
                                                config={{
                                                    ckfinder: {
                                                        // Upload the images to the server using the CKFinder QuickUpload command.
                                                        uploadUrl: '/api/v1/auth/upload/cms?path=' + bucket + '/cms&type=Files&responseType=json'
                                                    },
                                                    mediaEmbed: {
                                                        previewsInData: true
                                                    }
                                                }}
                                                onInit={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    console.log('Editor is ready to use!', editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    this.handleModelChange(data);
                                                    console.log({ event, editor, data });
                                                }}
                                                onBlur={editor => {
                                                    console.log('Blur.', editor);
                                                }}
                                                onFocus={editor => {
                                                    console.log('Focus.', editor);
                                                }}
                                            />
                                            {/* <span className="help-block" style={style}>{this.state.validation.description.message}</span> */}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Redirect URL  <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.url}
                                                name="url"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style}>{this.state.validation.url.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">&nbsp;</label>
                                        <div className="col-md-10">
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" name="active" checked={this.state.active} onChange={this.handleInputChange} type="checkbox" id="autoSizingCheck" />
                                                <label className="form-check-label" htmlFor="autoSizingCheck">
                                                    Active
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <br />
                                        <button className="btn btn-primary" onClick={this.onUpdate.bind(this)}
                                            style={this.state.isUpdate ? {} : { display: 'none' }}
                                        >
                                            Update
                                        </button>
                                        &nbsp;
                                        <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-success btn-block"
                                            style={this.state.isUpdate ? { display: 'none' } : {}}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                    <br /><br />
                                </form>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

        )
    }
}
