import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";

function Header() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const logoutHandler = () => {
    dispatch(logout());
  };
 const notlogin = (
      <div></div>
    );
    const loginvalid = (
<div className="page-header ">
  <nav className="navbar navbar-expand justify-content-end">
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div >
    <ul className="navbar-nav ">
      <li className="nav-item small-screens-sidebar-link">
        <a href="#0"className="nav-link"><i className="material-icons-outlined">menu</i></a>
      </li>
      <li className="nav-item nav-profile dropdown">
        <a className="nav-link dropdown-toggle" href="#0"id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="assets/images/avatars/profile-image-1.png" alt="profile" />
          <span>Admin</span><i className="material-icons dropdown-icon">keyboard_arrow_down</i>
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">         
            <a className="dropdown-item" href="/login" onClick={logoutHandler}>Log out</a>
        </div>
      </li>
      <li className="nav-item">
        <a href="#0"className="nav-link" id="dark-theme-toggle"><i className="material-icons-outlined">brightness_2</i><i className="material-icons">brightness_2</i></a>
      </li>
    </ul>
</div>
    
  </nav>
</div>

    );

  return (
    <div>
            {userInfo ? loginvalid : notlogin}
     </div>
  );
}

export default Header;
