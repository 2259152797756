import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListparticipant } from "../../../actions/eventActions";
import { getUserbyuuid } from "../../../actions/userActions";
import { NavLink } from 'react-router-dom';

function Edit({ history }) {
    const [email, setEmail] = useState("");
    const [tlp, setTlp] = useState("");
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [position, setPosition] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    useEffect(() => {

        prop()        
       
        if (userInfo) {
            history.push("/eventrequester");
          }else{
            history.push("/login");
          }
    }, [
        history,
        userInfo
    ]);


    const prop = () => {    
            getListparticipant().then(res => {                
                getUserbyuuid(res.data.created.by).then(result =>{
                    setEmail(result.data ? result.data.email : '-')
                    setTlp(result.data ? result.data.tlp: '-')
                    setName(result.data ? result.data.name: '-')
                    setCompany(result.data ? result.data.namaprusahaan: '-')
                    setPosition(result.data ? result.data.jabatan: '-')
                    setGelardepan(result.data ? result.data.gelardepan: '-')
                    setGelarbelakang(result.data ? result.data.gelarbelakang: '-')
                })
            })
        }

    
    return (

        <div className="page-content">

            <div className="page-info">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/#">Apps</a></li>
                        <li className="breadcrumb-item active" aria-current="page">List Event</li>
                    </ol>
                </nav>

            </div>
            <div className="main-wrapper">
                <div className="row">
                    <div className="col-lg-12">

                    </div>
                    <div className="col-lg-12">
                        <div className="card card-transactions">
                            <div className="card-body">
                                <h5 className="card-title">Event Requester</h5>
                                <br/>
                                <nav className="navbar navbar-expand">
                                    <ul className="nav">
                                        <li><NavLink to="/eventeditview" className="nav-link active" href="#0">Event Detail</NavLink></li>
                                        <li><NavLink to="/eventparticipant" className="nav-link">Participant</NavLink></li>
                                        <li><NavLink to="/eventcertificate" className="nav-link">Certificate Template</NavLink></li>
                                        <li><NavLink to="/eventrequester" className="nav-link">Requester</NavLink></li>
                                    </ul>
                                </nav>
                                <hr />
                                <form>
                                    <div className="row mb-30">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Email</label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={email}
                                                                    readOnly
                                                                    placeholder="Enter the Event Title"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Phone Number</label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={tlp}
                                                                    readOnly
                                                                    placeholder="Enter the Event Title"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Full Name</label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={name}
                                                                    readOnly
                                                                    placeholder="Enter the Event Title"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Company Name</label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={company}
                                                                    readOnly
                                                                    placeholder="Enter the Event Title"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Position Name</label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={position}
                                                                    readOnly
                                                                    placeholder="Enter the Event Title"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Front Title</label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={gelardepan}
                                                                    readOnly
                                                                    placeholder="Enter the Event Title"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Back Degree</label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={gelarbelakang}
                                                                    readOnly
                                                                    placeholder="Enter the Event Title"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )
}
export default Edit;
