import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import { login } from "../../actions/userActions";
import { NavLink } from 'react-router-dom';

function LoginScreen({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      //   history.push("/dashboard");
      window.location.href = '/dashboard';
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-5">
          <div className="auth-form">
            <div className="row">
              <div className="col">
                <div className="logo-box"><a href="/#" className="logo-text">Login - Digihealth</a></div>
                {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
                <form onSubmit={submitHandler}>
                  <div className="form-group">
                    <input className="form-control" id="email" aria-describedby="emailHelp"
                      type="email"
                      value={email}
                      placeholder="Enter email"
                      onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <input className="form-control" id="password"
                      type="password"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <button type="submit" className="btn btn-primary btn-block btn-submit">Sign In</button>
                  <div className="auth-options">
                    <div className="custom-control custom-checkbox form-group">
                      <input type="checkbox" className="custom-control-input" id="exampleCheck1" />
                      <label className="custom-control-label" htmlFor="exampleCheck1">Remember me</label>
                    </div>
                    <NavLink to="/forgetpassword" className="forgot-link">Forget Password</NavLink>
                  </div>

                </form>
                {loading && <Loading />}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-none d-lg-block d-xl-block">
          <div className="auth-image" />
        </div>
      </div>
    </div>


  );
}

export default LoginScreen;
