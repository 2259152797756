import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom';
import { getList } from "../../actions/eventActions";
import Moment from 'react-moment';
import 'moment/locale/id';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 25,
            currentPage: 0,
            searchTitle: '',
            searchTipe: '',
            searchActive: '',
            searchPersetujuan:'',
            sort:''
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onEdit = this.onEdit.bind(this)

    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value },() => {
            this.receivedData()
        });
    }

    onEdit(id, type, title, date, time, moderator, banner, sertificate, skp, linkmeet, meetid,
         passcode, priority, active, ishome, speakers, paticipans, desc, isapply, timeend, slug, approval, e) {
        let recent = [];
        recent.push({ 'id': id, 'type': type, 'title': title, 'date': date, 'time': time, 'moderator': moderator,
         'banner': banner, 'sertificate': sertificate, 'skp': skp, 'linkmeet': linkmeet, 'meetid': meetid, 
         'passcode': passcode, 'priority': priority, 'active': active, 'ishome': ishome, 'speakers': speakers, 
         'paticipans': paticipans, 'desc': desc, 'isapply':isapply, 'timeend':timeend, 'slug':slug, 'approval':approval  });
        localStorage.setItem('recent', JSON.stringify(recent));
        this.props.history.push(`/eventeditview`)

    }

    getData()
    {
        getList().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {
                const master = res['data'] ? res['data'] : [] ;
                this.setState({ data: master})
                this.receivedData()
            }
        })
    }

    receivedData() {

        
                let newDate = new Date()
                let date = newDate.getDate();
                let month = newDate.getMonth() + 1;
                let year = newDate.getFullYear();
                let separator = "-"
                let datenow = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
                var datecheck = this.state.searchActive
                var filterData
                var result
                const master = this.state.data
                var data
                if(this.state.sort === 'Descending')
                {
                    data = master.sort((a,b) => new Date(a.created.at) < new Date(b.created.at) ? 1 : -1)
                }else if(this.state.sort === 'Ascending'){
                    data = master.sort((a,b) => new Date(a.created.at) > new Date(b.created.at) ? 1 : -1)
                }else{
                    data = master.sort((a,b) => new Date(a.created.at) < new Date(b.created.at) ? 1 : -1)
                }
                if (datecheck === "today") {
                    result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                        && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)&& (this.state.searchPersetujuan ? item.isapprove === this.state.searchPersetujuan : item.isapprove)
                        && (item.date === datenow))
                    const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                        && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type) && (this.state.searchPersetujuan ? item.isapprove === this.state.searchPersetujuan : item.isapprove)
                        && (item.date === datenow))
                } else if (datecheck === "yesterday") {
                    result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                        && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type) && (this.state.searchPersetujuan ? item.isapprove === this.state.searchPersetujuan : item.isapprove)
                        && (item.date < datenow))
                    const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                        && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type) && (this.state.searchPersetujuan ? item.isapprove === this.state.searchPersetujuan : item.isapprove)
                        && (item.date < datenow))
                } else if (datecheck === "tomorrow") {
                    result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                        && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type) && (this.state.searchPersetujuan ? item.isapprove === this.state.searchPersetujuan : item.isapprove)
                        && (item.date > datenow))
                    const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                        && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type) && (this.state.searchPersetujuan ? item.isapprove === this.state.searchPersetujuan : item.isapprove)
                        && (item.date > datenow))
                } else {
                    result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                        && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type) && (this.state.searchPersetujuan ? item.isapprove === this.state.searchPersetujuan : item.isapprove))
                    const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                        && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type) && (this.state.searchPersetujuan ? item.isapprove === this.state.searchPersetujuan : item.isapprove))
                }

                const postData = filterData
                    .map(one => <React.Fragment>
                        <tr className="application-item">
                            <td>{one.type}</td>
                            <td>{one.title}</td>
                            <td>{one.active === "true" && <>Aktif</>} {one.active === "false" && <>Nonaktif</>}</td>
                            <td>{one.isapprove === "Diajukan" && <>Draft</>} {one.isapprove === "Ditolak" && <>Reject</>} {one.isapprove === "Disetujui" && <>Approved</>}</td>
                            <td>
                                {one.enddate !== undefined && one.date === one.enddate && <><Moment locale="id" format="DD MMM YYYY">{one.date}</Moment> ,</>}
                                {one.enddate === undefined && <><Moment locale="id" format="DD MMM YYYY">{one.date}</Moment> ,</>}
                                {one.enddate !== undefined && one.date !== one.enddate && <><Moment locale="id" format="DD MMM YYYY">{one.date}</Moment> - <Moment locale="id" format="DD MMM YYYY">{one.enddate}</Moment>,</>}
                            
                             <br/> {one.time} -  {one.timeend} WIB</td>
                            <td>{one.paticipans.length}</td>
                            <td>
                                <a href={"https://digihealth.id/events/"+one.slug} target="_blank" rel="noreferrer" className="btn btn-info btn-sm"><i className="fas fa-link"></i></a>
                                <button onClick={this.onEdit.bind(this, one.id, one.type, one.title, one.date, one.time, one.moderator, one.banner, one.sertificate, one.skp, one.linkmeet, one.meetid, one.passcode, one.priority, one.active, one.ishome, one.speakers, one.paticipans, one.desc, one.isapply, one.timeend, one.slug, one.isapprove)} className="btn btn-warning btn-sm" ><i className="fas fa-edit"></i></button>
                            </td>
                        </tr>
                    </React.Fragment>)

                this.setState({
                    pageCount: Math.ceil(result.length / this.state.perPage),

                    postData
                })
          
    }
   
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.getData()
        }else{
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }
    render() {
        return (
            <div className="page-content">

                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/#">Apps</a></li>
                            <li className="breadcrumb-item active" aria-current="page">List Event</li>
                        </ol>
                    </nav>

                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">

                        </div>
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Event Management<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                    <div className="row ">
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label"></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.searchTitle}
                                                    name="searchTitle"
                                                    placeholder="enter name event"
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Status</label>
                                                <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                    <option value="">All</option>
                                                    <option value="today">Sedang Berlangsung</option>
                                                    <option value="tomorrow">Akan Datang</option>
                                                    <option value="yesterday">Sudah Selesai</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Tipe</label>
                                                <select className="form-control" name="searchTipe" value={this.state.searchTipe} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="Seminar">Seminar</option>
                                                        <option value="Webinar">Webinar</option>
                                                    </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Persetujuan</label>
                                                    <select className="form-control" name="searchPersetujuan" value={this.state.searchPersetujuan} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="Diajukan">Draft</option>
                                                        <option value="Ditolak">Reject</option>
                                                        <option value="Disetujui">Approved</option>
                                                    </select>
                                                </div>
                                        </div>
                                        <div className="col-md-2">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Sort By Created Date</label>
                                                    <select className="form-control" name="sort" value={this.state.sort} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="Ascending">Longest</option>
                                                        <option value="Descending">Latest</option>
                                                    </select>
                                                </div>
                                        </div>
                                        <div className="col-md-1">
                                                 <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label py-2"> </label>
                                                    <div className="form-group row">
                                                        <NavLink to="/eventaddview" className="btn btn-success btn-sm btn-block" ><i className="fas fa-plus"></i> Add</NavLink>
                                                    </div>
                                                </div>
                                            
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Event Type</th>
                                                    <th>Name Event</th>
                                                    <th>Active</th>
                                                    <th>Approval</th>
                                                    <th>Event Duration</th>
                                                    <th>Participant</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.postData}
                                            </tbody>
                                        </table>
                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-left"></i>}
                                            nextLabel={<i className="fa fa-angle-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-end asrt-pagination"}
                                            subContainerClassName={"page pagination"}
                                            activeClassName={"active"} />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
