import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import {getListparticipant, updateEventAction, Sendmailremainder, getList, Getlistnotif , updateApprovebisnis, Sendmailapprove, Sendmailreject} from "../../../actions/eventActions";
import { upload, deletecontent, getUserbyuuid } from "../../../actions/userActions";
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import {  Syncevent } from "../../../actions/pagemanajemenAction";
var axios = require('axios');
const MySwal = withReactContent(Swal)
const style = {
    color: 'red',
};

function Edit({ history }) {
    const dispatch = useDispatch();
    const [id, setId] = useState("");
    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [enddate, setEnddate] = useState("");
    const [convertEndDate, setConvertEndDate] = useState("");
    const [convertStartDate, setConvertStartDate] = useState("");
    const [time, setTime] = useState("");
    const [desc, setDesc] = useState("");
    const [moderator, setModerator] = useState("");
    const [banner, setBanner] = useState("");
    const [sertificate, setSertificate] = useState("");
    const [publish, setPublish] = useState("");
    const [skp, setSkp] = useState("");
    const [linkmeet, setLinkmeet] = useState("");
    const [meetid, setMeetid] = useState("");
    const [passcode, setPasscode] = useState("");
    const [priority, setPriority] = useState("");
    const [slug, setSlug] = useState("");
    const [active, setActive] = useState(false);
    const [ishome, setIshome] = useState(false);
    const [speakers, setSpeakers] = useState([{ name: "", materi: "", file:'-', tipe:'1' }]);
    const [paticipans, setPaticipans] = useState([]);
    const eventCreate = useSelector((state) => state.eventCreate);
    const userLogin = useSelector((state) => state.userLogin);
    const [message, setMessage] = useState("");
    const [isapply, setIsapply] = useState(true);
    const [alert, setAlert] = useState(false);
    const [warning, ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timeend, setTimeend] = useState("");
    const [approval, setApproval] = useState("");
    const [approvalold, setApprovalold] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");
    const bucket = process.env.REACT_APP_API_KEY
    const AUTH_FIREBASE = process.env.REACT_APP_AUTH_FIREBASE

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    const {
        loading: loadingCreate,
        success: successCreate,
    } = eventCreate;
    const { userInfo } = userLogin;
    useEffect(() => {
               
        if (userInfo) {
            getListparticipant().then(res => {                
                getUserbyuuid(res.data.created.by).then(result =>{
                    //for requester
                    setEmail(result.data ? result.data.email : '')
                    setName(result.data ? result.data.name :'')
                    setGelardepan(result.data ? result.data.gelardepan : '')
                    setGelarbelakang(result.data ? result.data.gelarbelakang :'')
                    // for detail event
                    setId(res.data.id);
                    setType(res.data.type);
                    setTitle(res.data.title);
                    setDate(res.data.date ? new Date(res.data.date) : new Date());
                    setEnddate(res.data.enddate ? new Date(res.data.enddate) : new Date());
                    setConvertStartDate(res.data.date);
                    setConvertEndDate(res.data.enddate);
                    setTime(res.data.time);
                    setTimeend(res.data.timeend);
                    setModerator(res.data.moderator);
                    setBanner(res.data.banner);
                    setSertificate(res.data.sertificate);
                    setSkp(res.data.skp);
                    setLinkmeet(res.data.linkmeet);
                    setMeetid(res.data.meetid);
                    setPasscode(res.data.passcode);
                    setPriority(res.data.priority);
                    setApproval(res.data.isapprove);
                    setApprovalold(res.data.isapprove);
                    setPublish(res.data.publish);
                    setSlug(res.data.slug);
                    var valhome = (res.data.active === "true");
                    var valactive = (res.data.ishome === "true");
                    var valapply = (res.data.isapply === "true");
                    document.getElementById("previewbanner").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+res.data.banner 
                    setActive(valhome);
                    setIshome(valactive);
                    setIsapply(valapply);
                    setPaticipans(res.data.paticipans);
                    if(res.data.speakers.length !== 0)
                    {
                        setSpeakers(res.data.speakers);
                    }
                    setDesc(res.data.desc);
                    })
                })
            history.push("/eventeditview");
          }else{
            history.push("/login");
          }
    }, [
        history,
        userInfo,
        register,
        successCreate,
        bucket
    ]);

    const handleModelChange = (model) => {
        setDesc(model)
    }

    const resetHandler = () => {
        setType("");
        setTitle("");
        setDate("");
        setTime("");
        setModerator("");
        setBanner("");
        setSertificate("");
        setSkp("");
        setLinkmeet("");
        setMeetid("");
        setPasscode("");
        setPriority("");
        setActive("");
        setIshome("");
        setIsapply("");
        setDesc("");
        setSpeakers([{ name: "", materi: "" }]);
    };

    const remainderemailHandler = (e) => {
        e.preventDefault()
        Sendmailremainder();
        setAlert(true);
        setMessage("waiting send mail to member");
        setTimeout(() => {
            setAlert(false);
            }, 8000);
    };

    const remaindernotifHandler = (e) => {
        e.preventDefault()
        Getlistnotif().then(res => {
            var notif = res.data
            for (let index = 0; index < notif.length; index++) {
                console.log(notif[index]['endpoint'])
                var data = JSON.stringify({
                    "notification": {
                        "title": "Info Even "+title,
                        "body": "Tanggal Mulai Event "+date,
                        "icon": "https://digihealth.id/assets/images/logo-digihealth-new.png",
                        "click_action": "https://digihealth.id"
                    },
                    "data": {
                        "message": "Event",
                    },
                    "to": notif[index]['auth']
                  });
                  
                  var config = {
                    method: 'post',
                    url: 'https://fcm.googleapis.com/fcm/send',
                    headers: { 
                      'Authorization': AUTH_FIREBASE, 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then(function (response) {
                    console.log(JSON.stringify(response.data));
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
                  
                  
            }
        })

                
        // Sendnotif(raw);
        setAlert(true);
        setMessage("waiting send mail to member");
        setTimeout(() => {
            setAlert(false);
            }, 8000);
    };

    const submitHandler = () => {
        
        if(banner !== "" && timeend > time){
            var newslug
            var Text = title
                Text = Text.toLowerCase();
                Text = Text.replace(/[^a-zA-Z0-9]+/g,'-');
            var isactive = active.toString()
            var home = ishome.toString()
            var apply = isapply.toString()
            getList().then(res => {
                const data = res['data'] ? res['data'] : [];
                var result = data.filter(item => (slug ? item.slug === slug : []))
                let linkmeetremove = linkmeet.replace(/ /g, '')
                const requestapprove = {
                    id:id,
                    isapprove:approval
                }
                updateApprovebisnis(requestapprove)
                if(approval === "Disetujui" && approval !== approvalold)
                {
                    Sendmailapprove(email, gelardepan, name, gelarbelakang, type, title, date, time, timeend)
                }else if(approval === "Ditolak" && approval !== approvalold){
                    Sendmailreject(email, gelardepan, name, gelarbelakang, type, title, date, time, timeend)
                }
                if (result.length > 1) {
                    var random = Math.random().toString().substr(2, 5);
                    newslug = Text + '-' + random
                    dispatch(updateEventAction(id, type, title, convertStartDate, time, moderator, banner, sertificate, skp, linkmeetremove, meetid, passcode, priority, isactive, home, speakers, paticipans, desc, apply, newslug, timeend, publish, convertEndDate  ));
                    // Syncevent()
                    if (!type) return;
                    resetHandler();
                    history.push("/eventlist");
                } else {
                    newslug = result[0]['slug']
                    dispatch(updateEventAction(id, type, title, convertStartDate, time, moderator, banner, sertificate, skp, linkmeetremove, meetid, passcode, priority, isactive, home, speakers, paticipans, desc, apply, newslug, timeend, publish, convertEndDate));
                    // Syncevent()
                    if (!type) return;
                    resetHandler();
                    history.push("/eventlist");
                }
            })
           
        }
        if(timeend < time || timeend === time){
            MySwal.fire('Oops...', 'Maaf waktu akhir time harus lebih besar dari event start', 'info')
        }
        if(banner === "" || banner === undefined){
            setAlert(true)
        }
    };

    const postDetails = (pics) => {
        // setPicMessage(null);
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png")) {
        document.getElementById("previewbanner").style.display = 'none';
            setLoading(true)
            if (banner !== "" || banner !== null ) {
                const deleteRequest = {
                    path: bucket+'/event',
                    file: banner
                }
                deletecontent(deleteRequest)
            }
          const Request = {
                path: bucket+'/event',
                myFile: pics
            }

            upload(Request).then((res) => {
                setBanner(res.data);
                setLoading(false)
                setAlert(false)
                document.getElementById("previewbanner").style.display = 'block';
                document.getElementById("previewbanner").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+res.data  
            }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })

        } else {
            document.getElementById("previewbanner").style.display = 'none';
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type file yang anda masukan tidak cocok', 'info')
        }
      };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...speakers];
        list[index][name] = value;
        setSpeakers(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...speakers];
        list.splice(index, 1);
        setSpeakers(list);
    };

    const handleUploadClick = (pics, index) => {
        if (pics.size < 5100000 && (pics.type === "application/vnd.ms-powerpoint" || pics.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || pics.type === "application/msword" || pics.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || pics.type === "application/pdf")) {
            document.getElementById("upload"+index).classList.remove("fa-upload");
            document.getElementById("upload" + index).classList.add("fa-spinner", "fa-spin");
            const list = [...speakers];
           if (list[index]['file'] !== undefined) {
                const deleteRequest = {
                    path: bucket+'/materi',
                    file: list[index]['file']
                }
                deletecontent(deleteRequest)
            }
            
            const fileRequest = {
                path: bucket+'/materi',
                myFile: pics
            }
            upload(fileRequest).then((res) => {
                document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
                document.getElementById("upload"+index).classList.add("fa-upload");
                const list = [...speakers];
                list[index]['file'] = res.data
                setSpeakers(list);
                document.getElementById("preview"+index).href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/materi/"+res.data

            }).catch((err) => {
                document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
                document.getElementById("upload"+index).classList.add("fa-upload");
                MySwal.fire('Oops...', 'sorry server busy please tray again' , 'info')
            })

            
        } else {
            document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
            document.getElementById("upload"+index).classList.add("fa-upload");
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setSpeakers([...speakers, { name: "", materi: "", file: "-", type:"1" }]);
    };


    const handleChangeStartdate = (date) => {
        let newdate
        let newmonth
        if(date.getDate() < 10){newdate = '0'+(date.getDate())}else{newdate = date.getDate()}
        if(date.getMonth() < 9){newmonth = '0'+(date.getMonth()+1)}else{newmonth = date.getMonth()+1}
        let convertdate =  `${date.getFullYear()}-${newmonth}-${newdate}`;
        setDate(date);
        setConvertStartDate(convertdate);
        setEnddate(date)
        setConvertEndDate(convertdate)
    }

    const handleChangeEnddate = (date) => {
        let newdate
        let newmonth
        if(date.getDate() < 10 ){newdate = '0'+(date.getDate())}else{newdate = date.getDate()}
        if(date.getMonth() < 9){newmonth = '0'+(date.getMonth()+1)}else{newmonth = date.getMonth()+1}
        let convertdate =  `${date.getFullYear()}-${newmonth}-${newdate}`;
        setConvertEndDate(convertdate);
        setEnddate(date)
    }

    const CustomInputstartdate = (props) => {
        return (
            <input
                className="form-control" 
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
            />
        )
    }

    const CustomInputenddate = (props) => {
        return (
            <input
                className="form-control" 
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
            />
        )
    }

    return (

        <div className="page-content">

            <div className="page-info">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/#">Apps</a></li>
                        <li className="breadcrumb-item active" aria-current="page">List Event</li>
                    </ol>
                </nav>

            </div>
            <div className="main-wrapper">
                <div className="row">
                    <div className="col-lg-12">

                    </div>
                    <div className="col-lg-12">
                        <div className="card card-transactions">
                            <div className="card-body">
                                <h5 className="card-title">Event Management</h5>
                                <br/>
                                <div style={alert ? {} : { display: 'none' }}   className="alert alert-danger" id="success-alert">
                                    <div className="d-flex align-items-center">
                                        <strong><strong>Warning! </strong> {message} ...</strong>
                                        <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </div>
                                <nav className="navbar navbar-expand">
                                    <ul className="nav">
                                        <li><NavLink to="/eventeditview" className="nav-link active" href="#0">Event Detail</NavLink></li>
                                        <li><NavLink to="/eventparticipant" className="nav-link">Participant</NavLink></li>
                                        <li><NavLink to="/eventcertificate" className="nav-link">Certificate Template</NavLink></li>
                                        <li><NavLink to="/eventrequester" className="nav-link">Requester</NavLink></li>
                                    </ul>
                                </nav>
                                <hr />
                                <form onSubmit={handleSubmit(submitHandler)}>
                                    <div className="row mb-30">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <div className="single-input mb-25">
                                                            <div className="form-group row">
                                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Approval <span>*</span></label>
                                                                <div className="col-sm-10">
                                                                    <select className="form-control" name="approval" value={approval} onChange={(e) => setApproval(e.target.value)}>
                                                                        <option disabled value="Diajukan">Draft</option>
                                                                        <option value="Ditolak">Reject</option>
                                                                        <option value="Disetujui">Approved</option>
                                                                    </select>
                                                                    {errors.type && <p style={style}>Event type is required.</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Event Type <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                 <select className="form-control"                                                                     
                                                                    {...register('type', { required: true })}
                                                                    name="type" value={type} onChange={(e) => setType(e.target.value)}>
                                                                    <option disabled>Select</option>
                                                                    <option value="Seminar">Seminar</option>
                                                                    <option value="Webinar">Webinar</option>
                                                                </select>
                                                                {errors.type && <p style={style}>Event type is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Publish <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                 <select className="form-control"                                                                     
                                                                    {...register('publish', { required: true })}
                                                                    name="publish" value={publish} onChange={(e) => setPublish(e.target.value)}>
                                                                    <option disabled>Select</option>
                                                                    <option value="public">Public</option>
                                                                    <option value="private">Private</option>
                                                                </select>
                                                                {errors.publish && <p style={style}>Event publish is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Event Title <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={title}
                                                                    name="title"
                                                                    placeholder="Enter the Event Title"
                                                                    {...register('title', { required: true, value: title })}
                                                                    onChange={(e) => setTitle(e.target.value)}
                                                                />
                                                                {errors.title && <p style={style}>Event title is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Event Start Date <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                <DatePicker
                                                                    selected={date}
                                                                    value={date}
                                                                    name="date"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    {...register('date', { required: false })}
                                                                    onChange={(date) => handleChangeStartdate(date)}
                                                                    selectsStart
                                                                    startDate={date}
                                                                    endDate={enddate}
                                                                    disabledKeyboardNavigation
                                                                    customInput={<CustomInputstartdate />}
                                                                />
                                                                {errors.date && <p style={style}>Event date is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Event End Date <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                <DatePicker
                                                                    selected={enddate}
                                                                    onChange={(date) => handleChangeEnddate(date)}
                                                                    selectsEnd
                                                                    dateFormat="dd/MM/yyyy"
                                                                    startDate={date}
                                                                    endDate={enddate}
                                                                    minDate={date}
                                                                    disabledKeyboardNavigation
                                                                    customInput={<CustomInputenddate />}
                                                                />

                                                                {errors.enddate && <p style={style}>Event end date is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Event Start Time <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                <input
                                                                    type="time"
                                                                    className="form-control"
                                                                    value={time}
                                                                    name="time"
                                                                    placeholder="Enter the Event Time"
                                                                    {...register('time', { required: true, value:time })}
                                                                    onChange={(e) => setTime(e.target.value)}
                                                                />
                                                                {errors.time && <p style={style}>Event time is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Event End Time <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                <input
                                                                    type="time"
                                                                    className="form-control"
                                                                    value={timeend}
                                                                    name="timeend"
                                                                    {...register('timeend', { required: true, value:timeend })}
                                                                    placeholder="Enter the Event End Time"
                                                                    onChange={(e) => setTimeend(e.target.value)}
                                                                />
                                                                {errors.timeend && <p style={style}>Event End time is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">

                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Moderator <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={moderator}
                                                                    name="moderator"
                                                                    placeholder="Enter the Event Moderator"
                                                                    {...register('moderator', { required: true, value:moderator })}
                                                                    onChange={(e) => setModerator(e.target.value)}
                                                                />
                                                                {errors.moderator && <p style={style}>Event moderator is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Banner <span>*</span></label>
                                                            <div className="col-sm-5">
                                                            <span>Upload file dalam bentuk jpg/jpeg/png & Max size file 5mb</span>
                                                            <input
                                                                className="form-control"
                                                                onChange={(e) => postDetails(e.target.files[0])}
                                                                id="custom-file"
                                                                type="file"
                                                                label="Upload Profile Picture"
                                                                />
                                                            <p style={warning ? {color:"red"} : { display: 'none' }}>Event banner is required.</p>
                                                            </div>
                                                            <div className="col-sm-2 py-4">
                                                                <div style={loading ? {} : { display: 'none' }} className="spinner-border text-primary col-form-label br-2" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                                <a style={{ width:'50px' }} href="#0" target="_blank" rel="noreferrer" id="previewbanner" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Point SKP <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={skp}
                                                                    name="skp"
                                                                    placeholder="Enter the Point SKP"
                                                                    {...register('skp', { required: true, value:skp })}
                                                                    onChange={(e) => setSkp(e.target.value)}
                                                                />
                                                                {errors.skp && <p style={style}>Event skp is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">&nbsp;</label>
                                                            <div className="col-sm-10">
                                                                <div className="form-check mb-2">
                                                                    <input className="form-check-input" name="active" checked={active}
                                                                    onChange={e => setActive(e.target.checked)} type="checkbox" id="autoSizingCheck" />
                                                                    <label className="form-check-label" htmlFor="autoSizingCheck">
                                                                    Active
                                                                    </label>
                                                                </div>
                                                                <div className="form-check mb-2">
                                                                    <input className="form-check-input" name="ishome" checked={ishome}
                                                                    onChange={e => setIshome(e.target.checked)} type="checkbox" id="autoSizingCheckhome" />
                                                                    <label className="form-check-label" htmlFor="autoSizingCheckhome">
                                                                        Tampilkan Dihomepage
                                                                    </label>
                                                                </div>
                                                                <div className="form-check mb-2">
                                                                    <input className="form-check-input" name="isapply" checked={isapply}
                                                                    onChange={e => setIsapply(e.target.checked)} type="checkbox" id="autoSizingCheckapply" />
                                                                    <label className="form-check-label" htmlFor="autoSizingCheckapply">
                                                                        Show Button Apply
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Virtual Meeting Room <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={linkmeet}
                                                                    name="linkmeet"
                                                                    placeholder="Enter the Virtual Meeting Room"
                                                                    {...register('linkmeet', { required: true, value:linkmeet })}
                                                                    onChange={(e) => setLinkmeet(e.target.value)}
                                                                />
                                                                {errors.linkmeet && <p style={style}>Event linkmeet is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">

                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Meeting ID <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={meetid}
                                                                    name="meetid"
                                                                    {...register('meetid', { required: true, value:meetid })}
                                                                    placeholder="Enter the Meeting ID"
                                                                    onChange={(e) => setMeetid(e.target.value)}
                                                                />
                                                            {errors.meetid && <p style={style}>Event meetid is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">

                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Passcode <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={passcode}
                                                                    name="passcode"
                                                                    placeholder="Enter the Passcode"
                                                                    {...register('passcode', { required: true, value:passcode })}
                                                                    onChange={(e) => setPasscode(e.target.value)}
                                                                />
                                                                {errors.passcode && <p style={style}>Event passcode is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Priority <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={priority}
                                                                    name="priority"
                                                                    {...register('priority', { required: true, value:priority })}
                                                                    placeholder="Enter the Priority"
                                                                    onChange={(e) => setPriority(e.target.value)}
                                                                />
                                                                {errors.priority && <p style={style}>Event priority is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Desc <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <FroalaEditorComponent tag='textarea'
                                                                    value={desc}
                                                                    name="desc"
                                                                    placeholder="Enter the desc"
                                                                    model={desc}
                                                                    {...register('desc', { required: true, value:desc })}
                                                                    onModelChange={handleModelChange}>
                                                                </FroalaEditorComponent>                                                                
                                                                {errors.desc && <p style={style}>Event desc is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Speaker </label>
                                                            <div className="col-sm-12 alert alert-success">
                                                                <label htmlFor="first-name" className="col-sm-3 col-form-label">Nama Speaker </label>
                                                                <label htmlFor="first-name" className="col-sm-3 col-form-label">Materi </label>
                                                                <label htmlFor="first-name" className="col-sm-1 col-form-label">File </label>
                                                                <label htmlFor="first-name" className="col-sm-3 col-form-label">Show Type </label>

                                                                {speakers.map((x, i) => {
                                                                    return (
                                                                        <div className="box row">
                                                                            <div className="col-sm-3">
                                                                                <input
                                                                                    name="name"
                                                                                    className="form-control"
                                                                                    placeholder="Enter First Name"
                                                                                    value={x.name}
                                                                                    onChange={e => handleInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <input
                                                                                    name="materi"
                                                                                    className="form-control"
                                                                                    placeholder="Enter Last Name"
                                                                                    value={x.materi}
                                                                                    onChange={e => handleInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                            <div className="col-sm-1.5" style={{width:"90px"}}>
                                                                                {x.file !== "-" && x.file !== "" && <><a href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/materi/"+x.file} target="_blank" rel="noreferrer" id={"preview" + i} className="btn btn-primary btn-sm mb-2" ><i className="fas fa-image"></i></a>&nbsp;</>}
                                                                                    <input
                                                                                    className="btn btn-info btn-sm mb-2"
                                                                                    onChange={(e) => handleUploadClick(e.target.files[0], i)}
                                                                                    id={"custom"+i}
                                                                                    type="file"
                                                                                    name="file"
                                                                                    style={{display: "none"}}
                                                                                    label="Upload Profile Picture"
                                                                                    />
                                                                                <a href="#0"  className="btn btn-info btn-sm mb-2" onClick={(e) =>  $("#custom"+i).trigger("click")}><i id={"upload"+i} className="fas fa-upload"></i></a>

                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <select className="form-control" name="type" value={x.type} onChange={e => handleInputChange(e, i)}>
                                                                                    <option disabled>Select</option>
                                                                                    <option value="1">After Success Event Registration</option>
                                                                                    <option value="2">After Event Is Over</option>
                                                                                    <option value="3">After Member Get Certificate</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="btn-box col-sm-1.5">
                                                                                {speakers.length !== 1 && <span><button
                                                                                    className="btn btn-danger btn-sm"
                                                                                    onClick={() => handleRemoveClick(i)}><i className="fas fa-trash-alt"></i></button></span>}

                                                                                {speakers.length - 1 === i && <button className="btn btn-warning btn-sm" onClick={handleAddClick}><i className="fas fa-plus-square"></i></button>}

                                                                            </div>

                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="profile-action-btn d-flex flex-wrap align-content-center">
                                                <button className="btn btn-primary" type="submit"><i className="fas fa-save"></i> Update Event</button>&nbsp;&nbsp;
                                                <button className="btn btn-warning"  onClick={(e) => remaindernotifHandler(e)}>Send Notif Remainder</button>&nbsp;
                                                <button className="btn btn-info" onClick={(e) => remainderemailHandler(e)}>Send Mail Remainder</button>
                                            </div>
                                        </div>
                                    </div>
                                    {loadingCreate && <Loading />}
                                </form>

                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )
}
export default Edit;
