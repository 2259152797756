import React, { Component } from 'react';
import { deleteItem, addToList, updateItem, getList } from "../../actions/documentmanajemenAction";
import { getList as Listcategory } from "../../actions/categorylibraryAction";
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import FormValidator from '../../components/FormValidator'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { upload, deletecontent } from "../../actions/userActions";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
const bucket = process.env.REACT_APP_API_KEY

const style = {
    color: 'red',
};

export default class App extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
                field: 'title',
                method: 'isEmpty',
                validWhen: false,
                message: 'title is required'
            },
            {
                field: 'category',
                method: 'isEmpty',
                validWhen: false,
                message: 'category is required'
            },
            {
                field: 'author',
                method: 'isEmpty',
                validWhen: false,
                message: 'author is required'
            },
            {
                field: 'page',
                method: 'isEmpty',
                validWhen: false,
                message: 'page is required'
            },
            {
                field: 'banner',
                method: 'isEmpty',
                validWhen: false,
                message: 'banner is required'
            },
            {
                field: 'synopsis',
                method: 'isEmpty',
                validWhen: false,
                message: 'synopsis is required'
            }
            
        ]);
        this.state = {
            offset: 1,
            data: [],
            id:"",
            perPage: 25,
            title: "",
            titlemodal: "",
            author: "",
            category:[],
            page: "",
            banner:"",
            documentelibrary:"",
            video:"",
            synopsis:"",
            download:true, 
            active: true,
            currentPage: 1,
            searchTitle: '',
            searchActive: '',
            searchDownload: '',
            isUpdate: false,
            alert: false,
            message: "",
            errorMessage: '',
            slug: "",
            selectOptions: [],
            loading:false,
            uploadload:false,
            validation: this.validator.valid(),
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCreate = this.onCreate.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleModelChange(model) {
        this.setState({
            description: model
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    onCreate(e) {
        e.preventDefault()
        this.setState({
            title: "",
            titlemodal: "Add New Document",
            author: "",
            page: "",
            category:[],
            banner:"",
            documentelibrary:"",
            video:"",
            synopsis:"",
            download:true, 
            active: true,
            isUpdate: false,
        });
        document.getElementById('documentelibrary').value='';

    }

    receivedData() {
        getList(this.state.searchTitle,this.state.searchActive,this.state.searchDownload, this.state.currentPage  ).then(res => {
           
                const data = res['data']['data'] ? res['data']['data'] : [];
                const pagination = res['data']['pagination']['count']
                var postData = data
                    .map((one, index) => (
                        <tr className="application-item" key={index}>
                            <td>{one.title}</td>
                            <td>{one.category[0]['of']}</td>
                            <td>{one.created.at.split("T")[0]}</td>
                            <td>
                                {one.download === true ?
                                    <div className="productText">
                                        Yes
                                    </div>
                                    : null}
                                {one.download === false ?
                                    <div className="productText">
                                        No
                                    </div>
                                    : null}
                            </td>
                            <td>
                                {one.active === true ?
                                    <div className="productText">
                                        Active
                                    </div>
                                    : null}
                                {one.active === false ?
                                    <div className="productText">
                                        Nonactive
                                    </div>
                                    : null}
                            </td>
                            <td><button className="btn btn-danger btn-sm " onClick={this.onDelete.bind(this, one.id)} style={{ width: '50px' }}><i className="fas fa-trash"></i> </button>
                                <button className="btn btn-warning btn-sm " data-toggle="modal" data-target="#paycard" style={{ width: '50px' }} onClick={this.onEdit.bind(this, one.id, one.title, one.category, one.author,  one.page, one.banner, one.document, one.synopsis, one.download, one.active, one.video)}><i className="fas fa-edit"></i></button></td>
                        </tr>
                    ))
                
                this.setState({
                    pageCount: Math.ceil(pagination / this.state.perPage),
                    data: [...data],
                    postData
                })
            
        })
        Listcategory().then(res => {
            const datacategory = res['data'] ? res['data'] : [];
            const checkstatus = datacategory.filter(item => (item.active === true))
            this.setState({
                selectOptions:checkstatus
            })
        })

    }
    Filter = (e) => {
        this.setState({
            currentPage: 1,
            offset: 1
        }, () => {
            this.receivedData()
        });
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage+1,
            offset: offset
        }, () => {
            this.receivedData()
        });
    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
        } else {
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        if (validation.isValid) {
                if(this.state.video === "" && this.state.documentelibrary === "")
                {
                    MySwal.fire('Oops...', 'Sorry choose fill input video or document don`t empty', 'info')
                }else{
                    let checkdata = this.state.selectOptions.filter(item => (item.id === this.state.category))[0]
                    var of
                    if(checkdata['level1'] === false)
                    {
                    let checkparent = this.state.selectOptions.filter(item => (item.id === checkdata['parent']))
                    of =  checkdata['name']+' -> '+checkparent[0]['name']
                    }else{
                        of = checkdata['name']
                    }
                    var Text = this.state.title
                        Text = Text.toLowerCase();
                        Text = Text.replace(/[^a-zA-Z0-9]+/g,'-');
                    var random = Math.random().toString().substr(2, 5);
                    const cmsRequest = {
                        title: this.state.title,
                        author: this.state.author,
                        page: this.state.page,
                        category:[{id:this.state.category, name:checkdata['name'], of:of}],
                        banner:this.state.banner,
                        document:this.state.documentelibrary,
                        video:this.state.video,
                        synopsis:this.state.synopsis,
                        download:this.state.download, 
                        active: this.state.active,
                        slug:Text+'-'+random
                    }
                
                    addToList(cmsRequest).then(() => {
                        this.receivedData()
                        $(".close").trigger("click");
                    }).catch(err => {
                        this.setState({ editDisabled: false, errorMessage: err.message })
                    })
                }
        }

    }

    onDelete = (val, e) => {
        e.preventDefault()
        deleteItem(val).then(() => {
            this.receivedData()
        }).catch(err => {
            this.setState({ editDisabled: false, errorMessage: err.message })
        })
    }

    onEdit = (id, title, category, author, page, banner, document, synopsis, download, active, video, e) => {
        this.setState({
            id: id,
            titlemodal: "Edit Document",
            title: title,
            category:category[0]['id'],
            author:author,
            page:page,
            banner:banner,
            documentelibrary:document,
            synopsis:synopsis,
            video:video,
            download:download,
            isUpdate: true,
            active: active,
        })
    }

    onUpdate = e => {
        e.preventDefault()
        const validation = this.validator.validate(this.state);
        this.setState({ validation });

        if (validation.isValid) {
            let checkdata = this.state.selectOptions.filter(item => (item.id === this.state.category))[0]
            var of
            if(checkdata['level1'] === false)
            {
               let checkparent = this.state.selectOptions.filter(item => (item.id === checkdata['parent']))
               of =  checkdata['name']+' -> '+checkparent[0]['name']
            }else{
                of = checkdata['name']
            }
            const UpdateRequest = {
                id: this.state.id,
                title: this.state.title,
                author: this.state.author,
                page: this.state.page,
                category:[{id:this.state.category, name:checkdata['name'], of:of}],
                banner:this.state.banner,
                document:this.state.documentelibrary,
                video:this.state.video,
                synopsis:this.state.synopsis,
                download:this.state.download, 
                active: this.state.active,
            }
            updateItem(UpdateRequest).then(() => {
                this.receivedData();
                $(".close").trigger("click");
            }).catch(err => {
                this.setState({ isUpdate: false, errorMessage: err.message })
            })
            this.setState({ isUpdate: false })
        }
    }

    postDetails = (pics) => {
        // setPicMessage(null);
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png")) {
            this.setState({loading:true})
            if (this.state.banner !== "" || this.state.banner !== null ) {
                const deleteRequest = {
                    path: bucket+'/bannerelibrary',
                    file: this.state.banner
                }
                deletecontent(deleteRequest)
            }
          const Request = {
                path: bucket+'/bannerelibrary',
                myFile: pics
            }

            upload(Request).then((res) => {
                this.setState({banner:res.data,loading:false})
            }).catch((err) =>{
                this.setState({loading:false})
                MySwal.fire('Oops...', err , 'info')
            })

        } else {
            this.setState({loading:false})
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type file yang anda masukan tidak cocok', 'info')
        }
    };

     handleModelChange = (model) => {
        this.setState({synopsis:model})
    }

    postDetaildocument = (doc) => {
        if (doc.size < 5100000 && doc.type === "application/pdf" ) {
            this.setState({uploadload:true})
            if (this.state.documentelibrary !== "" || this.state.documentelibrary !== null ) {
                const deleteRequest = {
                    path: bucket+'/documentelibrary',
                    file: this.state.documentelibrary
                }
                deletecontent(deleteRequest)
            }
          const Request = {
                path: bucket+'/documentelibrary',
                myFile: doc
            }

            upload(Request).then((res) => {
                this.setState({documentelibrary:res.data, uploadload:false})

            }).catch((err) =>{
                this.setState({uploadload:false})
                MySwal.fire('Oops...', err , 'info')
            })
        } else {
            this.setState({uploadload:false})
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type file yang anda masukan tidak cocok', 'info')
        }
    };



    render() {
        return (

            <div className="page-content">
                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Apps</a></li>
                            <li className="breadcrumb-item active" aria-current="page">List Document Manajemen</li>
                        </ol>
                    </nav>
                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Document Elibrary Management<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"> </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.searchTitle}
                                                    name="searchTitle"
                                                    placeholder="enter title"
                                                    onChange={this.onChange}
                                                />
                                            </div></div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status</label>
                                                    <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="true">Active</option>
                                                        <option value="false">Inactive</option>
                                                    </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="staticEmail" className="col-form-label">Can Be Downloaded?</label>
                                                    <select className="form-control" name="searchDownload" value={this.state.searchDownload} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="true">YES</option>
                                                        <option value="false">NO</option>
                                                    </select>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <br/>
                                            <div className="form-group row py-4">
                                                <button className="btn btn-primary btn-sm btn-block" data-dismiss="modal" aria-label="Close" onClick={this.Filter.bind(this)}><i className="fas fa-search"></i> Filter</button>
                                            </div>
                                        </div>
                                        &nbsp;&nbsp;
                                        <div className="col-md-1">
                                            <br/>
                                            <div className="form-group row py-4">
                                                <button className="btn btn-success btn-sm btn-block" data-toggle="modal" data-target="#paycard" onClick={this.onCreate.bind(this)}><i className="fas fa-plus"></i> Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="table-responsive ">
                                        <table className="table" id="tree-table">
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Category</th>
                                                    <th>Created Date</th>
                                                    <th>Can be Downloaded</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.postData}
                                            </tbody>
                                        </table>
                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-left"></i>}
                                            nextLabel={<i className="fa fa-angle-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-end asrt-pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.titlemodal}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label"> Title  <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.title}
                                                name="title"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style} >{this.state.validation.title.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label"> Category  <span>*</span></label>
                                        <div className="col-md-10">
                                                    <select className="form-control" name="category" value={this.state.category} onChange={this.onChange}>
                                                        <option value="">Empty</option>
                                                        {this.state.selectOptions.map((one, index) => (
                                                            <option value={one.id} key={index}>{one.name}</option>
                                                        ))}
                                                    </select>
                                            <span className="help-block" style={style} >{this.state.validation.category.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Author / Provider <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.author}
                                                name="author"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style} >{this.state.validation.author.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Page Number <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={this.state.page}
                                                name="page"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style} >{this.state.validation.page.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Banner <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="file"
                                                id={"banner"}
                                                style={{ display: "none" }}
                                                accept="image/*"
                                                onChange={(e) => this.postDetails(e.target.files[0])}
                                                />
                                            {this.state.loading === false && <img
                                                className='buttonimage'
                                                src={this.state.banner ? "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/bannerelibrary/"+this.state.banner : "https://prosehat-marketplace.s3.us-east-2.amazonaws.com/upload-image.png"} alt="banner"
                                                id={"bannerimage"}
                                                style={{ objectFit: "cover" }}
                                                onClick={() => document.getElementById('banner').click()}
                                            />}
                                           {this.state.loading === true && <img
                                                className='buttonimage'
                                                src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/dev/bannerelibrary/7a8e8827-f935-4dad-aa4f-2d368d6b60f2.gif"} alt="banner"
                                                id={"bannerimageloading"}
                                                style={{ objectFit: "cover" }}
                                            /> }
                                            <span>Upload file dalam bentuk jpg/jpeg/png & Max size file 5mb</span>
                                            <span className="help-block" style={style} ><br/>{this.state.validation.banner.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Document</label>
                                        <div className="col-md-5">
                                            <input
                                                type="file"
                                                className="form-control"
                                                id="documentelibrary"
                                                name="documentelibrary"
                                                placeholder="enter title"
                                                onChange={(e) => this.postDetaildocument(e.target.files[0])}
                                                />
                                            <span>Upload file dalam bentuk PDF</span>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="col-sm-2 py-4">
                                               {this.state.uploadload === true && <div  className="spinner-border text-primary col-form-label br-2" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>}
                                                {this.state.documentelibrary !== undefined && this.state.documentelibrary !== "" && this.state.uploadload === false && <a style={{ width:'50px' }} href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/documentelibrary/"+this.state.documentelibrary} target="_blank" rel="noreferrer" id="previewbanner" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Url Video</label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.video}
                                                name="video"
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label"> Description  <span>*</span></label>
                                        <div className="col-md-10">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={this.state.synopsis}
                                                config={{
                                                    ckfinder: {
                                                        uploadUrl: '/api/v1/auth/upload/cms?path=' + bucket + '/cms&type=Files&responseType=json'
                                                    },
                                                    mediaEmbed: {
                                                        previewsInData: true
                                                    }
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    this.handleModelChange(data);
                                                    console.log({ event, editor, data });
                                                }}
                                                
                                            />
                                            <span className="help-block" style={style}>{this.state.validation.synopsis.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">&nbsp;</label>
                                        <div className="col-md-10">
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" name="active" checked={this.state.active} onChange={this.handleInputChange} type="checkbox" id="autoSizingCheck1" />
                                                <label className="form-check-label" htmlFor="autoSizingCheck1">
                                                    Active
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">&nbsp;</label>
                                        <div className="col-md-10">
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" name="download" checked={this.state.download} onChange={this.handleInputChange} type="checkbox" id="autoSizingCheck" />
                                                <label className="form-check-label" htmlFor="autoSizingCheck">
                                                Can Be Downloaded?
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <br />
                                        <button className="btn btn-primary" onClick={this.onUpdate.bind(this)}
                                            style={this.state.isUpdate ? {} : { display: 'none' }}
                                        >
                                            Update
                                        </button>
                                        &nbsp;
                                        <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-success"
                                            style={this.state.isUpdate ? { display: 'none' } : {}}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                    <br /><br />
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
