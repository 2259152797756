export const ARTICLE_LIST_REQUEST = "ARTICLE_LIST_REQUEST";
export const ARTICLE_LIST_SUCCESS = "ARTICLE_LIST_SUCCESS";
export const ARTICLE_LIST_FAIL = "ARTICLE_LIST_FAIL";

export const ARTICLE_CREATE_REQUEST = "ARTICLE_CREATE_REQUEST";
export const ARTICLE_CREATE_SUCCESS = "ARTICLE_CREATE_SUCCESS";
export const ARTICLE_CREATE_FAIL = "ARTICLE_CREATE_FAIL";

export const LIST_ARTICLE_REQUEST = "LIST_ARTICLE_REQUEST";
export const LIST_ARTICLE_SUCCESS = "LIST_ARTICLE_SUCCESS";
export const LIST_ARTICLE_FAIL = "LIST_ARTICLE_FAIL";

export const ARTICLE_DELETE_REQUEST = "ARTICLE_DELETE_REQUEST";
export const ARTICLE_DELETE_SUCCESS = "ARTICLE_DELETE_SUCCESS";
export const ARTICLE_DELETE_FAIL = "ARTICLE_DELETE_FAIL";
