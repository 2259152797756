import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import { createEventAction, getList } from "../../../actions/eventActions";
import { useForm } from 'react-hook-form';
import { upload, deletecontent } from "../../../actions/userActions";
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import {  Syncevent } from "../../../actions/pagemanajemenAction";

const MySwal = withReactContent(Swal)

const style = {
    color: 'red',
};

function Add({ history }) {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    const [type, setType] = useState("Seminar");
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [enddate, setEnddate] = useState("");
    const [convertEndDate, setConvertEndDate] = useState("");
    const [convertStartDate, setConvertStartDate] = useState("");
    const [time, setTime] = useState("");
    const [timeend, setTimeend] = useState("");
    const [moderator, setModerator] = useState("");
    const [banner, setBanner] = useState("");
    const [sertificate, setSertificate] = useState("-");
    const [skp, setSkp] = useState("");
    const [desc, setDesc] = useState("");
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [linkmeet, setLinkmeet] = useState("");
    const [meetid, setMeetid] = useState("");
    const [passcode, setPasscode] = useState("");
    const [publish, setPublish] = useState("public");
    const [priority, setPriority] = useState("");
    const [active, setActive] = useState(true);
    const [ishome, setIshome] = useState(true);
    const [isapply, setIsapply] = useState(true);
    const [speakers, setSpeakers] = useState([{ name: "", materi: "", file:'-', type:'1' }]);
    const [paticipans] = useState([]);
    const eventCreate = useSelector((state) => state.eventCreate);
    const userLogin = useSelector((state) => state.userLogin);
    const bucket = process.env.REACT_APP_API_KEY

    const {
        loading: loadingCreate,
        success: successCreate,
    } = eventCreate;
    const { userInfo } = userLogin;
    useEffect(() => {
        if (!userInfo) {
            history.push("/eventaddview");
        }
    }, [
        history,
        userInfo,
        successCreate,
    ]);

    const postDetails = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png")) {
            document.getElementById("previewbanner").style.display = 'none';
            setLoading(true)
            if (banner !== "" || banner !== null ) {
                const deleteRequest = {
                    path: bucket+'/event',
                    file: banner
                }
                deletecontent(deleteRequest)
            }

            const Request = {
                path: bucket+'/event',
                myFile: pics
            }

            upload(Request).then((res) => {
                setBanner(res.data);
                setLoading(false)
                setAlert(false)
                document.getElementById("previewbanner").style.display = 'block';
                document.getElementById("previewbanner").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+res.data  
            }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })

        } else {
            document.getElementById("previewbanner").style.display = 'none';
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type file yang anda masukan tidak cocok', 'info')
        }
    };


    const resetHandler = () => {
        setType("");
        setTitle("");
        setDate("");
        setTime("");
        setModerator("");
        setBanner("");
        setSertificate("");
        setSkp("");
        setLinkmeet("");
        setMeetid("");
        setPasscode("");
        setPriority("");
        setActive("");
        setIshome("");
        setDesc("");
        setIsapply("");
        setSpeakers([{ name: "", materi: "" }]);
    };

    const submitHandler = () => {
        if(banner !== "" && timeend > time)
        {
            var newslug
            var Text = title
                Text = Text.toLowerCase();
                Text = Text.replace(/[^a-zA-Z0-9]+/g,'-');
            var isactive = active.toString()
            var home = ishome.toString()
            var apply = isapply.toString()
            getList().then(res => {
                const data = res['data'] ? res['data'] : [];
                var result = data.filter(item => (title ? item.title === title : []))
                let linkmeetremove = linkmeet.replace(/ /g, '')
                if (result.length !== 0)
                {
                    var random = Math.random().toString().substr(2, 5);
                    newslug = Text + '-' + random
                    dispatch(createEventAction(type, title, convertStartDate, time, moderator, banner, sertificate, skp, linkmeetremove, meetid, passcode, priority, isactive, home, speakers, paticipans, desc, apply, newslug, timeend, publish, convertEndDate));
                    // Syncevent()
                    if (!type) return;
                    resetHandler();
                    history.push("/eventlist");
                } else {
                    newslug = Text
                    dispatch(createEventAction(type, title, convertStartDate, time, moderator, banner, sertificate, skp, linkmeetremove, meetid, passcode, priority, isactive, home, speakers, paticipans, desc, apply, newslug, timeend, publish, convertEndDate));
                    // Syncevent()
                    if (!type) return;
                    resetHandler();
                    history.push("/eventlist");
                }
            })
           
        }
        if(timeend < time || timeend === time){
            MySwal.fire('Oops...', 'Maaf waktu akhir time harus lebih besar dari event start', 'info')
        }
        if(banner === ""){
            setAlert(true)
        }
    };

    const handleUploadClick = (pics, index) => {
        if (pics.size < 5100000 && (pics.type === "application/vnd.ms-powerpoint" || pics.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || pics.type === "application/msword" || pics.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || pics.type === "application/pdf")) {
            document.getElementById("upload"+index).classList.remove("fa-upload");
            document.getElementById("upload" + index).classList.add("fa-spinner", "fa-spin");
            const list = [...speakers];
           if (list[index]['file'] !== undefined) {
                const deleteRequest = {
                    path: bucket+'/materi',
                    file: list[index]['file']
                }
                deletecontent(deleteRequest)
            }
            
            const fileRequest = {
                path: bucket+'/materi',
                myFile: pics
            }
            upload(fileRequest).then((res) => {
                document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
                document.getElementById("upload"+index).classList.add("fa-upload");
                const list = [...speakers];
                list[index]['file'] = res.data
                setSpeakers(list);
                document.getElementById("preview"+index).href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/materi/"+res.data

            }).catch((err) => {
                document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
                document.getElementById("upload"+index).classList.add("fa-upload");
                MySwal.fire('Oops...', 'sorry server busy please tray again' , 'info')
            })

            
        } else {
            document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
            document.getElementById("upload"+index).classList.add("fa-upload");
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    };

   const handleModelChange = (model) => {
        setDesc(model)
    }

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...speakers];
        list[index][name] = value;
        setSpeakers(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...speakers];
        list.splice(index, 1);
        setSpeakers(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setSpeakers([...speakers, { name: "", materi: "", file: "-", type:"1" }]);
    };

    const handleChangeStartdate = (date) => {
        let newdate
        let newmonth
        if(date.getDate() < 10){newdate = '0'+(date.getDate())}else{newdate = date.getDate()}
        if(date.getMonth() < 9){newmonth = '0'+(date.getMonth()+1)}else{newmonth = date.getMonth()+1}
        let convertdate =  `${date.getFullYear()}-${newmonth}-${newdate}`;
        setDate(date);
        setConvertStartDate(convertdate);
        setEnddate(date)
        setConvertEndDate(convertdate)
        
    }

    const handleChangeEnddate = (date) => {
        let newdate
        let newmonth
        if(date.getDate() < 10){newdate = '0'+(date.getDate())}else{newdate = date.getDate()}
        if(date.getMonth() < 9){newmonth = '0'+(date.getMonth()+1)}else{newmonth = date.getMonth()+1}
        let convertdate =  `${date.getFullYear()}-${newmonth}-${newdate}`;
        setConvertEndDate(convertdate);
        setEnddate(date)
    }

    const CustomInputstartdate = (props) => {
        return (
            <input
                className="form-control" 
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
            />
        )
    }

    const CustomInputenddate = (props) => {
        return (
            <input
                className="form-control" 
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
            />
        )
    }

    return (

        <div className="page-content">

            <div className="page-info">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/#">Apps</a></li>
                        <li className="breadcrumb-item active" aria-current="page">List Event</li>
                    </ol>
                </nav>

            </div>
            <div className="main-wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-transactions">
                            <div className="card-body">
                                <h5 className="card-title">Add New Event Management<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                <hr />
                                <form  onSubmit={handleSubmit(submitHandler)}>
                                    <div className="row mb-30">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Event Type <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                 <select className="form-control"                                                                     
                                                                    {...register('type', { required: true })}
                                                                    name="type" value={type} onChange={(e) => setType(e.target.value)}>
                                                                    <option disabled>Select</option>
                                                                    <option value="Seminar">Seminar</option>
                                                                    <option value="Webinar">Webinar</option>
                                                                </select>
                                                                {errors.type && <p style={style}>Event type is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Publish <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                 <select className="form-control"                                                                     
                                                                    {...register('publish', { required: true })}
                                                                    name="publish" value={publish} onChange={(e) => setPublish(e.target.value)}>
                                                                    <option disabled>Select</option>
                                                                    <option value="public">Public</option>
                                                                    <option value="private">Private</option>
                                                                </select>
                                                                {errors.publish && <p style={style}>Event publish is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Event Title <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={title}
                                                                    name="title"
                                                                    {...register('title', { required: true })}
                                                                    placeholder="Enter the Event Title"
                                                                    onChange={(e) => setTitle(e.target.value)}
                                                                />
                                                                    {errors.title && <p style={style}>Event title is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Event Start Date <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                <DatePicker
                                                                    selected={date}
                                                                    value={date}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    name="date"
                                                                    type="date"
                                                                    {...register('date', { required: false })}
                                                                    onChange={(date) => handleChangeStartdate(date)}
                                                                    selectsStart
                                                                    startDate={date}
                                                                    endDate={enddate}
                                                                    disabledKeyboardNavigation
                                                                    customInput={<CustomInputstartdate />}
                                                                />
                                                                {errors.date && <p style={style}>Event date is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Event End Date <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                <DatePicker
                                                                    selected={enddate}
                                                                    onChange={(date) => handleChangeEnddate(date)}
                                                                    selectsEnd
                                                                    dateFormat="dd/MM/yyyy"
                                                                    startDate={date}
                                                                    endDate={enddate}
                                                                    minDate={date}
                                                                    disabledKeyboardNavigation
                                                                    customInput={<CustomInputenddate />}
                                                                />

                                                                {errors.enddate && <p style={style}>Event end date is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Event Start Time <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                <input
                                                                    type="time"
                                                                    className="form-control"
                                                                    value={time}
                                                                    name="time"
                                                                    {...register('time', { required: true })}
                                                                    placeholder="Enter the Event Time"
                                                                    onChange={(e) => setTime(e.target.value)}
                                                                />
                                                                {errors.time && <p style={style}>Event time is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-4 col-form-label">Event End Time <span>*</span></label>
                                                            <div className="col-sm-8">
                                                                <input
                                                                    type="time"
                                                                    className="form-control"
                                                                    value={timeend}
                                                                    name="timeend"
                                                                    {...register('timeend', { required: true })}
                                                                    placeholder="Enter the Event Time"
                                                                    onChange={(e) => setTimeend(e.target.value)}
                                                                />
                                                                {errors.timeend && <p style={style}>Event end time is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Moderator <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={moderator}
                                                                    {...register('moderator', { required: true })}
                                                                    name="moderator"
                                                                    placeholder="Enter the Event Moderator"
                                                                    onChange={(e) => setModerator(e.target.value)}
                                                                />
                                                                {errors.moderator && <p style={style}>Event moderator is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Banner <span>*</span></label>
                                                            <div className="col-sm-5">
                                                            <span>Upload file dalam bentuk jpg/jpeg/png & Max size file 5mb</span>
                                                                <input
                                                                    className="form-control"
                                                                    onChange={(e) => postDetails(e.target.files[0])}
                                                                    id="custom-file"
                                                                    type="file"
                                                                    name="file"
                                                                    label="Upload Profile Picture"
                                                                />
                                                                <p style={alert ? {color:"red"} : { display: 'none' }}>Event banner is required.</p>
                                                            </div>
                                                            <div className="col-sm-2 py-4">
                                                                <div style={loading ? {} : { display: 'none' }} className="spinner-border text-primary col-form-label br-2" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                                <a style={{ display: 'none', width:'50px' }} href="#0" target="_blank" rel="noreferrer" id="previewbanner" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Point SKP <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={skp}
                                                                    name="skp"
                                                                    {...register('skp', { required: true })}
                                                                    placeholder="Enter the Point SKP"
                                                                    onChange={(e) => setSkp(e.target.value)}
                                                                />
                                                                {errors.skp && <p style={style}>Event skp is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">&nbsp;</label>
                                                            <div className="col-sm-10">
                                                                <div className="form-check mb-2">
                                                                    <input className="form-check-input" name="active" checked={active}
                                                                    onChange={e => setActive(e.target.checked)} type="checkbox" id="autoSizingCheck" />
                                                                    <label className="form-check-label" htmlFor="autoSizingCheck">
                                                                    Active
                                                                    </label>
                                                                </div>
                                                                <div className="form-check mb-2">
                                                                    <input className="form-check-input" name="ishome" checked={ishome}
                                                                    onChange={e => setIshome(e.target.checked)} type="checkbox" id="autoSizingCheckhome" />
                                                                    <label className="form-check-label" htmlFor="autoSizingCheckhome">
                                                                        Tampilkan Dihomepage
                                                                    </label>
                                                                </div>
                                                                <div className="form-check mb-2">
                                                                    <input className="form-check-input" name="isapply" checked={isapply}
                                                                    onChange={e => setIsapply(e.target.checked)} type="checkbox" id="autoSizingCheckapply" />
                                                                    <label className="form-check-label" htmlFor="autoSizingCheckapply">
                                                                        Show Button Apply
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Virtual Meeting Room <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={linkmeet}
                                                                    name="linkmeet"
                                                                    {...register('linkmeet', { required: true })}
                                                                    placeholder="Enter the Virtual Meeting Room"
                                                                    onChange={(e) => setLinkmeet(e.target.value)}
                                                                />
                                                                {errors.linkmeet && <p style={style}>Event linkmeet is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Meeting ID <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={meetid}
                                                                    name="meetid"
                                                                    {...register('meetid', { required: true })}
                                                                    placeholder="Enter the Meeting ID"
                                                                    onChange={(e) => setMeetid(e.target.value)}
                                                                />
                                                                {errors.meetid && <p style={style}>Event meetid is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">

                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Passcode <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={passcode}
                                                                    name="passcode"
                                                                    {...register('passcode', { required: true })}
                                                                    placeholder="Enter the Passcode"
                                                                    onChange={(e) => setPasscode(e.target.value)}
                                                                />
                                                                {errors.passcode && <p style={style}>Event passcode is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Priority <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={priority}
                                                                    name="priority"
                                                                    {...register('priority', { required: true })}
                                                                    placeholder="Enter the Priority"
                                                                    onChange={(e) => setPriority(e.target.value)}
                                                                />
                                                                {errors.priority && <p style={style}>Event priority is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Desc <span>*</span></label>
                                                            <div className="col-sm-10">
                                                                <FroalaEditorComponent tag='textarea'
                                                                    value={desc}
                                                                    name="desc"
                                                                    placeholder="Enter the desc"
                                                                    model={desc}
                                                                    {...register('desc', { required: true, value:desc })}
                                                                    onModelChange={handleModelChange}>
                                                                </FroalaEditorComponent>
                                                                {errors.desc && <p style={style}>Event desc is required.</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Speaker </label>
                                                            <div className="col-sm-12 alert alert-success">
                                                                <label htmlFor="first-name" className="col-sm-3 col-form-label">Nama Speaker </label>
                                                                <label htmlFor="first-name" className="col-sm-3 col-form-label">Materi </label>
                                                                <label htmlFor="first-name" className="col-sm-1 col-form-label">File </label>
                                                                <label htmlFor="first-name" className="col-sm-3 col-form-label">Show Type </label>

                                                                {speakers.map((x, i) => {
                                                                    return (
                                                                        <div className="box row" key={i}>
                                                                            <div className="col-sm-3">
                                                                                <input
                                                                                    name="name"
                                                                                    className="form-control"
                                                                                    placeholder="Enter First Name"
                                                                                    value={x.name}
                                                                                    onChange={e => handleInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <input
                                                                                    name="materi"
                                                                                    className="form-control"
                                                                                    placeholder="Enter Materi"
                                                                                    value={x.materi}
                                                                                    onChange={e => handleInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                            <div className="col-sm-1.5" style={{width:"90px"}}>
                                                                                {x.file !== "-" && <><a href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/materi/"+x.file} target="_blank" rel="noreferrer" id={"preview" + i} className="btn btn-primary btn-sm mb-2" ><i className="fas fa-image"></i></a>&nbsp;</>}
                                                                                    <input
                                                                                    className="btn btn-info btn-sm mb-2"
                                                                                    onChange={(e) => handleUploadClick(e.target.files[0], i)}
                                                                                    id={"custom"+i}
                                                                                    type="file"
                                                                                    name="file"
                                                                                    style={{display: "none"}}
                                                                                    label="Upload Profile Picture"
                                                                                    />
                                                                                <a href="#0"  className="btn btn-info btn-sm mb-2" onClick={(e) =>  $("#custom"+i).trigger("click")}><i id={"upload"+i} className="fas fa-upload"></i></a>&nbsp;

                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <select className="form-control" name="type" value={x.type} onChange={e => handleInputChange(e, i)}>
                                                                                    <option disabled>Select</option>
                                                                                    <option value="1">After Success Event Registration</option>
                                                                                    <option value="2">After Event Is Over</option>
                                                                                    <option value="3">After Member Get Certificate</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="btn-box col-sm-1.5">
                                                                                {speakers.length !== 1 && <span><button className="btn btn-danger btn-sm" onClick={() => handleRemoveClick(i)}><i className="fas fa-trash-alt"></i></button>&nbsp;&nbsp;</span>}
                                                                                {speakers.length - 1 === i && <button className="btn btn-warning btn-sm" onClick={handleAddClick}><i className="fas fa-plus-square"></i></button>}

                                                                            </div>

                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="profile-action-btn d-flex flex-wrap align-content-center justify-content-between">
                                                <button className="btn btn-primary" type="submit"><i className="fas fa-save"></i> Add New Event</button>
                                            </div>
                                        </div>
                                    </div>
                                    {loadingCreate && <Loading />}
                                </form>

                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}
export default Add;
