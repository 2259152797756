import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  userLoginReducer,
  userRegisterReducer,
  userUpdateReducer,
  userListReducer,
  userDeleteReducer,
  forgetPasswordReducer,
  resetPasswordReducer
} from "./reducers/userReducers";

import {
  eventListReducer,
  eventCreateReducer
} from "./reducers/eventReducers";

import {
  professionListReducer,
  professionUpdateReducer,
  professionCreateReducer,
  professionDeleteReducer

} from "./reducers/professionReducers";

import {
  productListReducer,
  productDeleteReducer,
  productCreateReducer,
  createListReducer, 
} from "./reducers/productReducers";


import {
  articleListReducer,
  listArticleReducer,
  articleDeleteReducer,
  articleCreateReducer,

} from "./reducers/articleReducers";

import {
  bannerCreateReducer,
  bannerDeleteReducer,
  bannerListReducer,
  bannerUpdateReducer,
} from "./reducers/bannerReducers";
const reducer = combineReducers({
 
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  forgetPassword: forgetPasswordReducer,
  resetPassword: resetPasswordReducer,

  eventList: eventListReducer,
  eventCreate: eventCreateReducer,

  professionList: professionListReducer,
  professionCreate: professionCreateReducer,
  professionUpdate: professionUpdateReducer,
  professionDelete: professionDeleteReducer,
  
  bannerList: bannerListReducer,
  bannerCreate: bannerCreateReducer,
  bannerDelete: bannerDeleteReducer,
  bannerUpdate: bannerUpdateReducer,
  articleList: articleListReducer,
  listArticle: listArticleReducer,
  articleDelete: articleDeleteReducer,
  articleCreate: articleCreateReducer,
  productList: productListReducer,
  productCreate: productCreateReducer,
  productDelete: productDeleteReducer,
  createList:createListReducer
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
