import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserAction, getUserbyid } from "../../actions/userActions";
import MenuMember from "../../components/MenuMember";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

function Edit({ history }) {
    const dispatch = useDispatch();
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [index, setIndex] = useState(0);
    const [title, setTitle] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");
    const [tlp, setTlp] = useState("");
    const [nip, setNip] = useState("");
    const [email, setEmail] = useState("");
    const [str, setStr] = useState("");
    const [npkidi, setNpkidi] = useState("");
    const [profile, setProfile] = useState("");
    const [nik, setNik] = useState("");
    const [tempatlahir, setTempatlahir] = useState("");
    const [tanggallahir, setTanggallahir] = useState("");
    const [profesi, setProfesi] = useState("");
    const [spesialisasi, setSpesialisasi] = useState("");
    const [consltant, setConsltant] = useState("true");
    const [address, setAddress] = useState([]);
    const [newaddress, setNewaddress] = useState([]);
    const [education, setEducation] = useState([{ mulai: "", lulus: "", institusi: "", jenjang: "" }]);
    const [jobs, setJobs] = useState([{ mulai: "", berhenti: "", institusi: "", jabatan: "" }]);
    const userUpdate = useSelector((state) => state.userUpdate);
    const userLogin = useSelector((state) => state.userLogin);
    const [verifikasi, setVerifikasi] = useState("");
    const [sertifikat, setSertifikat] = useState([]);
    const [cv, setCv] = useState("");
    const [strfile, setStrfile] = useState("");
    const [bank, setBank] = useState("");

    const [domisili, setDomisili] = useState("");
    const [alamat, setAlamat] = useState("");
    const [provinsi, setProvinsi] = useState("");
    const [kabupaten, setKabupaten] = useState("");
    const [kecamatan, setKecamatan] = useState("");
    const [kelurahan, setKelurahan] = useState("");
    const [utama, setUtama] = useState("");
    const [role, setRole] = useState("");
    const [tipe, setTipe] = useState("");


    const {
        // loading: loadingUpdate,
        // error: errorUpdate,
        success: successUpdate,
    } = userUpdate;
    const { userInfo } = userLogin;
    useEffect(() => {
        var prod = JSON.parse(localStorage.getItem('recentmember'));
        getUserbyid(prod[0]['id']).then(result =>{ 
        setId(result.data.id);
        setName(result.data.name);
        setGelardepan(result.data.gelardepan);
        setGelarbelakang(result.data.gelarbelakang);
        setTlp(result.data.tlp);
        setEmail(result.data.email);
        setNip(result.data.nip);
        setStr(result.data.str);
        setNpkidi(result.data.npkidi);
        setNik(result.data.nik);
        setTempatlahir(result.data.tempatlahir);
        setTanggallahir(result.data.tanggallahir);
        setProfesi(result.data.profesi);
        setSpesialisasi(result.data.spesialisasi);
        setConsltant(result.data.consltant);
        setVerifikasi(result.data.verifikasi);
        setCv(result.data.cv);
        setStrfile(result.data.strfile);
        setBank(result.data.bank);
        setRole(result.data.role);
        setProfile(result.data.profile);
        if(result.data.address.length !== 0){
            setAddress(result.data.address);
        }
        if(result.data.education.length !== 0){
            setEducation(result.data.education);
        }
        if(result.data.jobs.length !== 0){
            setJobs(result.data.jobs);
        }
        if(result.data.sertifikat !== null){
            setSertifikat(result.data.sertifikat);
        }
    })
        
        if (userInfo) {
            history.push("/listaddress");
          }else{
            history.push("/login");
          }
        
    }, [
        history,
        userInfo,
        successUpdate,
    ]);

    const resetHandler = () => {
        setAlamat("");
        setDomisili("");
        setProvinsi("");
        setKabupaten("");
        setKecamatan("");
        setKelurahan("");
        setUtama("");
        };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateUserAction(role, id, name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, newaddress, education, jobs, strfile, bank, cv, sertifikat, verifikasi, profile));
        if (!name) return;
        resetHandler();
        history.push("/listaddress");
    };

    const updateHandler = (e) => {
        e.preventDefault();
        const list = [...address];
        list[index]['domisili'] = domisili;
        list[index]['alamat'] = alamat;
        list[index]['provinsi'] = provinsi;
        list[index]['kabupaten'] = kabupaten;
        list[index]['kecamatan'] = kecamatan;
        list[index]['kelurahan'] = kelurahan;
        list[index]['utama'] = utama;
        list[index]['tipe'] = tipe;
        setNewaddress(list)
        dispatch(updateUserAction(role, id, name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, newaddress, education, jobs));
        if (!name) return;
        resetHandler();
        history.push("/listaddress");
    };

    const utamaHandler = (key) => {
        const list = [...address];
        for (let start = 0; start < address.length; start++) {
            list[start]['utama'] = utama;
            setNewaddress(list)
        }
        list[key]['utama'] = "true";
        setNewaddress(list)
        dispatch(updateUserAction(role, id, name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs));
        if (!name) return;
        resetHandler();
        history.push("/listaddress");
    };

    var x = 0
    // handle click event of the Add button
    const handleAddClick = () => {
        setTitle("Add New Address")
        document.getElementById('alamat').value='';
        document.getElementById('domisili').value='';
        document.getElementById('tipe').value='';
        document.getElementById('provinsi').value='';
        document.getElementById('kabupaten').value='';
        document.getElementById('kecamatan').value='';
        document.getElementById('kelurahan').value='';
        var awal = address.length
        setIndex(awal)
        setNewaddress([...address, { domisili: "", alamat: "", provinsi: "", kabupaten: "", kecamatan: "", kelurahan: "", utama: "", tipe: "" }]);
    };

    const handleEditClick = (id, domisili, alamat, provinsi, kabupaten, kecamatan, kelurahan, utama, tipe, e) => {
        setTitle("Edit Address")
        var awal = id
        setIndex(awal)
        setDomisili(domisili);
        setAlamat(alamat);
        setProvinsi(provinsi);
        setKabupaten(kabupaten);
        setKecamatan(kecamatan);
        setKelurahan(kelurahan);
        setUtama(utama);
        setTipe(tipe);
    };

    const handleTipeClick = (e) => {
        var value = e
        setTipe(e)
        const lis = [...newaddress];
        var current = 0
        console.log(value)
        if (value === "Alamat Sesuai KTP / Passport")
        {
        
            for (let start = 0; start < newaddress.length; start++) {
            if(lis[start]['tipe'] === "Alamat Sesuai KTP / Passport")
            {
                current = start
            }
            console.log(lis[start]['tipe'])
            lis[start]['tipe'] = "Alamat Domisili";
            setNewaddress(lis)
            }
            lis[index]['tipe'] = value;
            setNewaddress(lis)

        } else {
            console.log(current)
            if (current !== 0)
            {
                lis[index]['tipe'] = value;
                setNewaddress(lis)   
            } else {
                lis[index]['tipe'] = value;
                lis[current]['tipe'] = "Alamat Sesuai KTP / Passport";
                setNewaddress(lis)
                
            }
               
        }
    };

    const handleReset = () => {
        setTitle("Add New Address")
        const list = [...newaddress];
        list.splice(index, 1);
        setNewaddress(list);
        setIndex(0)
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        var check = 0
        var list
       
        if (name === "tipe" && value === "Alamat Sesuai KTP / Passport") {
             for (let index = 0; index < newaddress.length; index++) {
                if (newaddress[index]['tipe'] === "Alamat Sesuai KTP / Passport")
                {
                    check = 1
                    break;

                }   
            }
            list = [...newaddress];
            if (check === 1) {
                MySwal.fire('Mohon Maaf untuk alamat sesuai KTP hanya bisa satu')
                list[index][name] = "Alamat Domisili";
                document.getElementById('tipe').selectedIndex = -1;

            } else {
                list[index][name] = value;
            }
        } else {
            list = [...newaddress];
            list[index][name] = value;
        }
        setNewaddress(list);
    };



    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...address];
        list.splice(index, 1);
        setNewaddress(list);
        dispatch(updateUserAction(role, id, name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, list, education, jobs));
        if (!name) return;
        resetHandler();
        history.push("/listaddress");
    };



    return (

        <div className="page-content">

            <div className="page-info">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/memberlist">Member</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Address Member</li>
                    </ol>
                </nav>

            </div>
            <div className="main-wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-transactions">
                            <div className="card-body">
                                <h5 className="card-title">Daftar Address<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                <MenuMember />
                                <hr />
                                <div className="row">
                                    <div className="col-md-10">
                                        <strong>Address List</strong>
                                    </div>
                                    <div className="col-md-2 text-right">
                                        <button className="btn btn-primary " data-toggle="modal" data-target="#paycard" onClick={handleAddClick} ><i className="fas fa-plus"></i> Add</button>
                                    </div>
                                    <br></br><br></br>
                                    {address.map((y, l) => {
                                        const check = y.utama;
                                        return (
                                            <div className="col-md-6" key={l}>
                                                <div className="shadow p-3 mb-5 bg-white rounded">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <strong className="input-group mb-0">{y.alamat}</strong> 
                                                                <label className="input-group mb-0">{y.domisili}</label>
                                                                <label className="input-group mb-0">{y.kelurahan}, {y.kecamatan}</label>
                                                                <label>{y.kabupaten}, {y.provinsi}</label>
                                                            </div>
                                                                                                                       
                                                            {check ? (
                                                                <div className="row align-right text-right float-right">
                                                                    <div className="col-md-12 align-right text-right" >
                                                                        <button className="btn btn-sm btn-success " ><i className="fas fa-check-circle"></i> Utama</button>&nbsp;
                                                                        <button className="btn btn-sm btn-info " ><i className="fas fa-home"></i> {y.tipe}</button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="row">
                                                                    <div className="col-md-12" >
                                                                        <button className="btn btn-sm btn-warning mb-2 " data-toggle="modal" data-target="#edit" onClick={() => handleEditClick(l, y.domisili, y.alamat, y.provinsi, y.kabupaten, y.kecamatan, y.kelurahan, y.utama, y.tipe)} ><i className="fas fa-edit"></i> Edit</button>
                                                                        &nbsp;
                                                                        <button className="btn btn-sm btn-danger mb-2" onClick={() => handleRemoveClick(l)} ><i className="fas fa-trash"></i> Delete</button>
                                                                        &nbsp;
                                                                        <button className="btn btn-sm btn-success mb-2" onClick={() => utamaHandler(l)} ><i className="fas fa-check-circle"></i> Utama</button>&nbsp;
                                                                        <button className="btn btn-sm btn-info mb-2" ><i className="fas fa-home"></i> {y.tipe}</button>
   
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleReset()}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Nama Alamat</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="alamat"
                                                id="alamat"
                                                className="form-control"
                                                placeholder="Nama Alamat"
                                                value={x.alamat}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                 <div className="form-group">
                                    <label htmlFor="kd_promo">Tipe Alamat</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <select name="tipe" value={x.tipe} id="tipe" className="form-control" onChange={e => handleInputChange(e, index)}>
                                                <option value="Alamat Domisili">Alamat Domisili</option>
                                                <option value="Alamat Sesuai KTP / Passport">Alamat Sesuai KTP / Passport </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Domisili</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="domisili"
                                                id="domisili"
                                                className="form-control"
                                                placeholder="jl.abc"
                                                value={x.domisili}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Provinsi</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="provinsi"
                                                id="provinsi"
                                                className="form-control"
                                                placeholder="DKI Jakarta"
                                                value={x.provinsi}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kota</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kabupaten"
                                                id="kabupaten"
                                                className="form-control"
                                                placeholder="Jaksel"
                                                value={x.kabupaten}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kecamatan</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kecamatan"
                                                id="kecamatan"
                                                className="form-control"
                                                placeholder="cilandak"
                                                value={x.kecamatan}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kelurahan</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kelurahan"
                                                id="kelurahan"
                                                className="form-control"
                                                placeholder="jl.abc"
                                                value={x.kelurahan}
                                                onChange={e => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" onClick={submitHandler.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close">
                                    Submit
                                </button>
                                <br></br> 
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="edit" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Nama Alamat</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={alamat}
                                                name="alamat"
                                                onChange={(e) => setAlamat(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                 <div className="form-group">
                                    <label htmlFor="kd_promo">Tipe Alamat</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <select name="tipe" value={tipe} className="form-control" onChange={(e) => handleTipeClick(e.target.value)}>
                                                <option value="Alamat Domisili">Alamat Domisili</option>
                                                <option value="Alamat Sesuai KTP / Passport">Alamat Sesuai KTP / Passport </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Domisili</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="domisili"
                                                className="form-control"
                                                placeholder="jl.abc"
                                                value={domisili}
                                                onChange={(e) => setDomisili(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Provinsi</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="provinsi"
                                                className="form-control"
                                                placeholder="DKI Jakarta"
                                                value={provinsi}
                                                onChange={(e) => setProvinsi(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kota</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kabupaten"
                                                className="form-control"
                                                placeholder="Jaksel"
                                                value={kabupaten}
                                                onChange={(e) => setKabupaten(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kecamatan</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kecamatan"
                                                className="form-control"
                                                placeholder="cilandak"
                                                value={kecamatan}
                                                onChange={(e) => setKecamatan(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kd_promo">Kelurahan</label>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <input
                                                name="kelurahan"
                                                className="form-control"
                                                placeholder="jl.abc"
                                                value={kelurahan}
                                                onChange={(e) => setKelurahan(e.target.value)}
                                            />
                                            <input
                                                name="utama"
                                                type="hidden"
                                                className="form-control"
                                                placeholder="jl.abc"
                                                value={utama}
                                                onChange={(e) => setUtama(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" onClick={updateHandler.bind(this)} className="btn btn-success btn-block" data-dismiss="modal" aria-label="Close"
                                >
                                    Submit
                                </button>
                                <br></br>

                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </div>



    )
}
export default Edit;
