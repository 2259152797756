import React, { Component } from 'react';
import {getListoptions } from "../../actions/documentmanajemenAction";
import Moment from 'react-moment';
import 'moment/locale/id';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

export default class App extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            offset: 1,
            data: [],
            id:"",
            perPage: 25,
            title: "",
            titlemodal: "",
            author: "",
            category:[],
        };

    }

    receivedData() {
        getListoptions().then(res => {
            const data = res['data'] ? res['data']: [];
            var sortdata = data
            .filter(data => data.total !== '0')
            .sort((a, b) => parseInt(b.total) - parseInt(a.total))
            .slice(0, 10)
            let repot = sortdata
            .sort((a,b) => new Date(a.created.at) < new Date(b.created.at) ? 1 : -1)
            .slice(0, 10).map(d => ({
                "title" : d.title,
                "date" : d.created.at.split("T")[0],
                "download" : parseInt(d.viewdownload),
                "read":parseInt(d.viewread),
                "watch":parseInt(d.viewvideo),
              }))

            var postData = sortdata
                    .sort((a,b) => new Date(a.created.at) < new Date(b.created.at) ? 1 : -1)
                    .slice(0, 10)
                    .map((one, index) => (
                        <tr className="application-item" key={index}>
                            <td>{one.title}</td>
                            <td><Moment locale="id" format="D MM y">{one.created.at.split("T")[0]}</Moment></td>
                            <td>{one.viewdownload}</td>
                            <td>{one.viewread}</td>
                            <td>{one.viewvideo}</td>
                            <td>
                                <button className="btn btn-warning btn-sm " data-toggle="modal" data-target="#paycard" style={{ width: '50px' }} onClick={this.onEdit.bind(this, one.id, one.title, one.created.at.split("T")[0])}><i className="fas fa-file"></i></button>
                            </td>
                        </tr>
                    ))
                
                this.setState({
                    data: [...repot],
                    postData
                })
            
        })
    }
    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
        } else {
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }


    onEdit = (id, title, date, e) => {
        localStorage.setItem('name',title)
        localStorage.setItem('documentdate',date)
        this.props.history.push('/repotelibday/' + id)
    }

    saveAsExcel() {
        let header = ["Document Title", "Document Date","Total Download","Total Read Online","Total Watch Online"];
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = this.getSheetData(this.state.data, header);
          const totalColumns = sheetData[0].length;
          sheet1.cell("A1").value(sheetData);
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style("bold", true);
          sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
          range.style("border", true);
          return workbook.outputAsync().then((res) => {
            saveAs(res, "repotelibrarytop10.xls");
          });
        });
      }
      
      getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
      }



    render() {
        return (

            <div className="page-content">

                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Apps</a></li>
                            <li className="breadcrumb-item active" aria-current="page">List E-Library Document Metric Report</li>
                        </ol>
                    </nav>
                </div>
                <div className="main-wrapper">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">E-Library Document Metric Report<a href="/#" onClick={()=> this.saveAsExcel()} style={{color:"white"}} className="btn btn-primary card-title-helper blockui-transactions">Download As Xls</a></h5>
                                    <br />
                                    <hr />
                                    <div className="table-responsive ">
                                        <table className="table" id="tree-table">
                                            <thead>
                                                <tr>
                                                    <th>Document Title</th>
                                                    <th>Document Date</th>
                                                    <th>Total Download</th>
                                                    <th>Total Read Online</th>
                                                    <th>Total Watch Online</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.postData}
                                            </tbody>
                                        </table>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
