import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Menu() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const notlogin = (
    <></>
  );
  const loginvalid = (
    <div className="page-sidebar">
      <div className="logo-box"><img src="assets/images/logo-digihealth-new.png" alt="prosehat" width="70%" /><a href="#0" id="sidebar-close"><i className="material-icons">close</i></a> <a href="#0" id="sidebar-state"><i className="material-icons">adjust</i><i className="material-icons compact-sidebar-icon">panorama_fish_eye</i></a></div>
      <div className="page-sidebar-inner slimscroll">
        <ul className="accordion-menu">
          <li className="sidebar-title">
            Apps
          </li>
          <li>
            <Link to="/dashboard"><i className="material-icons-outlined">dashboard</i>Dashboard</Link>
          </li>
          <li>
            <Link to="/professionlist"><i className="material-icons-outlined">list</i>Profession</Link>
          </li>
          <li>
            <Link to="/memberlist"><i className="material-icons-outlined">supervised_user_circle</i>Member</Link>
          </li>
          <li>
            <Link to="/eventlist"><i className="material-icons-outlined">event</i>Event</Link>
          </li>
          <li>
            <Link to="/managejob"><i className="material-icons-outlined">work</i>Job</Link>
          </li>
          <li>
            <Link to="/partner"><i className="material-icons-outlined">supervised_user_circle</i>Partner</Link>
          </li>
          <li>
            <Link to="/page-manajemen"><i className="material-icons-outlined">pages</i>Pages Manajemen</Link>
          </li>
          <li>
            <Link to="/footer-manajemen"><i className="material-icons-outlined">navigation</i>Footer Navigation</Link>
          </li>
          <li>
             <Link to="/categorylibrary"><i className="material-icons-outlined">list</i>Category Elibrary</Link>
          </li>
          <li>
             <Link to="/document-manajemen"><i className="material-icons-outlined">book</i>Document Elibrary</Link>
          </li>
          <li>
             <Link to="/repotelib"><i className="material-icons-outlined">insert_chart</i>Report Elibrary</Link>
          </li>
          <li>
            <Link to="/listuser"><i className="material-icons-outlined">lock</i>Users</Link>
          </li>
        </ul>
      </div>
    </div>
  )

  return (
    <>
      {userInfo ? loginvalid : notlogin}
    </>

  );
}

export default Menu;
