import React, { Component  } from 'react';
import {Sendmailapprove, Sendmailreject, deleteItem, addToList, updateItem, getList, Sendmailblast, getCity, updateApprovejobs } from "../../actions/jobAction";
import { getListdashboard as jobslug } from "../../actions/jobAction";
import { Getlistnotif } from "../../actions/eventActions";
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import FormValidator from '../../components/FormValidator'
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import Select from 'react-select'
import { getUserbyuuid } from "../../actions/userActions";

// import {  Syncjob } from "../../actions/pagemanajemenAction";

var axios = require('axios');
const style = {
    color: 'red',
};
const customStyles = {
    control: (base, state) => ({
      ...base
    }),
    menu: (base) => ({
      ...base
    }),
    menuList: (base) => ({
      ...base,
      minHeight: "150px" // your desired height
    })
  };

  const AUTH_FIREBASE = process.env.REACT_APP_AUTH_FIREBASE
 

export default class App extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
                field: 'jobtitle',
                method: 'isEmpty',
                validWhen: false,
                message: 'jobtitle is required'
            },
            {
                field: 'jobtype',
                method: 'isEmpty',
                validWhen: false,
                message: 'jobtype is required'
            },
            {
                field: 'postdate',
                method: 'isEmpty',
                validWhen: false,
                message: 'postdate is required'
            },
            {
                field: 'expiredate',
                method: 'isEmpty',
                validWhen: false,
                message: 'expiredate is required'
            },
            {
                field: 'companyname',
                method: 'isEmpty',
                validWhen: false,
                message: 'companyname is required'
            },
            {
                field: 'joblocation',
                method: 'isEmpty',
                validWhen: false,
                message: 'joblocation is required'
            },
            {
                field: 'jobdescription',
                method: 'isEmpty',
                validWhen: false,
                message: 'jobdescription is required'
            },
            {
                field: 'priority',
                method: 'isEmpty',
                validWhen: false,
                message: 'priority is required'
            }
        ]);
        this.state = {
            offset: 0,
            data: [],
            perPage: 25,
            jobtitle: "",
            jobtype: "Full-Time",
            postdate: "",
            expiredate: "",
            companyname: "",
            joblocation: "",
            jobdescription: "",
            approval:"",
            oldapproval:"",
            email:"",
            tlp:"",
            name:"",
            company:"",
            position:"",
            gelardepan:"",
            gelarbelakang:"",
            priority: "",
            url: "",
            ishome: true,
            active: true,
            isapply:true,
            isPsychotest:false,
            title: "",
            psychotest:"-",
            currentPage: 0,
            searchTitle: '',
            searchActive: '',
            searchApprove: '',
            isUpdate: false,
            alert: false,
            message: "",
            errorMessage: '',
            slug: "",
            selectOptions : [],
            validation: this.validator.valid(),
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCreate = this.onCreate.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
    }
   
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value },() => {
            this.receivedData()
        });
    }
    
    handleModelChange(model) {
       this.setState({
            jobdescription: model
        }); 
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        if(name === "isPsychotest")
        {
            if(this.state.isPsychotest === false)
            {
                this.setState({
                    psychotest: "-"
                }); 
            }
        }
    }
  handleChange(e){
   this.setState({joblocation:e.value})
  }

    checkrequester(uuid) {    
        getUserbyuuid(uuid).then(result =>{
            this.setState({
                email:result.data.email,
                tlp:result.data.tlp,
                name:result.data.name,
                company:result.data.namaprusahaan,
                position:result.data.jabatan,
                gelardepan:result.data.gelardepan,
                gelarbelakang:result.data.gelarbelakang
            })
        })
    }


    onCreate(e) {
        e.preventDefault()
        this.setState({
            title: "Add New Job",
            jobtitle: "",
            jobtype: "",
            postdate: "",
            expiredate: "",
            companyname: "",
            joblocation: "",
            jobdescription: "",
            psychotest:"",
            priority: "",
            url: "",
            slug: "",
            ishome: true,
            isPsychotest:false,
            active: true,
            isUpdate: false,
            sort:'Descending'
        });
    }

    remaindernotifHandler = (e) => {
        e.preventDefault()
        $(".close").trigger("click");
        Getlistnotif().then(res => {
            var notif = res.data
            for (let index = 0; index < notif.length; index++) {
                console.log(notif[index]['endpoint'])
                var data = JSON.stringify({
                    "notification": {
                        "title": "Info Loker "+this.state.jobtitle,
                        "body": "Tipe Pekerjaan "+this.state.jobtype,
                        "icon": "https://digihealth.id/assets/images/logo-digihealth-new.png",
                        "click_action": "https://digihealth.id"
                    },
                    "data": {
                        "message": "Event",
                    },
                    "to": notif[index]['auth']
                  });
                  
                  var config = {
                    method: 'post',
                    url: 'https://fcm.googleapis.com/fcm/send',
                    headers: { 
                      'Authorization': AUTH_FIREBASE, 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then(function (response) {
                    console.log(JSON.stringify(response.data));
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
                  
                  
            }
        })
        this.setState({
            alert: true,
            message: "waiting send notif to member"
        })
        setTimeout(() => {
            this.setState({
                alert: false,
            })
        }, 8000);

    }

    blastemailHandler = (e) => {
        e.preventDefault()
        $(".close").trigger("click");
        Sendmailblast(this.state.slug, this.state.jobtitle, this.state.jobtype, this.state.companyname, this.state.joblocation, this.state.jobdescription)
        this.setState({
            alert: true,
            message: "waiting send mail to member"
        })
        setTimeout(() => {
            this.setState({
                alert: false,
            })
        }, 8000);
    };

    getlistcity() {
        getCity().then(res => {
            const data = res['rajaongkir']['results']
            const options = data.map(d => ({
                "value" : d.city_name,
                "label" : d.city_name

            }))

            this.setState({selectOptions: options})
      })
    }

    onCandidate(id, jobname, company, location, type, psychotest, e) {
        let payloadjob = [];
        payloadjob.push({ 'master': id, 'name': jobname, 'company':company, 'location':location, 'type':type, 'psychotest':psychotest });
        localStorage.setItem('payloadjob', JSON.stringify(payloadjob));
        this.props.history.push(`/jobcandidate`)
    }


    receivedData() {
                var active = (this.state.searchActive ? this.state.searchActive === "true" : null);
                getList(this.state.searchTitle, this.state.searchApprove, this.state.currentPage, active, this.state.sort).then(res => {
                    if (res.status !== 'success') {
                        localStorage.removeItem('userInfo')
                        this.props.history.push(`/login`)
                    } else {
                        const data = res['data'] ? res['data'] : [];
                        const pagination = res['pagination'] ? res['pagination']['count'] : 0

                  var postData = data
                    .map((one, index) => (
                        <tr className="application-item" key={index}>
                            <td>{one.jobtitle}</td>
                            <td>{one.jobtype}</td>
                            <td>{one.postdate}</td>
                            <td>{one.expiredate}</td>
                            <td>{one.companyname}</td>
                            <td>{one.priority}</td>
                            <td>
                                {one.ishome === true ?
                                    <div className="productText">
                                        Active
                                    </div>
                                    : null}
                                {one.ishome === false ?
                                    <div className="productText">
                                        Nonactive
                                    </div>
                                    : null}
                            </td>
                            <td>
                                {one.active === true?
                                    <div className="productText">
                                        Active
                                    </div>
                                    : null}
                                {one.active === false ?
                                    <div className="productText">
                                        Nonactive
                                    </div>
                                    : null}
                            </td>
                            <td>{one.isapprove === "Diajukan" && <>Draft</>} {one.isapprove === "Ditolak" && <>Reject</>} {one.isapprove === "Disetujui" && <>Approved</>}</td>
                            <td><button className="btn btn-danger btn-xs mb-2" onClick={this.onDelete.bind(this, one.id)} style={{ width: '50px' }}><i className="fas fa-trash"></i> </button> &nbsp;&nbsp;
                                <button className="btn btn-warning btn-xs " data-toggle="modal" data-target="#paycard" style={{ width: '50px' }} onClick={this.onEdit.bind(this, one.id, one.jobtitle, one.jobtype, one.postdate, one.expiredate, one.companyname, one.joblocation, one.jobdescription, one.priority, one.url, one.ishome, one.active, one.slug, one.psychotest, one.isapply, one.isapprove, one.created)}><i className="fas fa-edit"></i></button>&nbsp;&nbsp;
                                <button className="btn btn-info btn-xs " data-toggle="tooltip" data-placement="bottom" title="Candidate"  style={{ width: '50px' }} onClick={this.onCandidate.bind(this, one.id, one.jobtitle, one.companyname, one.joblocation, one.jobtype, one.psychotest)}><i className="fas fa-address-card"></i></button></td>

                        </tr>
                    ))

                this.setState({
                    pageCount: Math.ceil(pagination / this.state.perPage),

                    postData
                })
            }
        })
          

    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage+1,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.getlistcity()
            this.receivedData()
        }else{
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const validation = this.validator.validate(this.state);
        this.setState({ validation });
         
        if (validation.isValid) {
            var Text = this.state.jobtitle
            Text = Text.toLowerCase();
            Text = Text.replace(/[^a-zA-Z0-9]+/g, '-');
            
            getList().then(res => {
                const data = res['data'] ? res['data'] : [];
                var result = data.filter(item => (this.state.jobtitle ? item.jobtitle.toLowerCase().indexOf(this.state.jobtitle.toLowerCase()) > -1 :[]))                
                if (result.length !== 0) {
                    var random = Math.random().toString().substr(2, 5);
                    const jobRequest = {
                        jobtitle: this.state.jobtitle,
                        jobtype: this.state.jobtype,
                        postdate: this.state.postdate,
                        expiredate: this.state.expiredate,
                        companyname: this.state.companyname,
                        joblocation: this.state.joblocation,
                        jobdescription: this.state.jobdescription,
                        priority: this.state.priority,
                        url: this.state.url,
                        ishome: this.state.ishome,
                        active: this.state.active,
                        isapply: this.state.isapply,
                        slug: Text + '-' + random,
                        psychotest: this.state.psychotest
                    }
                    
                    addToList(jobRequest).then(() => {
                        // Syncjob()
                        this.receivedData()
                        $(".close").trigger("click");
                    }).catch(err => {
                        this.setState({ editDisabled: false, errorMessage: err.message })
                    })

                } else {
                    const jobRequest = {
                        jobtitle: this.state.jobtitle,
                        jobtype: this.state.jobtype,
                        postdate: this.state.postdate,
                        expiredate: this.state.expiredate,
                        companyname: this.state.companyname,
                        joblocation: this.state.joblocation,
                        jobdescription: this.state.jobdescription,
                        priority: this.state.priority,
                        url: this.state.url,
                        ishome: this.state.ishome,
                        active: this.state.active,
                        isapply: this.state.isapply,
                        slug: Text,
                        psychotest: this.state.psychotest,
                    }
                    
                    addToList(jobRequest).then(() => {
                        // Syncjob()
                        this.receivedData()
                        $(".close").trigger("click");
                    }).catch(err => {
                        this.setState({ editDisabled: false, errorMessage: err.message })
                    })

                }
            })

            
        }

    }

    onDelete = (val, e) => {
        e.preventDefault()
        deleteItem(val).then(() => {
            // this.handlePageClick(1)
            // Syncjob()
            this.receivedData()
        }).catch(err => {
            this.setState({ editDisabled: false, errorMessage: err.message })

            // this.setState({ errorMessage:err.data.message })
        })
    }

    onEdit = (item_id, jobtitle, jobtype, postdate, expiredate, companyname, joblocation, jobdescription, priority, url, ishome, active, slug, psychotest, isapply, isapprove, created, e) => {
        var isPsychotest 
        if(psychotest !== "-" && psychotest !== null && psychotest !== undefined){
            isPsychotest = true
        }else{
            isPsychotest = false
        }
        this.checkrequester(created.by)
        this.setState({
            id: item_id,
            title: "Edit Job",
            isUpdate: true,
            jobtitle: jobtitle,
            approval:isapprove,
            oldapproval:isapprove,
            jobtype: jobtype,
            postdate: postdate,
            expiredate: expiredate,
            companyname: companyname,
            joblocation: joblocation,
            jobdescription: jobdescription,
            priority: priority,
            url: url,
            ishome: ishome,
            active: active,
            slug: slug,
            isapply:isapply,
            psychotest: psychotest,
            isPsychotest: isPsychotest
        })
    }

    onUpdate = e => {
        e.preventDefault()
        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        var psychotest
        if(this.state.isPsychotest === false)
        {psychotest = "-"}else{ psychotest = this.state.psychotest }
        if (validation.isValid) {
            var Text = this.state.jobtitle
            Text = Text.toLowerCase();
            Text = Text.replace(/[^a-zA-Z0-9]+/g, '-');

            const requestapprove = {
                id:this.state.id,
                isapprove:this.state.approval
            }
            updateApprovejobs(requestapprove)
            if(this.state.approval === "Disetujui" && this.state.oldapproval !== this.state.approval)
            {
                Sendmailapprove(this.state.email, this.state.gelardepan, this.state.name, this.state.gelarbelakang, this.state.jobtype, this.state.jobtitle, this.state.jobtype, this.state.postdate, this.state.expiredate)
            }else if(this.state.approval === "Ditolak" && this.state.oldapproval !== this.state.approval){
                Sendmailreject(this.state.email, this.state.gelardepan, this.state.name, this.state.gelarbelakang, this.state.jobtype, this.state.jobtitle, this.state.jobtype, this.state.postdate, this.state.expiredate)
            }
            jobslug().then(res => {
                const data = res['data'] ? res['data'] : [];
                console.log(data);
                var result = data.filter(item => (this.state.slug ? item.slug === this.state.slug : []))
                if (result.length > 1) {
                    var random = Math.random().toString().substr(2, 5);
                    const UpdateRequest = {
                        id: this.state.id,
                        jobtitle: this.state.jobtitle,
                        jobtype: this.state.jobtype,
                        postdate: this.state.postdate,
                        expiredate: this.state.expiredate,
                        companyname: this.state.companyname,
                        joblocation: this.state.joblocation,
                        jobdescription: this.state.jobdescription,
                        priority: this.state.priority,
                        url: this.state.url,
                        ishome: this.state.ishome,
                        active: this.state.active,
                        slug: Text + '-' + random,
                        psychotest: psychotest,
                        isapply:this.state.isapply
        
                    }
                    updateItem(UpdateRequest).then(() => {
                        // Syncjob()
                        this.receivedData();
                    }).catch(err => {
                        this.setState({ isUpdate: false, errorMessage: err.message })
                    })
                } else {
                    const UpdateRequest = {
                        id: this.state.id,
                        jobtitle: this.state.jobtitle,
                        jobtype: this.state.jobtype,
                        postdate: this.state.postdate,
                        expiredate: this.state.expiredate,
                        companyname: this.state.companyname,
                        joblocation: this.state.joblocation,
                        jobdescription: this.state.jobdescription,
                        priority: this.state.priority,
                        url: this.state.url,
                        ishome: this.state.ishome,
                        active: this.state.active,
                        slug: result[0]['slug'],
                        psychotest: psychotest,
                        isapply:this.state.isapply
        
                    }
                    updateItem(UpdateRequest).then(() => {
                        // Syncjob()
                        this.receivedData();
                        $(".close").trigger("click");
                    }).catch(err => {
                        this.setState({ isUpdate: false, errorMessage: err.message })
                    })
                }
            })
           
            this.setState({ isUpdate: false })
        }
    }

    render() {
        return (

            <div className="page-content">
                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/#">Apps</a></li>
                            <li className="breadcrumb-item active" aria-current="page">List Job</li>
                        </ol>
                    </nav>
                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Job Management<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                    <br />
                                    <div style={this.state.alert ? {} : { display: 'none' }} className="alert alert-danger" id="success-alert">
                                        <div className="d-flex align-items-center">
                                            <strong><strong>Warning! </strong> {this.state.message} ...</strong>
                                            <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label"></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.searchTitle}
                                                    name="searchTitle"
                                                    placeholder="enter job title"
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                    <label htmlFor="staticEmail" className="form-label">Status</label>
                                                    <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="true">Aktif</option>
                                                        <option value="false">Tidak Aktif</option>
                                                    </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label htmlFor="staticEmail" className="form-label">Persetujuan</label>
                                                    <select className="form-control" name="searchApprove" value={this.state.searchApprove} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="Diajukan">Draft</option>
                                                        <option value="Ditolak">Reject</option>
                                                        <option value="Disetujui">Approved</option>
                                                    </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Sort By Created Date</label>
                                                    <select className="form-control" name="sort" value={this.state.sort} onChange={this.onChange}>
                                                        <option value="Descending">All</option>
                                                        <option value="ascending">Longest</option>
                                                        <option value="Descending">Latest</option>
                                                    </select>
                                                </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label py-2"> </label>
                                                <button className="btn btn-success btn-sm btn-block" data-toggle="modal" data-target="#paycard" onClick={this.onCreate.bind(this)}><i className="fas fa-plus"></i> Add</button>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    <div className="table-responsive">
                                        <table className="table" id="tree-table">
                                            <thead>
                                                <tr>
                                                    <th>Job Title</th>
                                                    <th>Job Type</th>
                                                    <th>Post Date</th>
                                                    <th>Expired Date</th>
                                                    <th>Company Name</th>
                                                    <th>Priority</th>
                                                    <th>Show in Homepage</th>
                                                    <th>Status</th>
                                                    <th>Approve</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.postData}
                                            </tbody>
                                        </table>
                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-left"></i>}
                                            nextLabel={<i className="fa fa-angle-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-end asrt-pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#home" role="tab" aria-controls="home">Detail Job</a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#profile" role="tab" aria-controls="profile">Requester</a>
                                    </li>
                                </ul>
                                <br/><br/>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="home" role="tabpanel">
                                    <form>
                                    <div className="form-group row" style={this.state.isUpdate ? {} : { display: 'none' }}>
                                        <label htmlFor="title" className="col-sm-2 col-form-label">Approval  <span>*</span></label>
                                        <div className="col-md-10">
                                            <select className="form-control" name="approval" id="approval" value={this.state.approval} onChange={this.onChange}>
                                                    <option disabled value="Diajukan">Draft</option>
                                                    <option value="Ditolak">Reject</option>
                                                    <option value="Disetujui">Approved</option>
                                            </select>
                                            <span className="help-block" style={style}>{this.state.validation.jobtype.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Job Title  <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.jobtitle}
                                                name="jobtitle"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style} >{this.state.validation.jobtitle.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="title" className="col-sm-2 col-form-label">Job Type  <span>*</span></label>
                                        <div className="col-md-10">
                                            <select className="form-control" name="jobtype" id="jobtype" value={this.state.jobtype} onChange={this.onChange}>
                                                <option value="">Select</option>
                                                <option value="Full-Time">Full-Time</option>
                                                <option value="Part-Time">Part-Time</option>
                                            </select>
                                            <span className="help-block" style={style}>{this.state.validation.jobtype.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Post Date  <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={this.state.postdate}
                                                name="postdate"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style}>{this.state.validation.postdate.message}</span>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Expired Date  <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={this.state.expiredate}
                                                name="expiredate"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style}>{this.state.validation.expiredate.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Company Name  <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.companyname}
                                                name="companyname"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style}>{this.state.validation.companyname.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">&nbsp;</label>
                                        <div className="col-md-10">
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" name="active" checked={this.state.active} onChange={this.handleInputChange} type="checkbox" id="autoSizingCheck" />
                                                <label className="form-check-label" htmlFor="autoSizingCheck">
                                                    Active
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">&nbsp;</label>
                                        <div className="col-md-10">
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" name="ishome" checked={this.state.ishome} onChange={this.handleInputChange} type="checkbox" id="autoSizingCheck1" />
                                                <label className="form-check-label" htmlFor="autoSizingCheck1">
                                                    Tampilkan di Homepage ?
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">&nbsp;</label>
                                        <div className="col-md-10">
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" name="isapply" checked={this.state.isapply} onChange={this.handleInputChange} type="checkbox" id="autoSizingCheck2" />
                                                <label className="form-check-label" htmlFor="autoSizingCheck2">
                                                     Show Button Apply ?
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Job Description  <span>*</span></label>
                                        <div className="col-md-10">
                                             <FroalaEditorComponent tag='textarea'
                                                value={this.state.jobdescription}
                                                name="desc"
                                                placeholder="Enter the desc"
                                                model={this.state.jobdescription}
                                                onModelChange={this.handleModelChange}>
                                             </FroalaEditorComponent>
                                            <span className="help-block" style={style}>{this.state.validation.jobdescription.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Job Location  <span>*</span></label>
                                        <div className="col-md-10">
                                            <Select styles={customStyles} maxMenuHeight={10} options={this.state.selectOptions} placeholder={<div>{this.state.joblocation}</div>}   value={this.state.joblocation} onChange={this.handleChange.bind(this)}/>
                                            <span className="help-block" style={style}>{this.state.validation.joblocation.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Priority  <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={this.state.priority}
                                                name="priority"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style}>{this.state.validation.priority.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Redirect URL </label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.url}
                                                name="url"
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">&nbsp;</label>
                                        <div className="col-md-10">
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" name="isPsychotest" checked={this.state.isPsychotest} onChange={this.handleInputChange} type="checkbox" id="autoSizingCheck2" />
                                                <label className="form-check-label" htmlFor="autoSizingCheck2">
                                                Need a psychotest ?
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row" style={this.state.isPsychotest ? {} : { display: 'none' }}>
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Psychotest URL </label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.psychotest}
                                                name="psychotest"
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <br />
                                        <button className="btn btn-primary" onClick={this.onUpdate.bind(this)}
                                            style={this.state.isUpdate ? {} : { display: 'none' }} data-dismiss="modal" aria-label="Close"
                                        >
                                            Update
                                        </button>
                                        &nbsp;
                                        <button className="btn btn-danger" onClick={this.remaindernotifHandler.bind(this)}
                                            style={this.state.isUpdate ? {} : { display: 'none' }}
                                        >
                                            Blast Notif
                                        </button>

                                        <button className="btn btn-warning" onClick={this.blastemailHandler.bind(this)}
                                            style={this.state.isUpdate ? {} : { display: 'none' }}
                                        >
                                            Blast Email
                                        </button>

                                        <button type="submit"  onClick={this.onSubmit.bind(this)} className="btn btn-success btn-block"
                                            style={this.state.isUpdate ? { display: 'none' } : {}} data-dismiss="modal" aria-label="Close"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                    <br /><br />
                                </form>
                                    </div>
                                    <div className="tab-pane" id="profile" role="tabpanel">
                                        <form>
                                            <div className="row mb-30">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <div className="single-input mb-25">
                                                                <div className="form-group row">
                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Email</label>
                                                                    <div className="col-sm-10">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.email}
                                                                            readOnly
                                                                            placeholder="Enter the Event Title"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <div className="single-input mb-25">
                                                                <div className="form-group row">
                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Phone Number</label>
                                                                    <div className="col-sm-10">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.tlp}
                                                                            readOnly
                                                                            placeholder="Enter the Event Title"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <div className="single-input mb-25">
                                                                <div className="form-group row">
                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Full Name</label>
                                                                    <div className="col-sm-10">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.name}
                                                                            readOnly
                                                                            placeholder="Enter the Event Title"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <div className="single-input mb-25">
                                                                <div className="form-group row">
                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Company Name</label>
                                                                    <div className="col-sm-10">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.company}
                                                                            readOnly
                                                                            placeholder="Enter the Event Title"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <div className="single-input mb-25">
                                                                <div className="form-group row">
                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Position Name</label>
                                                                    <div className="col-sm-10">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.position}
                                                                            readOnly
                                                                            placeholder="Enter the Event Title"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <div className="single-input mb-25">
                                                                <div className="form-group row">
                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Front Title</label>
                                                                    <div className="col-sm-10">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.gelardepan}
                                                                            readOnly
                                                                            placeholder="Enter the Event Title"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <div className="single-input mb-25">
                                                                <div className="form-group row">
                                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Back Degree</label>
                                                                    <div className="col-sm-10">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={this.state.gelarbelakang}
                                                                            readOnly
                                                                            placeholder="Enter the Event Title"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                

                            </div>

                        </div>
                    </div>
                </div>


            </div>

        )
    }
}
