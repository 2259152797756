import React, { Component } from 'react';
import { getListcandidate, updateStatusCandiate, SendmailLamaranditinjau, SendmailDiundangwawancara, SendmailLamaranditolak, SendmailLamaranditerima } from "../../actions/jobAction";
import { getUserbyemail } from "../../actions/userActions";
import ReactPaginate from 'react-paginate';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            user: [],
            bulk: [],
            bulkstatus: "",
            changeall: false,
            perPage: 10,
            searchTitle: "",
            searchTipe: "",
            title: "",
            id: "0"
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onChangeAll = this.onChangeAll.bind(this)
        this.onChangestatus = this.onChangestatus.bind(this)

    }

    onChangestatus(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeFilter(e) {
        this.setState({ [e.target.name]: e.target.value })
        this.receivedData(this.state.id)
    }

    onChange(e) {
        const UpdateRequest = {
            id: e.target.name,
            status: e.target.value
        }
        updateStatusCandiate(UpdateRequest)
        this.onSendmail(e.target.name, e.target.value)
        
    }

handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const found = this.state.bulk.some(el => el === name);
    console.log(value)
    if (!found) {
        // add new 
        this.state.bulk.push(name)
    } else {
        var master = [...this.state.bulk];
        var index = this.state.bulk.indexOf(name)
        master.splice(index, 1);
        this.setState({ "bulk": master });
    }
}

onBulk() {

    for (let index = 0; index < this.state.bulk.length; index++) {
        const UpdateRequest = {
            id: this.state.bulk[index],
            status: this.state.bulkstatus
        }
        updateStatusCandiate(UpdateRequest)
        this.onSendmail(this.state.bulk[index], this.state.bulkstatus)
    }
    this.setState({
        alert: true,
        message: "waiting send mail to candidate"
    })
    setTimeout(() => {
        this.setState({
            alert: false,
        })
        this.receivedData(this.state.id)
    }, 8000);

}

onSendmail(id, status)
{
    var mastercandidat = this.state.data.filter(item => (id ? item.id === id: []))
    getUserbyemail(mastercandidat[0]['email']).then(res => {
        let filter = res['data'];
        if (status === "Lamaran Ditinjau") {
            SendmailLamaranditinjau(filter['gelardepan'], filter['gelarbelakang'], filter['name'], filter['email'])
        } else if (status === "Diundang Wawancara") {
            SendmailDiundangwawancara(filter['gelardepan'], filter['gelarbelakang'], filter['name'], filter['email'])
        } else if (status === "Lamaran Ditolak") {
            SendmailLamaranditolak(filter['gelardepan'], filter['gelarbelakang'], filter['name'], filter['email'])
        } else if (status === "Lamaran Diterima") {
            SendmailLamaranditerima(filter['gelardepan'], filter['gelarbelakang'], filter['name'], filter['email'])
        }
    })
}

onChangeAll(event) {
    const target = event.target;
    const nilai = target.type === 'checkbox' ? target.checked : target.nilai;
    // this.setState({ "changeall": nilai })
    var cbarray = document.querySelectorAll('input[id="bulk"]');
    for (var i = 0; i < cbarray.length; i++) {
        cbarray[i].checked = nilai
    }
    if (nilai === true) {
        for (let index = 0; index < this.state.data.length; index++) {
            const found = this.state.bulk.some(el => el === this.state.data[index]['id']);
            // console.log(found)
            if (!found) {
                // add new 
                this.state.bulk.push(this.state.data[index]['id'])
            } else {
                var master = [...this.state.bulk];
                var check = this.state.bulk.indexOf(this.state.data[index]['id'])
                master.splice(check, 1);
                this.setState({ "bulk": master });
            }
        }
    } else {
        this.setState({ "bulk": [] });

    }

}

onDetail(email, e) {
    getUserbyemail(email).then(res => {
        let filter = res['data'];
        let recent = [];
        recent.push({
            'id': filter['id']
        });
        localStorage.setItem('recentmember', JSON.stringify(recent));
    })
    this.props.history.push(`/memberdetailprofile`)

}



receivedData(id) {
    getListcandidate(id).then(res => {
        if (res.status !== 'success') {
            localStorage.removeItem('userInfo')
            this.props.history.push(`/login`)
        } else {
            const data = res['data'] ? res['data'] : [];
            var result = data.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 || item.email.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name)
                && (this.state.searchTipe ? item.status === this.state.searchTipe : item.status))
            var slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
            var filterData = slice.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 || item.email.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name)
                && (this.state.searchTipe ? item.status === this.state.searchTipe : item.status))
            const postData = filterData
                .map((one, index) => (
                    <tr className="application-item" key={index}>
                        <td><input name={one.id} type="checkbox" onChange={this.handleInputChange} id="bulk" /></td>
                        <td>{one.applydate}</td>
                        <td><a href="memberdetailprofile#" onClick={this.onDetail.bind(this, one.email)}>{one.email}</a></td>
                        <td><a href="memberdetailprofile#" onClick={this.onDetail.bind(this, one.email)}>{one.name}</a></td>
                        <td>{one.gelardepan}</td>
                        <td>{one.gelarbelakang}</td>
                        <td>{one.phone}</td>
                        <td>
                            <select className="form-control" name={one.id} onChange={this.onChange}>
                                <option value="Lamaran Ditinjau" selected={one.status === "Lamaran Ditinjau"}>Lamaran Ditinjau</option>
                                <option value="Diundang Wawancara" selected={one.status === "Diundang Wawancara"}>Diundang Wawancara</option>
                                <option value="Lamaran Diterima" selected={one.status === "Lamaran Diterima"}>Lamaran Diterima</option>
                                <option value="Lamaran Ditolak" selected={one.status === "Lamaran Ditolak"}>Lamaran Ditolak</option>
                            </select>
                        </td>
                    </tr>
                ))
            this.setState({
                pageCount: Math.ceil(result.length / this.state.perPage),
                data: filterData,
                postData
            })
        }
    })
    

}
handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.receivedData()
    });

};

componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
        var jobid = JSON.parse(localStorage.getItem('payloadjob'));
        var id = jobid[0]['master']
        this.receivedData(id)
        this.setState({ "title": jobid[0]['name'], "id": id })

    } else {
        localStorage.removeItem('userInfo')
        this.props.history.push("/login");
    }
}



render() {
    return (
        <div className="page-content">
            <div className="page-info">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Apps</a></li>
                        <li className="breadcrumb-item"><a href="/managejob">Job</a></li>
                        <li className="breadcrumb-item active" aria-current="page">List Candidate</li>
                    </ol>
                </nav>
            </div>
            <div className="main-wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-transactions">
                            <div className="card-body">
                                <h5 className="card-title">Candidate Of Job {this.state.title} </h5>
                                <br />
                                
                                <div className="row pull-right d-flex justify-content-end">
                                    <div className="col-md-3">
                                        <div className="form-group row">
                                            <label htmlFor="staticEmail" className="col-form-label">Show</label>
                                            <div className="col-sm-5">
                                                <select className="form-control" name="perPage" value={this.state.perPage} onChange={this.onChangeFilter}>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                            <label htmlFor="staticEmail" className="col-form-label">records per page</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 ">
                                        <div className="form-group row">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.searchTitle}
                                                name="searchTitle"
                                                placeholder="enter candidate by name & email"
                                                onChange={this.onChangeFilter}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group row">
                                            <div className="col-sm-9">
                                                <select className="form-control" name="searchTipe" value={this.state.searchTipe} onChange={this.onChangeFilter}>
                                                    <option value="">All</option>
                                                    <option value="Lamaran Ditinjau">Lamaran Ditinjau</option>
                                                    <option value="Diundang Wawancara">Diundang Wawancara</option>
                                                    <option value="Lamaran Diterima">Lamaran Diterima</option>
                                                    <option value="Lamaran Ditolak">Lamaran Ditolak</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                                <div className="row pull-right d-flex justify-content-start">
                                    <div className="col-md-5">
                                        <div className="form-group row">
                                            <div className="col-sm-6">
                                                <select className="form-control" name="bulkstatus" value={this.state.bulkstatus} onChange={this.onChangestatus}>
                                                    <option value="Lamaran Ditinjau">Lamaran Ditinjau</option>
                                                    <option value="Diundang Wawancara">Diundang Wawancara</option>
                                                    <option value="Lamaran Diterima">Lamaran Diterima</option>
                                                    <option value="Lamaran Ditolak">Lamaran Ditolak</option>
                                                </select>
                                            </div>
                                            <button className="btn btn-primary" data-dismiss="modal" aria-label="Close" onClick={this.onBulk.bind(this)}><i className="fas fa-file"></i> Change Status</button>
                                        </div>
                                    </div>
                                </div>
                                <div style={this.state.alert ? {} : { display: 'none' }} className="alert alert-danger" id="success-alert">
                                    <div className="d-flex align-items-center">
                                        <strong><strong>Warning! </strong> {this.state.message} ...</strong>
                                        <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </div>
                                <hr />
                                <div className="table-responsive container">
                                    <table className="table" id="tree-table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input name="masterbulk" type="checkbox" onChange={this.onChangeAll} id="mastercheck" />
                                                </th>
                                                <th>Apply Date </th>
                                                <th>Email</th>
                                                <th>Name</th>
                                                <th>Front Title</th>
                                                <th>Back Title</th>
                                                <th>Phone Number</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.postData}
                                        </tbody>
                                    </table>
                                    <br />
                                    <ReactPaginate
                                        previousLabel={<i className="fa fa-angle-left"></i>}
                                        nextLabel={<i className="fa fa-angle-right"></i>}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={1}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination justify-content-end asrt-pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"} />
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}
}
