import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { deleteUserAction, listUsers, updateUserAction, bulkuploaduser } from "../../actions/userActions";
import ErrorMessage from "../../components/ErrorMessage";
import ReactDatatable from '@ashvin27/react-datatable';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

function ListUser({ history }) {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [gelardepan, setGelardepan] = useState("");
  const [gelarbelakang, setGelarbelakang] = useState("");
  const [tlp, setTlp] = useState("");
  const [nip, setNip] = useState("");
  const [email, setEmail] = useState("");
  const [str, setStr] = useState("");
  const [verifikasi, setVerifikasi] = useState("");
  const [npkidi, setNpkidi] = useState("");
  const [nik, setNik] = useState("");
  const [tempatlahir, setTempatlahir] = useState("");
  const [tanggallahir, setTanggallahir] = useState("");
  const [profesi, setProfesi] = useState("");
  const [spesialisasi, setSpesialisasi] = useState("");
  const [consltant, setConsltant] = useState("");
  const [role, setRole] = useState("");
  const [address, setAddress] = useState([]);
  const [education, setEducation] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [excel, setExcel] = useState([]);
  const [alert, setAlert] = useState(false);
  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;
  const userLogin = useSelector((state) => state.userLogin);
  const userDelete = useSelector((state) => state.userDelete);
  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    success: successUpdate,
  } = userUpdate;
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = userDelete;

  const { userInfo } = userLogin;
  useEffect(() => {
    dispatch(listUsers());
    if (userInfo) {
      history.push("/listuser");
    } else {
      history.push("/login");
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successUpdate
  ]);

  const editHandler = (role, id, name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, e) => {
    setId(id);
    setName(name);
    setGelardepan(gelardepan);
    setGelarbelakang(gelarbelakang);
    setTlp(tlp);
    setEmail(email);
    setNip(nip);
    setStr(str);
    setNpkidi(npkidi);
    setNik(nik);
    setTempatlahir(tempatlahir);
    setTanggallahir(tanggallahir);
    setProfesi(profesi);
    setSpesialisasi(spesialisasi);
    setConsltant(consltant);
    setVerifikasi(verifikasi)
    setRole(role)
    if (address.length !== 0) {
      setAddress(address);
    }
    if (education.length !== 0) {
      setEducation(education);
    }
    if (jobs.length !== 0) {
      setJobs(jobs);
    }

  }
  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteUserAction(id));
    }
  };

  const resetHandler = () => {
    setId("");
    setName("");
    setGelardepan("");
    setGelarbelakang("");
    setTlp("");
    setEmail("");
    setNip("");
    setStr("");
    setNpkidi("");
    setNik("");
    setTempatlahir("");
    setTanggallahir("");
    setProfesi("");
    setSpesialisasi("");
    setConsltant("");
    setVerifikasi("")
    setRole("")
    setAddress([]);
    setEducation([]);
    setJobs([]);
  };

  const updateHandler = (e) => {
    e.preventDefault();
    dispatch(updateUserAction(role, id, name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs));
    resetHandler();
  };

  const columns = [
    {
      key: "name",
      text: "Name",
      sortable: true
    },
    {
      key: "email",
      text: "Email",
      sortable: true
    },
    {
      key: "role",
      text: "Role",
      sortable: true
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 150,
      align: "left",
      sortable: false,
      cell: record => {
        return (
          <Fragment>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => deleteHandler(record.email)}>
              <i className="fa fa-trash"></i>
            </button>
            <button
              className="btn btn-warning btn-sm" data-toggle="modal" data-target="#usermodal"
              onClick={() => editHandler(record.role, record.id, record.name, record.email, record.gelardepan, record.gelarbelakang, record.tlp, record.nip, record.str, record.npkidi, record.nik, record.tempatlahir, record.tanggallahir, record.profesi, record.spesialisasi, record.consltant, record.address, record.education, record.jobs)}>
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      }
    }

  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: 'advance',
    button: {
      excel: false,
      print: false
    }
  }

 const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      /* Parse data */
      const ab = e.target.result;
      const wb = XLSX.read(ab, { type: 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const dataxl = XLSX.utils.sheet_to_json(ws, { header: 2 });
      /* Update state */
      console.log(dataxl)
      setExcel(dataxl)

    };
    reader.readAsArrayBuffer(file);
  }

 const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) handleFile(files[0]);
  };

  const bulkupload = () => {
    if (excel.length < 501 && excel.length !== 0) {
      setAlert(true)
      excel.map((value, key) => {
        var result = users.filter(item => (item.email === value.email))
        if (result.length === 0) {
          
          setTimeout( dispatch(bulkuploaduser(value.name, value.email, "1q2w3e4r5t", value.tlp)), 10000);
        }
        return value
      })
      var time = excel.length*1000

      setTimeout(() => {
        setAlert(false)
        dispatch(listUsers());
      }, time)

    } else {
      MySwal.fire('Oops...', 'Mohon maaf sepertinya jumlah recode melebihi batas maximum 500 recod', 'info')
    }

  }




  return (
    <div className="page-content">

      <div className="page-info">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/#">Apps</a></li>
            <li className="breadcrumb-item active" aria-current="page">List User</li>
          </ol>
        </nav>

      </div>
      <div className="main-wrapper">
        <div className="row">
          <div className="col-lg-12">
            {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
            {errorDelete && (
              <ErrorMessage variant="danger">{errorDelete}</ErrorMessage>
            )}

          </div>
          <div className="col-lg-12">
            <div className="card card-transactions">
              <div className="card-body">
                <h5 className="card-title">List User<button className="btn btn-primary card-title-helper blockui-transactions" data-toggle="modal" data-target="#blukupload" type="submit" style={{ color: "white" }}><i className="fas fa-upload"></i> Bulk Upload</button></h5>
                <br />
                 <div style={alert ? {} : { display: 'none' }} className="alert alert-danger" id="success-alert">
                      <div className="d-flex align-items-center">
                          <strong><strong>Warning! </strong>Mohon menunggu proses ...</strong>
                          <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                      </div>
                  </div>
                {loading && <Loading />}
                {loadingDelete && <Loading />}
                {loadingUpdate && <Loading />}
                {/* <button id="addRow" className="btn btn-primary m-b-md" data-toggle="modal" data-target="#usermodal" >Add User</button> */}
                <ReactDatatable
                  config={config}
                  records={users}
                  columns={columns} />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="blukupload" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Bulk Upload</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="kd_promo">File Xlsx, Klik link berikut untuk download contoh file max 500 recod <a href="/assets/file/contohbulkuploaduser.xlsx">link</a></label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="file"
                        className="form-control"
                        id="file"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary btn-block" data-dismiss="modal" aria-label="Close" onClick={() => bulkupload()}>
                  Update
                </button>
                <br></br>
              </form>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="usermodal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add User</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form >
                <div className="form-group">
                  <label htmlFor="title">Name</label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        name="title"
                        disabled
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="kd_promo">Email</label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        name="email"
                        disabled
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="kd_promo">Level</label>
                  <div className="row">
                    <div className="col-md-12">
                      <select className="form-control"
                        value={role}
                        name="role"
                        onChange={(e) => setRole(e.target.value)}>
                        <option value="ADMIN">ADMIN</option>
                        <option value="USER">USER</option>
                        <option value="Perorangan">Perorangan</option>
                        <option value="Bisnis">Bisnis</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary btn-block" onClick={updateHandler.bind(this)} data-dismiss="modal" aria-label="Close" >
                  Save
                </button>
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>


    </div>



  );
}

export default ListUser;
