import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import MenuMember from "../../components/MenuMember";
import { getListbyuser } from "../../actions/eventActions";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 25,
            currentPage: 0,
            searchTitle: '',
            searchTipe: '',
            searchActive: '',

        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)

    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onDetail(id, e) {
        let recent = [];
        recent.push({ 'id': id });
        localStorage.setItem('recent', JSON.stringify(recent));
        this.props.history.push(`/eventdeltail`)

    }


    receivedData() {
        var member = JSON.parse(localStorage.getItem('recentmember'));
        var id = member[0]['id'];
        getListbyuser(id).then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {
                if (res['data'] != null) {
                    let newDate = new Date()
                    let date = newDate.getDate();
                    let month = newDate.getMonth() + 1;
                    let year = newDate.getFullYear();
                    let separator = "-"
                    let datenow = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
                    var datecheck = this.state.searchActive
                    var filterData
                    var result
                    if (datecheck === "today") {
                        const data = res['data'] ? res['data'] : [];
                        result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date === datenow))
                        const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date === datenow))
                    } else if (datecheck === "yesterday") {
                        const data = res['data'] ? res['data'] : [];
                        result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date < datenow))
                        const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date < datenow))

                    } else if (datecheck === "tomorrow") {
                        const data = res['data'] ? res['data'] : [];
                        result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date > datenow))
                        const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type)
                            && (item.date > datenow))

                    } else {
                        const data = res['data'] ? res['data'] : [];
                        result = data.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type))
                        const slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.title.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.title)
                            && (this.state.searchTipe ? item.type === this.state.searchTipe : item.type))
                    }
                    var member = JSON.parse(localStorage.getItem('recentmember'));
                    var idmember = member[0]['id'];
                    const postData = filterData
                        .map((one, index) => (
                            <tr className="application-item" key={index}>
                                <td>
                                    {one.paticipans.map((value) =>
                                        <div>
                                            {value.idmember === idmember ?
                                                <div className="productText">
                                                    {value.nomorpendaftaran}
                                                </div>
                                                : null}
                                            </div>
                                    )}
                                </td>
                                <td>{one.type}</td>
                                <td>{one.title}</td>
                                <td>{one.date}, {one.time}</td>
                                <td>{one.moderator}</td>
                                <td>
                                    {one.speakers.map((value) =>
                                        <div>{value.name}<br /></div>
                                    )}
                                </td>
                                <td>{one.speakers.length}</td>
                                <td><button onClick={this.onDetail.bind(this, one.id)} className="btn btn-warning btn-sm btn-block" ><i className="fas fa-file-contract"></i></button></td>
                            </tr>
                        ))

                    this.setState({
                        pageCount: Math.ceil(result.length / this.state.perPage),

                        postData
                    })
                } else {
                    this.setState({
                        pageCount: 0,


                    })
                }
            }
        })
    }
    Filter = (e) => {

        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
        }else{
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }
    render() {
        return (
            <div className="page-content">

                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                             <li className="breadcrumb-item"><a href="/memberlist">Member</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Aktivity Member</li>
                        </ol>
                    </nav>
                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Daftar Activity<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                    <MenuMember />
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group row">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.searchTitle}
                                                    name="searchTitle"
                                                    placeholder="enter name event"
                                                    onChange={this.onChange}
                                                />
                                            </div></div>
                                        <div className="col-md-4">
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Status</label>
                                                <div className="col-sm-9">
                                                    <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="today">Sedang Berlangsung</option>
                                                        <option value="tomorrow">Akan Datang</option>
                                                        <option value="yesterday">Sudah Selesai</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Tipe</label>
                                                <div className="col-sm-10">
                                                    <select className="form-control" name="searchTipe" value={this.state.searchTipe} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="Seminar">Seminar</option>
                                                        <option value="Webinar">Webinar</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-1">
                                            <div className="form-group row">
                                                <button className="btn btn-primary btn-sm btn-block" data-dismiss="modal" aria-label="Close" onClick={this.Filter.bind(this)}><i className="fas fa-search"></i> Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Nomor Pendafataran</th>
                                                <th>Type Event</th>
                                                <th>Nama Event</th>
                                                <th>Waktu Event</th>
                                                <th>Moderator Event</th>
                                                <th>Speaker</th>
                                                <th>Materi</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.postData}
                                        </tbody>
                                    </table>
                                    <ReactPaginate
                                        previousLabel={<i className="fa fa-angle-left"></i>}
                                        nextLabel={<i className="fa fa-angle-right"></i>}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={1}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination justify-content-end asrt-pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"} />


                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        )
    }
}
