import React, { Component } from 'react';
import { getList, addToList, updateItem, deleteItem } from "../../actions/partnerAction";
import { upload, deletecontent } from "../../actions/userActions";
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import FormValidator from '../../components/FormValidator'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const bucket = process.env.REACT_APP_API_KEY
const MySwal = withReactContent(Swal)

const style = {
    color: 'red',
};

export default class App extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
                field: 'cmstitle',
                method: 'isEmpty',
                validWhen: false,
                message: 'title is required'
            },
            {
                field: 'priority',
                method: 'isEmpty',
                validWhen: false,
                message: 'priority is required'
            }
        ]);
        this.state = {
            offset: 0,
            data: [],
            perPage: 25,
            title: "",
            cmstitle: "",
            description: "",
            priority: "",
            active: true,
            currentPage: 0,
            searchTitle: '',
            searchActive: '',
            isUpdate: false,
            alert: false,
            message: "",
            logo:"",
            loading:false,
            errorMessage: '',
            slug: "",
            selectOptions: [],
            validation: this.validator.valid(),
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCreate = this.onCreate.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleModelChange(model) {
        this.setState({
            description: model
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    onCreate(e) {
        e.preventDefault()
        this.setState({
            title: "Add New Partner",
            cmstitle: "",
            description: "",
            url: "",
            active: true,
            isUpdate: false
        });
    }

    receivedData() {
        getList().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {
                const data = res['data'] ? res['data'] : [];
                var slice
                var filterData
 
                slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                filterData = slice

                var postData = filterData
                    .sort((a, b) => a.priority > b.priority ? 1 : -1)
                    .map((one, index) => (
                        <tr className="application-item" key={index}>
                            <td>{one.title}</td>
                            <td>
                                <img src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/partner/"+one.logo} width="30" alt="logo" />
                            </td>                            
                            <td>{one.priority}</td>
                            <td><button className="btn btn-danger btn-sm " onClick={this.onDelete.bind(this, one.id)} style={{ width: '50px' }}><i className="fas fa-trash"></i> </button>
                                <button className="btn btn-warning btn-sm " data-toggle="modal" data-target="#paycard" style={{ width: '50px' }} onClick={this.onEdit.bind(this, one.id, one.title, one.logo, one.priority)}><i className="fas fa-edit"></i></button></td>
                        </tr>
                    ))

                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    data: [...data],
                    postData
                })
            }
        })

    }
    Filter = (e) => {

        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
        } else {
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        if (validation.isValid) {
            const cmsRequest = {
                title: this.state.cmstitle,
                logo: this.state.logo,
                priority: this.state.priority,
            }
            addToList(cmsRequest).then(() => {
                this.receivedData()
                $(".close").trigger("click");
            }).catch(err => {
                this.setState({ editDisabled: false, errorMessage: err.message })
            })
        }
    }

    onDelete = (val, e) => {
        e.preventDefault()
        deleteItem(val).then(() => {
            this.receivedData()
        }).catch(err => {
            this.setState({ editDisabled: false, errorMessage: err.message })
        })
    }

    onEdit = (item_id, title, logo, priority, e) => {
        this.setState({
            id: item_id,
            title: "Edit Partner",
            cmstitle: title,
            isUpdate: true,
            logo: logo,
            priority: priority
        })
        document.getElementById("previewbanner").style.display = 'block';
        document.getElementById("previewbanner").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/partner/"+logo  
    }

    onUpdate = e => {
        e.preventDefault()
        const validation = this.validator.validate(this.state);
        this.setState({ validation });

        if (validation.isValid) {
           
            const UpdateRequest = {
                id: this.state.id,
                title: this.state.cmstitle,
                logo: this.state.logo,
                priority: this.state.priority
            }
            updateItem(UpdateRequest).then(() => {
                // Syncgeneral()
                this.receivedData();
                $(".close").trigger("click");
            }).catch(err => {
                this.setState({ isUpdate: false, errorMessage: err.message })
            })
            

            this.setState({ isUpdate: false })
        }
    }

    // upload image 
    postDetails = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png")) {
            document.getElementById("previewbanner").style.display = 'none';
            this.setState({loading:true})
            if (this.state.logo !== "" || this.state.logo !== null ) {
                const deleteRequest = {
                    path: bucket+'/partner',
                    file: this.state.logo
                }
                deletecontent(deleteRequest)
            }

            const Request = {
                path: bucket+'/partner',
                myFile: pics
            }

            upload(Request).then((res) => {
                this.setState({
                    logo:res.data,
                    loading:false,
                    alert:false
                })
                document.getElementById("previewbanner").style.display = 'block';
                document.getElementById("previewbanner").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/partner/"+res.data  
            }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })

        } else {
            document.getElementById("previewbanner").style.display = 'none';
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type file yang anda masukan tidak cocok', 'info')
        }
    };

    render() {
        return (

            <div className="page-content">
                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Apps</a></li>
                            <li className="breadcrumb-item active" aria-current="page">List Partner Manajemen</li>
                        </ol>
                    </nav>
                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Partner Management<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-1">
                                            <div className="form-group row">
                                                <button className="btn btn-success btn-sm btn-block" data-toggle="modal" data-target="#paycard" onClick={this.onCreate.bind(this)}><i className="fas fa-plus"></i> Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="table-responsive">
                                        <table className="table" id="tree-table">
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Logo</th>
                                                    <th>Priority</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.postData}
                                            </tbody>
                                        </table>
                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-left"></i>}
                                            nextLabel={<i className="fa fa-angle-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-end asrt-pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label"> Title  <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.cmstitle}
                                                name="cmstitle"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style} >{this.state.validation.cmstitle.message}</span>
                                        </div>
                                    </div>
 
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Logo <span>*</span></label>
                                                <div className="col-sm-7">
                                                <span>Upload file dalam bentuk jpg/jpeg/png & Max size file 5mb</span>
                                                    <input
                                                        className="form-control"
                                                        onChange={(e) => this.postDetails(e.target.files[0])}
                                                        id="custom-file"
                                                        type="file"
                                                        name="file"
                                                        label="Upload Profile Picture"
                                                    />
                                                </div>
                                                <div className="col-sm-2" style={{marginTop:"30px"}}>
                                                    <div style={this.state.loading ? {} : { display: 'none' }} className="spinner-border text-primary col-form-label br-2" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <a style={{ display: 'none', width:'50px' }} href="#0" target="_blank" rel="noreferrer" id="previewbanner" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>
                                                </div>
                                            </div>
 
                                    <div className="form-group row">
                                        <label htmlFor="kd_promo" className="col-sm-2 col-form-label">Priority  <span>*</span></label>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.priority}
                                                name="priority"
                                                onChange={this.onChange}
                                            />
                                            <span className="help-block" style={style}>{this.state.validation.priority.message}</span>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <br />
                                        <button className="btn btn-primary" onClick={this.onUpdate.bind(this)}
                                            style={this.state.isUpdate ? {} : { display: 'none' }}
                                        >
                                            Update
                                        </button>
                                        &nbsp;
                                        <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-success btn-block"
                                            style={this.state.isUpdate ? { display: 'none' } : {}}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                    <br /><br />
                                </form>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

        )
    }
}
