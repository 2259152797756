import React, { Component, Fragment } from 'react';
import { addToList, getList, deleteItem, updateItem } from "../../actions/categorylibraryAction";
import { getListoptions as Listdoc } from "../../actions/documentmanajemenAction";
import ReactPaginate from 'react-paginate';
import FormValidator from '../../components/FormValidator'
import $ from 'jquery';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
const style = {
    color: 'red',
};

export default class App extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
                field: 'level',
                method: 'isEmpty',
                validWhen: false,
                message: 'level category library is required'
            },
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Document category name is required'
            }
        ]);
        this.state = {
            offset: 0,
            data: [],
            child: [],
            list: [],
            listdoc:[],
            perPage: 25,
            level1: false,
            level2: false,
            level3: false,
            isFilter: false,
            level: "",
            name: "",
            parent: "-",
            order: "1",
            active: true,
            title: "",
            currentPage: 0,
            searchTitle: '',
            searchActive: '',
            searchLevel: '',
            isUpdate: false,
            nameold:'',
            listcategorylibrary: [],
            listusernew:[],
            message: "",
            errorMessage: '',
            alertparent:false,
            validation: this.validator.valid(),

        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCreate = this.onCreate.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onEdit = this.onEdit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);

    }
    filterspesialisasi = (key) => {
        getList().then(res => {
            const data = res['data'] ? res['data'] : [];
            var filterData
            if (key === 'level2') {
                filterData = data.filter(item => (item.level1 === true))
            } else if (key === 'level3') {
                filterData = data.filter(item => (item.level2 === true))
            } else {
                filterData = data
            }
            this.setState({ listcategorylibrary: [...filterData] })
        })
    }
    onChange(e) {

        this.setState({ [e.target.name]: e.target.value })

        if (e.target.name === 'level') {
            console.log('level', e.target.value);
            if (document.getElementById("level").value === 'level1') {
                document.getElementById("parent").disabled = true;
                this.setState({
                    level1: true,
                    level2: false,
                    level3: false,
                    parent: "",
                })
                this.filterspesialisasi("level1")
            }
            if (document.getElementById("level").value === 'level2') {
                document.getElementById("parent").disabled = false;
                this.setState({
                    level1: false,
                    level2: true,
                    level3: false,
                })
                this.filterspesialisasi("level2")

            }
            if (document.getElementById("level").value === 'level3') {
                document.getElementById("parent").disabled = false;
                this.setState({
                    level1: false,
                    level2: false,
                    level3: true,
                })
                this.filterspesialisasi("level3")
            }
        }

        if (e.target.name === 'isActive') {
            this.setState({
                active: !this.state.active,
            });
        }

        console.log('LAST STATE', this.state);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    onCreate(e) {
        e.preventDefault()
        this.onReset();
        this.setState({
            title: "Tambah Category Library"
        });
    }


    onEdit = (item_id, name, parent, level1, level2, level3, active, e) => {
        var lev
        if (level1 === true) {
            lev = "level1"
            this.filterspesialisasi("level1")
        } else if (level2 === true) {
            lev = "level2"
            this.filterspesialisasi("level2")
        } else if (level3 === true) {
            lev = "level3"
            this.filterspesialisasi("level3")
        }
        this.setState({
            id: item_id,
            title: "Edit Category Library",
            name: name,
            nameold: name,
            parent: parent,
            level1: level1,
            level2: level2,
            level3: level3,
            level: lev,
            isUpdate: true,
            active: active
        })
    }


    onReset() {
        this.setState({
            id: "",
            title: "",
            name: "",
            parent: "",
            level1: "",
            level2: "",
            level3: "",
            level: "",
            nameold:"",
            isUpdate: false,
            alertparent:false
        });
    }


    receivedData() {
        getList().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {
                const data = res['data'] ? res['data'] : [];
                var result
                var results
                var slice
                var filterData
                var active = (this.state.searchActive ? this.state.searchActive === "true" : null);
                if (active !== null) {
                    results = []
                    if(this.state.searchLevel === 'level1' ){
                        result = data.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.active === active) && (item.level1 === true))
                        slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.active === active) && (item.level1 === true))
                    }else if (this.state.searchLevel === 'level2' ){
                        result = data.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.active === active) && (item.level2 === true))
                        slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.active === active) && (item.level2 === true))
                    }else if (this.state.searchLevel === 'level3' ){
                        result = data.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.active === active) && (item.level3 === true))
                        slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.active === active) && (item.level3 === true))
                    }else{
                        result = data.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.active === active))
                        slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.active === active))
                    }
                    this.setState({ isFilter: true })
                } else if (active === null && this.state.searchLevel === "" && this.state.searchTitle !== "") {
                    results = []
                    result = data.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name))
                    slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name))
                    this.setState({ isFilter: true })
                }
                else if (active === null && this.state.searchLevel !== "" && this.state.searchTitle !== "") {
                    results = []

                    if(this.state.searchLevel === 'level1' ){
                        result = data.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.level1 === true))
                        slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.level1 === true))
                    }else if (this.state.searchLevel === 'level2' ){
                        result = data.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.level2 === true))
                        slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.level2 === true))
                    }else if (this.state.searchLevel === 'level3' ){
                        result = data.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.level3 === true))
                        slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name) && (item.level3 === true))
                    }
                    this.setState({ isFilter: true })
                }
                else if (active === null && this.state.searchLevel !== "" && this.state.searchTitle === "") {
                    results = []

                    if(this.state.searchLevel === 'level1' ){
                        result = data.filter(item => (item.level1 === true))
                        slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (item.level1 === true))
                    }else if (this.state.searchLevel === 'level2' ){
                        result = data.filter(item => (item.level2 === true))
                        slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (item.level2 === true))
                    }else if (this.state.searchLevel === 'level3' ){
                        result = data.filter(item => (item.level3 === true))
                        slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                        filterData = slice.filter(item => (item.level3 === true))
                    }

                    this.setState({ isFilter: true })
                }
                
                else {
                    this.setState({ isFilter: false })
                    results = data.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name))
                    result = data.filter(item => (item.level1 === true))
                    slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.name.toLowerCase().indexOf(this.state.searchTitle.toLowerCase()) > -1 : item.name))
                }
                this.setState(
                    {
                        list: [...data],
                        data: [...filterData],
                        child: [...results]
                    }
                )

                this.setState({
                    pageCount: Math.ceil(result.length / this.state.perPage),
                    filterData
                })


                this.check()
            }
        })
        Listdoc().then(res => {
            this.setState({listdoc:res['data'] ? res['data'] : []})
        })

    }
    Filter = (e) => {
        this.receivedData()
    }

    handlePageClick = (e) => {
        var selectedPage
        if (e.selected === undefined) {
            selectedPage = 1;
        } else {
            selectedPage = e.selected;
        }
        const offset = selectedPage * this.state.perPage;
        this.forceUpdate()
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
            this.check()
        });

    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
        } else {
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const validation = this.validator.validate(this.state);
        this.setState({ validation });

        if (validation.isValid) {
            if(this.state.level !== 'level1' && this.state.parent === '' ){
                this.setState({alertparent:true})
            }else{
                const categorylibraryRequest = {
                    level1: this.state.level1,
                    level2: this.state.level2,
                    level3: this.state.level3,
                    name: this.state.name,
                    parent: this.state.parent,
                    order: this.state.order,
                    active: this.state.active,
                }

                addToList(categorylibraryRequest).then(() => {
                    this.receivedData()
                    this.onReset();
                    $(".close").trigger("click");
                    this.check()
                }).catch(err => {
                    this.setState({ editDisabled: false, errorMessage: err.message })
                })
                this.setState({ editDisabled: false })
            }
        }

    }

    onUpdate = e => {
        e.preventDefault()
        const validation = this.validator.validate(this.state);
        this.setState({ validation });

        if (validation.isValid) {
            const UpdateRequest = {
                id: this.state.id,
                level1: this.state.level1,
                level2: this.state.level2,
                level3: this.state.level3,
                name: this.state.name,
                parent: this.state.parent,
                order: this.state.order,
                active: this.state.active
            }

            updateItem(UpdateRequest).then(() => {
                this.receivedData();
                $(".close").trigger("click");
                this.check()
            }).catch(err => {
                this.setState({ isUpdate: false, errorMessage: err.message })
            })

            this.setState({ isUpdate: false })
        }
    }

    onDelete = (val, e) => {
        e.preventDefault()
        let checklevel1 = this.state.list.filter(item => (item.parent === val) && (item.level2 === true)).length
        let checklevel2 = this.state.list.filter(item => (item.parent === val) && (item.level3 === true)).length

        if(checklevel1 !== 0){
            MySwal.fire('Sorry, the category cannot be deleted because it has have child')
        }else if(checklevel2 !== 0){
            MySwal.fire('Sorry, the category cannot be deleted because it has have child')
        }else{
            let checkstatus = this.state.listdoc.filter(item => item.category.find(category => category.id === val)).length
            if(checkstatus === 0){
            deleteItem(val).then(() => {
                this.receivedData();
                this.check()
            }).catch(err => {
                this.setState({ editDisabled: false, errorMessage: err.message })
            })
            }else{
                MySwal.fire('Sorry, the category cannot be deleted because it has used in document manajemen ') 
            }
        }
        
    }

    check = () => {
       
        var toggler = document.getElementsByClassName("caret");
        var i;

        for (i = 0; i < toggler.length; i++) {
            toggler[i].addEventListener("click", function () {
                this.parentElement.querySelector(".nested").classList.toggle("active");
                this.classList.toggle("caret-down");
            });
        }
    }

    render() {

        return (
            <div className="page-content">

                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/dashbaord">Dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Daftar Category Library</li>
                        </ol>
                    </nav>

                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">

                        </div>
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Manajemen Category Library<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>

                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group row">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.searchTitle}
                                                    name="searchTitle"
                                                    placeholder="Cari nama ..."
                                                    onChange={this.onChange}
                                                />
                                            </div></div>
                                        <div className="col-md-4">
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Status</label>
                                                <div className="col-sm-9">
                                                    <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="true">Aktif</option>
                                                        <option value="false">Tidak Aktif</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Level</label>
                                                <div className="col-sm-9">
                                                    <select className="form-control" name="searchLevel" value={this.state.searchLevel} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="level1">Level 1</option>
                                                        <option value="level2">Level 2</option>
                                                        <option value="level3">Level 3</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <div className="form-group row">
                                                <button className="btn btn-primary btn-sm btn-block" data-dismiss="modal" aria-label="Close" onClick={this.Filter.bind(this)}><i className="fas fa-search"></i> Filter</button>
                                            </div>
                                        </div>
                                        &nbsp;&nbsp;
                                        <div className="col-md-1">
                                            <div className="form-group row">
                                                <button className="btn btn-success btn-sm btn-block" data-toggle="modal" data-target="#paycard" onClick={this.onCreate.bind(this)}><i className="fas fa-plus"></i> Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive container">

                                        {(() => {
                                            if (this.state.searchActive === "" && this.state.searchTitle === "" && this.state.isFilter === false) {
                                                return (
                                                    <>
                                                        <table className="table" id="tree-table" >
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Type</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                        {this.state.data
                                                            .filter(tree => tree.level1 === true)
                                                            .map(function (one, induk) {
                                                                return <>

                                                                    <li><span className="caret">
                                                                        <tr className="application-item" data-id={one.id} data-parent={0} data-level={one.id} data-width="1" key={induk}>
                                                                            <td style={{ width: "220px" }}>
                                                                                {one.level1 === true ? <> {one.name}</> : null}
                                                                            </td>
                                                                            <td style={{ width: "230px" }}>  Level 1</td>
                                                                            <td style={{ width: "270px" }}>
                                                                                {one.active === true || one.active === "on" ?
                                                                                    <div className="productText">
                                                                                        Active
                                                                                    </div>
                                                                                    : null}
                                                                                {one.active === false || one.active === "" ?
                                                                                    <div className="productText">
                                                                                        Nonactive
                                                                                    </div>
                                                                                    : null} </td>
                                                                            <td><button className="btn btn-danger btn-sm mb-3 " style={{ width: "50px" }} onClick={this.onDelete.bind(this, one.id)} ><i className="fas fa-trash" ></i></button>&nbsp;&nbsp;
                                                                                <button className="btn btn-warning btn-sm mb-3" style={{ width: "50px" }} data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, one.id, one.name, one.parent, one.level1, one.level2, one.level3, one.active)}><i className="fas fa-edit" ></i></button>
                                                                            </td>
                                                                        </tr>


                                                                    </span>
                                                                        <ul className="nested">
                                                                            {this.state.child
                                                                                .filter(two => two.level2 === true && two.parent === one.id)
                                                                                .map(function (two, index) {
                                                                                    return <>
                                                                                        <li><span className="caret">
                                                                                            <tr className="application-item" data-id={two.id} data-parent={one.id} data-level={two.id} data-width="2" key={index}>
                                                                                                <td style={{ width: "200px" }}>
                                                                                                    &nbsp;{two.name}
                                                                                                </td>
                                                                                                <td style={{ width: "230px" }}> Level 2 </td>
                                                                                                <td style={{ width: "270px" }}>
                                                                                                    {two.active === true || two.active === "on" ?
                                                                                                        <div className="productText">
                                                                                                            Active
                                                                                                        </div>
                                                                                                        : null}
                                                                                                    {two.active === false || two.active === "" ?
                                                                                                        <div className="productText">
                                                                                                            Nonactive
                                                                                                        </div>
                                                                                                        : null}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <button className="btn btn-danger btn-sm mb-3" style={{ width: "50px" }} onClick={this.onDelete.bind(this, two.id)} ><i className="fas fa-trash" ></i></button>&nbsp;&nbsp;
                                                                                                    <button className="btn btn-warning btn-sm mb-3" style={{ width: "50px" }} data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, two.id, two.name, two.parent, two.level1, two.level2, two.level3, two.active)}><i className="fas fa-edit" ></i></button>
                                                                                                </td>
                                                                                            </tr>

                                                                                        </span>
                                                                                            <ul className="nested">
                                                                                                {this.state.child
                                                                                                    .filter(tree => tree.level3 === true && tree.parent === two.id)
                                                                                                    .map(function (tree, idx) {

                                                                                                        return <Fragment>
                                                                                                            <tr className="application-item" data-id={idx + 3} data-parent={two.id} data-level={idx + 3} data-width="3" key={index}>
                                                                                                                <td style={{ width: "180px" }}>
                                                                                                                    {tree.name}
                                                                                                                </td>
                                                                                                                <td style={{ width: "230px" }}>  Level 3</td>
                                                                                                                <td style={{ width: "270px" }}>
                                                                                                                    {tree.active === true || tree.active === "on" ?
                                                                                                                        <div className="productText">
                                                                                                                            Active
                                                                                                                        </div>
                                                                                                                        : null}
                                                                                                                    {tree.active === false || tree.active === "" ?
                                                                                                                        <div className="productText">
                                                                                                                            Nonactive
                                                                                                                        </div>
                                                                                                                        : null}
                                                                                                                </td>
                                                                                                                <td><button className="btn btn-danger btn-sm mb-3" style={{ width: "50px" }} onClick={this.onDelete.bind(this, tree.id)} ><i className="fas fa-trash" ></i></button>&nbsp;&nbsp;
                                                                                                                    <button className="btn btn-warning btn-sm mb-3" style={{ width: "50px" }} data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, tree.id, tree.name, tree.parent, tree.level1, tree.level2, tree.level3, tree.active)}><i className="fas fa-edit" ></i></button>
                                                                                                                </td>
                                                                                                            </tr></Fragment>;

                                                                                                    }.bind(this))}
                                                                                            </ul>
                                                                                        </li>
                                                                                    </>

                                                                                }.bind(this)
                                                                                )}
                                                                        </ul>
                                                                    </li>


                                                                </>;
                                                            }.bind(this))}
                                                    </>
                                                )
                                            } else if (this.state.isFilter === true) {


                                                return (
                                                    <table className="table" id="tree-table" >
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Type</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {this.state.data
                                                                .map((one, index) => (
                                                                    <tr className="application-item" key={index}>
                                                                        <td>{one.name}</td>
                                                                        <td> {one.level1 === true ?
                                                                            <div className="productText">
                                                                                Level 1
                                                                            </div>
                                                                            : null}
                                                                            {one.level2 === true ?
                                                                                <div className="productText">
                                                                                    Level 2
                                                                                </div>
                                                                                : null}
                                                                            {one.level3 === true ?
                                                                                <div className="productText">
                                                                                    Level 3
                                                                                </div>
                                                                                : null}</td>
                                                                        <td>
                                                                            {one.active === true || one.active === "on" ?
                                                                                <div className="productText">
                                                                                    Active
                                                                                </div>
                                                                                : null}
                                                                            {one.active === false || one.active === "" ?
                                                                                <div className="productText">
                                                                                    Nonactive
                                                                                </div>
                                                                                : null}</td>
                                                                        <td>
                                                                            <button className="btn btn-danger btn-sm " style={{ width: "50px" }} onClick={this.onDelete.bind(this, one.id)} ><i className="fas fa-trash" ></i></button>&nbsp;&nbsp;
                                                                            <button className="btn btn-warning btn-sm " style={{ width: "50px" }} data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, one.id, one.name, one.parent, one.level1, one.level2, one.level3, one.active)}><i className="fas fa-edit" ></i></button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                )
                                            }
                                        })()}

                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-left"></i>}
                                            nextLabel={<i className="fa fa-angle-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-end asrt-pagination"}
                                            subContainerClassName={"page pagination"}
                                            activeClassName={"active"} />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="title">Category Level</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <select className="form-control" name="level" id="level" value={this.state.level} onChange={this.onChange}>
                                                    <option value="" disabled>Pilih</option>
                                                    <option value="level1" key="level1">Level 1</option>
                                                    <option value="level2" key="level2">Level 2</option>
                                                    <option value="level3" key="level3">Level 3</option>
                                                </select>
                                                <span className="help-block" style={style}>{this.state.validation.level.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Document Category Name</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.name}
                                                    name="name"
                                                    onChange={this.onChange}
                                                />
                                                <span className="help-block" style={style}>{this.state.validation.name.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="pare">parent</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <select className="form-control" name="parent" id="parent" value={this.state.parent} onChange={this.onChange}>
                                                    <option value="-">No Parent</option>
                                                    {this.state.listcategorylibrary.map((item, index) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                               {this.state.alertparent === true &&  <span className="help-block" style={style}>Parent cant be empty</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo"></label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" name="active" checked={this.state.active} onChange={this.handleInputChange} type="checkbox" id="autoSizingCheck" />
                                                    <label className="form-check-label" htmlFor="autoSizingCheck">
                                                        Active
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-block" onClick={this.onUpdate.bind(this)}
                                        style={this.state.isUpdate ? {} : { display: 'none' }}
                                    >
                                        Update
                                    </button>
                                    <button type="submit" onClick={this.onSubmit.bind(this)} className="btn btn-success btn-block"
                                        style={this.state.isUpdate ? { display: 'none' } : {}}
                                    >
                                        Submit
                                    </button>
                                    <br></br>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        )
    }
}
