import React, { Component } from 'react';
import { checkview } from "../../actions/repotelibraryAction";
import Moment from 'react-moment';
import 'moment/locale/id';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import ReactPaginate from 'react-paginate';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            perPage: 25,
            currentPage: 0,
            data: [],
            id:"",
            loading:false,
            uploadload:false,
        };
        this.handlePageClick = this.handlePageClick.bind(this);


    }

    receivedData() {
        let params = window.location.pathname
        const myArray = params.split("/repotelibday/");
        let query = myArray[1] ? myArray[1] : []
        const masterdate = []
        this.setState({id:query})
        checkview(query, '','').then(res => {
            const data = res['data'] ? res['data']: [];
            for (let i = 0; i < data.length; i++) {
              if (masterdate.every(r => r.applydate !== data[i].applydate)) {
                masterdate.push({"applydate":data[i]['applydate'],"download":0, "read":0, "video":0})
              } 
            }
            this.countdate(masterdate)
        })
    }

    countdate(masterdate){
        const master = masterdate
        const slice = masterdate.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            data:slice,
            pageCount: Math.ceil(master.length / this.state.perPage)
        })
        for (let index = 0; index < slice.length; index++) {
           checkview(this.state.id, 'download',slice[index]['applydate'] ).then(res => {
            const data = res['pagination'] ? res['pagination']: [];
            const check = [...this.state.data]
            check[index]['download'] = data['count'] 
            this.setState({
                data:check
            })
         }) 
        }

        for (let index = 0; index < slice.length; index++) {
            checkview(this.state.id, 'read',slice[index]['applydate'] ).then(res => {
             const data = res['pagination'] ? res['pagination']: [];
             const check = [...this.state.data]
             check[index]['read'] = data['count'] 
             this.setState({
                 data:check
             })
            }) 
         }

         for (let index = 0; index < slice.length; index++) {
            checkview(this.state.id, 'watch',slice[index]['applydate'] ).then(res => {
             const data = res['pagination'] ? res['pagination']: [];
             const check = [...this.state.data]
             check[index]['video'] = data['count'] 
             this.setState({
                 data:check
             })
            }) 
         }
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
        } else {
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }

    onEdit = (date, type, e) => {
        this.props.history.push('/Repotelibdetail/' + this.state.id+'@'+date+'+'+type)
    }

    saveAsExcel() {
        let header = ["Date", "TOTAL DOWNLOAD","TOTAL READ ONLINE","TOTAL WATCH ONLINE"];
        let name = localStorage.getItem('name')? localStorage.getItem('name') : ''
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = this.getSheetData(this.state.data, header);
          const totalColumns = sheetData[0].length;
          sheet1.cell("A1").value(sheetData);
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style("bold", true);
          sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
          range.style("border", true);
          return workbook.outputAsync().then((res) => {
            saveAs(res, name+"-repotelibraryperday.xls");
          });
        });
      }
      
      getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
          });
        });
        sheetData.unshift(header);
        return sheetData;
      }


    render() {
        return (

            <div className="page-content">

                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Apps</a></li>
                            <li className="breadcrumb-item active" aria-current="page">List E-Library Document Metric Report</li>
                        </ol>
                    </nav>
                </div>
                <div className="main-wrapper">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">E-Library Document Metric Report - Per Day<a href="/#" onClick={()=> this.saveAsExcel()} style={{color:"white"}} className="btn btn-primary card-title-helper blockui-transactions">Download As Xls</a></h5>
                                    <span>{localStorage.getItem('name')? localStorage.getItem('name') : ''} - Document Date : <Moment locale="id" format="D MM y">{localStorage.getItem('documentdate')? localStorage.getItem('documentdate') : ''}</Moment></span>
                                    <br />
                                    <hr />
                                    <div className="table-responsive">
                                        <table className="table" id="tree-table">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Total Download</th>
                                                    <th>Total Read Online</th>
                                                    <th>Total Watch Online</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.data
                                                .map((one, index) => (
                                                    <tr className="application-item" key={index}>
                                                        <td><Moment locale="id" format="D MM y">{one.applydate}</Moment></td>
                                                        <td>
                                                            <button className="btn btn-warning btn-sm " data-toggle="modal" data-target="#paycard" style={{ width: '50px' }} onClick={this.onEdit.bind(this, one.applydate, 'download')}><span style={{color:'black'}}> {one.download}</span> <i className="fas fa-file"></i></button>
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-warning btn-sm " data-toggle="modal" data-target="#paycard" style={{ width: '50px' }} onClick={this.onEdit.bind(this, one.applydate, 'read')}><span style={{color:'black'}}> {one.read}</span> <i className="fas fa-file"></i></button>
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-warning btn-sm " data-toggle="modal" data-target="#paycard" style={{ width: '50px' }} onClick={this.onEdit.bind(this, one.applydate, 'watch')}><span style={{color:'black'}}> {one.video}</span> <i className="fas fa-file"></i></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-left"></i>}
                                            nextLabel={<i className="fa fa-angle-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-end asrt-pagination"}
                                            subContainerClassName={"page pagination"}
                                            activeClassName={"active"} />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
