import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { getList } from "../../actions/userActions";
import { getList as getListpro } from "../../actions/professionAction";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 1,
            data: [],
            perPage: 25,
            currentPage: 1,
            searchTitle: '',
            searchActive: '',
            searchCity:'',
            isUpdate: false,
            listspelialisasi: [],
            listconsultant: [],
            listpropesi: [],
            profesi: '',
            spesialisasi: '',
            consltant: '',
            level:''
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onEdit = this.onEdit.bind(this)
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value,
            currentPage: 1,
            offset: 1
        },() => {
            this.receivedData()
        });

    }

    prop = () => {
        getListpro().then(res => {
            const data = res['data'] ? res['data'] : [];
            const filterData = data.filter(item => (true ? item.level1 === true : item.level1) &&  (item.active === true))
            this.setState({ listpropesi: [...filterData] })
        })
    }
    handleChange = (evt) => {
        var index = evt.target.selectedIndex;
        var optionElement = evt.target.childNodes[index]
        var option = optionElement.getAttribute('data-order');
        this.filterspesialisasi(option)
        this.setState({ profesi: evt.target.value, currentPage: 1,
            offset: 1
        },() => {
            this.receivedData()
        });
    }

    handleChangeconsul = (evt) => {
        var index = evt.target.selectedIndex;
        var optionElement = evt.target.childNodes[index]
        var option = optionElement.getAttribute('data-order');
        this.filterlistconsultant(option)
        this.setState({ spesialisasi: evt.target.value, currentPage: 1,
            offset: 1
        },() => {
            this.receivedData()
        });
    }

    filterspesialisasi = (key) => {
        getListpro().then(res => {
            const data = res['data'] ? res['data'] : [];
            const filterData = data.filter(item => (key ? item.parent === key : item.parent) &&  (item.active === true))
            this.setState({ listspelialisasi: [...filterData], currentPage: 1,
                offset: 1
            },() => {
                this.receivedData()
            });
        })
    }
    filterlistconsultant = (key) => {
        getListpro().then(res => {
            const data = res['data'] ? res['data'] : [];
            const filterData = data.filter(item => (key ? item.parent === key : item.parent) &&  (item.active === true))
            this.setState({ listconsultant: [...filterData], currentPage: 1,
                offset: 1
            },() => {
                this.receivedData()
            });
        })
    }
    receivedData() {
        getList(this.state.searchTitle, this.state.searchCity, this.state.profesi, this.state.spesialisasi, this.state.consltant, this.state.currentPage, this.state.level).then(res => {
            if (res.data.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {

                const data = res['data']['data'] ? res['data']['data'] : []; 
                const pagination = res['data']['pagination'] ? res['data']['pagination']['count'] : []                
                const postData = data
                    .map((one, index) => (
                        
                        <tr className="application-item" key={index}>
                            <td>{one.name}</td>
                            <td>{one.email}</td>
                            <td>{one.gelardepan}</td>
                            <td>{one.gelarbelakang}</td>
                            <td>{one.tlp}</td>
                            <td>{one.profesi}</td>
                            <td>{one.spesialisasi === 'N' && <>-</>}
                                {one.spesialisasi !== 'N' && <>{one.spesialisasi}</>}
                            </td>
                            <td>
                                {one.consltant === 'N' && <>-</>}
                                {one.consltant !== 'N' && <>{one.consltant}</>}
                            </td>
                            <td>{one.level}</td>
                            <td>
                                {/* jika status 1 */}
                                {one.statuslevel === "1" && one.level === 'EARNER' && <>Waiting vertifikasi</>}
                                {one.statuslevel === "1" && one.level === 'MEMBER' && <>Vertifikasi</>}
                                {one.statuslevel === "1" && one.level === 'LEARNER' && <>Vertifikasi</>}

                                {/* jika status 2 */}
                                {one.statuslevel === "2" && one.level === 'EARNER' && <>Pendding vertifikasi</>}
                                {one.statuslevel === "2" && one.level === 'MEMBER' && <>Vertifikasi</>}
                                {one.statuslevel === "2" && one.level === 'LEARNER' && <>Vertifikasi</>}

                                 {/* jika status 3 */}
                                {one.statuslevel === "3" && one.level === 'EARNER' && <>Success vertifikasi</>}
                                {one.statuslevel === "3" && one.level === 'MEMBER' && <>Vertifikasi</>}
                                {one.statuslevel === "3" && one.level === 'LEARNER' && <>Vertifikasi</>}

                            </td>
                            <td><button onClick={this.onEdit.bind(this, one.id, one.uuid)} style={{ width: '50px' }} className="btn btn-warning btn-sm btn-block"><i className="fas fa-edit"></i></button></td>
                        </tr>
                    ))

                this.setState({
                    pageCount: Math.ceil(pagination / this.state.perPage),
                    postData
                })
            }
        });
    }
    
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage+1,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
            this.prop()
        }else{
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }

    onEdit(id, uuid, e) {
        let recent = [];
        recent.push({
            'id': id, 'uuid':uuid
        });
        localStorage.setItem('recentmember', JSON.stringify(recent));
        this.props.history.push(`/memberdetailprofile`)
    }

    render() {
        return (
            <div className="page-content">

                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/#">Apps</a></li>
                            <li className="breadcrumb-item active" aria-current="page">List Member</li>
                        </ol>
                    </nav>

                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Member Management<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.searchTitle}
                                                    name="searchTitle"
                                                    placeholder="enter name"
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.searchCity}
                                                    name="searchCity"
                                                    placeholder="enter City"
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Profession</label>
                                                <select className="form-control" name="profesi" value={this.state.profesi} onChange={this.handleChange}>
                                                    <option value="">All</option>
                                                    {this.state.listpropesi
                                                        .map((one, i) => (
                                                            <option value={one.name} data-order={one.id} key={i}>{one.name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Specialitation</label>
                                                <select className="form-control" name="spesialisasi" value={this.state.spesialisasi} onChange={this.handleChangeconsul}>
                                                    <option value="">All</option>
                                                    {this.state.listspelialisasi
                                                        .map((one) => (
                                                            <option value={one.name} data-order={one.id}>{one.name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Sub Specialitation</label>
                                                <select className="form-control" name="consltant" value={this.state.consltant} onChange={this.onChange}>
                                                    <option value="">All</option>
                                                    {this.state.listconsultant
                                                        .map((one) => (
                                                            <option value={one.name} data-order={one.id}>{one.name}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Level User</label>
                                                <select className="form-control" name="level" value={this.state.level} onChange={this.onChange}>
                                                    <option value="">All</option>
                                                    <option value="LEARNER">LEARNER</option>
                                                    <option value="MEMBER">MEMBER</option>
                                                    <option value="EARNER">EARNER</option>

                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table id="zero-conf" className="table"  >
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Gelar Depan</th>
                                                            <th>Gelar Belakang</th>
                                                            <th>Phone Number</th>
                                                            <th>Profession</th>
                                                            <th>Speciality</th>
                                                            <th>Sub-Speciality</th>
                                                            <th>Level</th>
                                                            <th>Status Vertifikasi</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.postData}
                                                    </tbody>
                                                </table>
                                                <br />
                                                <ReactPaginate
                                                    previousLabel={<i className="fa fa-angle-left"></i>}
                                                    nextLabel={<i className="fa fa-angle-right"></i>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={this.state.pageCount}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination justify-content-end asrt-pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"} />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}
