import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  FORGET_PASSWORD_FAIL,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
} from "../constants/userConstants";
import axios from "axios";

export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
      Authorization: `${userInfo.data.token}`,
      },
    }; 

    const {data} = await axios.get(`/api/v1/users/list?name=&address=&profesi=&spesialisasi=&consultant=&page=&jenis=&level=`, config);
    
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data['data'],
    });

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};

 

export const getUserbyuuid = (uuid) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
      .get(`/api/v1/users/searchbyuuid/${uuid}`, {
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      })
      .then(res => {
          res.data.status = 'success'
          return res.data
      }).catch(err => {
          return {
              error:'Please login again!',
              status:'failed',
              message:err.message
          }
      })
}

export const getUserbyemail = (email) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
      .get(`/api/v1/users/searchbyemail/${email}`, {
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      })
      .then(res => {
          res.data.status = 'success'
          return res.data
      }).catch(err => {
          return {
              error:'Please login again!',
              status:'failed',
              message:err.message
          }
      })
}

export const getUserbyid = (uuid) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
      .get(`/api/v1/users/searchbyid/${uuid}`, {
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      })
      .then(res => {
          res.data.status = 'success'
          return res.data
      }).catch(err => {
          return {
              error:'Please login again!',
              status:'failed',
              message:err.message
          }
      })
}

export const deleteUserAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `${userInfo.data.token}`,
      },
    };

    const { data } = await axios.delete(`/api/v1/users/delete/${id}`, config);

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    });
  }
};


export const forgetPasswordAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FORGET_PASSWORD_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/auth/forgetpassword/${id}`);
    if(data.code !== 200){
      dispatch({
        type: FORGET_PASSWORD_FAIL,
        payload: "email tidak terdaftar",
      });
    }else{
      dispatch({
        type: FORGET_PASSWORD_SUCCESS,
        payload: "Mohon cek email untuk link reset password",
      });
    }
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: FORGET_PASSWORD_FAIL,
      payload: message,
    });
  }
};

export const resetPasswordAction = (id, password, konfirmasi) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });
    if(password !== konfirmasi){
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: "Konfirmasi password salah",
      });
    }else{
      const { data } = await axios.put(
        `/api/v1/auth/resetpassword/${id}`,
        { password }
      );
      if(data.code !== 200){
        dispatch({
          type: RESET_PASSWORD_FAIL,
          payload: "Link anda sudah expaired",
        });
      }else{
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: "Reset Password Berhasil Silahkan Login",
        });
      }
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: FORGET_PASSWORD_FAIL,
      payload: message,
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/auth/login",
      { email, password },
      config
    );
    if(data.code !== 200){
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "Username Atau Password Anda Salah",
      });
    }else if(data.data['user']['role'] !== "ADMIN")
    {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "Username Atau Password Anda Salah",
      });
    }else{
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
    }

  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
};


export const bulkuploaduser = (name, email, password, tlp) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });
  const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        "Authorization": `${userInfo.data.token}`

      },
    };

    const { data } = await axios.post(
      "/api/v1/users/bulkupload",
      { name, email, password, tlp },
      config
    );

    if (data.code !== 200) {
      
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: data.error_description 
      });

      
    }else{
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: "Register sukses mohon check email verifikasi",
      });

    }


  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateProfesi = user => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/users/profesi/${user.id}`,
      {
        Profesi: user.profesi
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}

export const updatelevelmember = user => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/users/verifikasi/level/${user.id}`,
      {
        statuslevel: user.statuslevel,
        notelevel: user.notelevel
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}

export const updateSpesialisasi = user => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/users/spesialisasi/${user.id}`,
      {
        spesialisasi: user.spesialisasi
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}

export const updateConsltant = user => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/users/consltant/${user.id}`,
      {
        consltant: user.consltant
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}

export const updateUserAction = (role, id, name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, strfile, bank, cv, sertifikat, verifikasi, profile) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userInfo.data.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/v1/users/${id}`,
      {role, id, name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs,  strfile, bank, cv, sertifikat, verifikasi, profile},
      config
    );
    localStorage.setItem("recentmember", JSON.stringify([data['data']]));
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    });
  }
};


export const updateProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post("/api/users/profile", user, config);

    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const upload = member => {
   var token = JSON.parse(localStorage.getItem('userInfo'));
   const data = new FormData() 
         data.append('myFile', member.myFile)
  return axios
    .post(
      `/api/v1/events/upload?path=${member.path}`,
      data,
      {
        headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization': token['data']['token']
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}

 export const deletecontent = user => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    return axios
      .delete(
        `/api/v1/events/deletecontent/${user.file}?path=${user.path}`,
        {
          headers: { 
              'Content-Type': 'application/json', 
              'Authorization': token['data']['token']
          }
        }
      )
      .then(function(response) {
          return response.data;
      })
  }

  export const getList = (name, address, profesi, spesialisasi,consultant, page, level ) => {
    var token = JSON.parse(localStorage.getItem("userInfo"));
    return axios
      .get(`/api/v1/users/list?name=${name}&address=${address}&profesi=${profesi}&spesialisasi=${spesialisasi}&consultant=${consultant}&page=${page}&jenis=pagination&level=${level}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token["data"]["token"],
        },
      })
      .then((res) => {
        res.data.status = "success";
        return res;
      })
      .catch((err) => {
        return {
          error: "Please login again!",
          status: "failed",
          message: err.message,
        };
      });
  };

  export const getListuser = () => {
    var token = JSON.parse(localStorage.getItem("userInfo"));
    return axios
      .get("/api/v1/users/list?name=&address=&profesi=&spesialisasi=&consultant=&page=&jenis=&level=", {
        headers: {
          "Content-Type": "application/json",
          Authorization: token["data"]["token"],
        },
      })
      .then((res) => {
        res.data.status = "success";
        return res.data;
      })
      .catch((err) => {
        return {
          error: "Please login again!",
          status: "failed",
          message: err.message,
        };
      });
  };
  

