export const BANNER_LIST_REQUEST = "BANNER_LIST_REQUEST";
export const BANNER_LIST_SUCCESS = "BANNER_LIST_SUCCESS";
export const BANNER_LIST_FAIL = "BANNER_LIST_FAIL";

export const BANNER_CREATE_REQUEST = "BANNER_CREATE_REQUEST";
export const BANNER_CREATE_SUCCESS = "BANNER_CREATE_SUCCESS";
export const BANNER_CREATE_FAIL = "BANNER_CREATE_FAIL";

export const BANNER_UPDATE_REQUEST = "BANNER_UPDATE_REQUEST";
export const BANNER_UPDATE_SUCCESS = "BANNER_UPDATE_SUCCESS";
export const BANNER_UPDATE_FAIL = "BANNER_UPDATE_FAIL";

export const BANNER_DELETE_REQUEST = "BANNER_DELETE_REQUEST";
export const BANNER_DELETE_SUCCESS = "BANNER_DELETE_SUCCESS";
export const BANNER_DELETE_FAIL = "BANNER_DELETE_FAIL";
