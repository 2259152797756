import {
    CATEGORYLIBRARY_LIST_REQUEST,
    CATEGORYLIBRARY_LIST_SUCCESS,
    CATEGORYLIBRARY_LIST_FAIL,
    CATEGORYLIBRARY_CREATE_FAIL,
    CATEGORYLIBRARY_CREATE_REQUEST,
    CATEGORYLIBRARY_CREATE_SUCCESS
  } from "../constants/categorylibraryConstants";
  import axios from "axios";

  export const listCategorylibrary = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: CATEGORYLIBRARY_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `${userInfo.data.token}`,
        },
      };

      const {data} = await axios.get(`/api/v1/categorylibrary`, config);

      dispatch({
        type: CATEGORYLIBRARY_LIST_SUCCESS,
        payload: data['data'],
      });

    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: CATEGORYLIBRARY_LIST_FAIL,
        payload: message,
      });
    }
  };


export const getList = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
      .get('/api/v1/categorylibrary', {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      })
      .then(res => {
          res.data.status = 'success'
          return res.data
      }).catch(err => {
          return {
              error:'Please login again!',
              status:'failed',
              message:err.message
          }
      })
}

export const addToList = categorylibrary => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .post(
      '/api/v1/categorylibrary',
      {
        level1: categorylibrary.level1,
        level2: categorylibrary.level2,
        level3: categorylibrary.level3,
        parent: categorylibrary.parent,
        name: categorylibrary.name,
        order: categorylibrary.order,
        active: categorylibrary.active
      },
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
        }
      }
    )
    .then(function(response) {
        return response.data;
    }).catch(err => {
        return {
            error:'Error to add',
            status:'failed',
            message:err.message
        }
    })
}


export const updateItem = UpdateRequest => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/categorylibrary/${UpdateRequest.id}`,
      {
        level1: UpdateRequest.level1,
        level2: UpdateRequest.level2,
        level3: UpdateRequest.level3,
        parent: (UpdateRequest.parent ? UpdateRequest.parent : ""),
        name: UpdateRequest.name,
        order: UpdateRequest.order,
        active: UpdateRequest.active
      },
      {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
        }
      }
    )
    .then(function(response) {
        return response.data;
    })
}

export const deleteItem = (id) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .delete(`/api/v1/categorylibrary/${id}`, {
      headers: {
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
        }
    })
    .then(function(response) {
      return response.data;
  }).catch(err => {
      return {
          error:'Error to add',
          status:'failed',
          message:err.message
      }
  })
}

  export const createCategorylibraryAction = (level1, level2, level3, name, Parent, order) => async (
    dispatch,
    getState
  ) => {
    try {
      dispatch({
        type: CATEGORYLIBRARY_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${userInfo.data.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/v1/categorylibrary/add`,
        {level1, level2, level3, Parent, name, order},
        config
      );

      dispatch({
        type: CATEGORYLIBRARY_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: CATEGORYLIBRARY_CREATE_FAIL,
        payload: message,
      });
    }
  };
