import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import { updateEventAction, getListparticipant, updateItem, sendmailremaindersertificatenotready, sendmailremaindersertificateready } from "../../../actions/eventActions";
import { upload, deletecontent } from "../../../actions/userActions";
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

function Edit({ history }) {
    const dispatch = useDispatch();
    const [id, setId] = useState("");
    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [moderator, setModerator] = useState("");
    const [banner, setBanner] = useState("");
    const [sertificate, setSertificate] = useState("");
    const [skp, setSkp] = useState("");
    const [linkmeet, setLinkmeet] = useState("");
    const [meetid, setMeetid] = useState("");
    const [passcode, setPasscode] = useState("");
    const [priority, setPriority] = useState("");
    const [active, setActive] = useState("true");
    const [ishome, setIshome] = useState("true");
    const [speakers, setSpeakers] = useState([{ name: "", materi: "" }]);
    const [paticipans, setPaticipans] = useState([]);
    const eventCreate = useSelector((state) => state.eventCreate);
    const userLogin = useSelector((state) => state.userLogin);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const bucket = process.env.REACT_APP_API_KEY
    const {
        loading: loadingCreate,
        success: successCreate,
    } = eventCreate;
    const { userInfo } = userLogin;

 

    useEffect(() => {
          if (userInfo) {
            getListparticipant().then(res => {
                setId(res.data.id);
                setType(res.data.type);
                setTitle(res.data.title);
                setDate(res.data.date);
                setTime(res.data.time);
                setModerator(res.data.moderator);
                setBanner(res.data.banner);
                setSertificate(res.data.sertificate);
                setSkp(res.data.skp);
                setLinkmeet(res.data.linkmeet);
                setMeetid(res.data.meetid);
                setPasscode(res.data.passcode);
                setPriority(res.data.priority);
                setActive(res.data.active);
                setIshome(res.data.ishome);
                setSpeakers(res.data.speakers);
                setPaticipans(res.data.paticipans);
                if(res.data.sertificate !== '-' && res.data.sertificate !== undefined && res.data.sertificate !== null ){
                    document.getElementById("previewsertificate").style.display = 'block';
                    document.getElementById("previewsertificate").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/sertificate/"+res.data.sertificate
                }else{
                    document.getElementById("previewsertificate").style.display = 'none';  
                }
    
            })
            history.push("/eventcertificate");
          }else{
            history.push("/login");
          }
    }, [
        history,
        userInfo,
        successCreate,
        bucket
        
    ]);
    
    const resetHandler = () => {
        setType("");
        setTitle("");
        setDate("");
        setTime("");
        setModerator("");
        setBanner("");
        setSertificate("");
        setSkp("");
        setLinkmeet("");
        setMeetid("");
        setPasscode("");
        setPriority("");
        setActive("");
        setIshome("");
        setSpeakers([{ name: "", materi: "" }]);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateEventAction(id, type, title, date, time, moderator, banner, sertificate, skp, linkmeet, meetid, passcode, priority, active, ishome, speakers, paticipans));
        if (!type) return;
        resetHandler();
        history.push("/eventlist");
    };

    // ================== this function post image ==================

    const postDetails = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png")) {
           document.getElementById("previewsertificate").style.display = 'none';
           setLoading(true)
            if (sertificate !== "" || sertificate !== null ) {
                const deleteRequest = {
                    path: bucket+'/sertificate',
                    file: sertificate
                }
                deletecontent(deleteRequest)
            }
            const Request = {
                path: bucket+'/sertificate',
                myFile: pics
            }
           upload(Request).then((res) => {
                setLoading(false)
                setSertificate(res.data);
                document.getElementById("previewsertificate").style.display = 'block';
                document.getElementById("previewsertificate").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/sertificate/"+res.data  
            }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })

          
         } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type file yang anda masukan tidak cocok', 'info')
        }
      };

    //   =========== end funtion post image =========

    // this remainder email for certicate not ready
    const remaindercertificatenotready = (e) => {
        e.preventDefault()
        sendmailremaindersertificatenotready()
        setAlert(true);
        setMessage("waiting send mail to member");
        setTimeout(() => {
            setAlert(false);
            }, 8000);
    }


    // this remainder email for certicate ready
    const remaindercertificateready = (e) => {
        e.preventDefault()
        for (let index = 0; index < paticipans.length; index++) {
           if(paticipans[index]['remainder'] === 'R')
           {
            const list = [...paticipans];
            list[index]['remainder'] = 'G';
            setPaticipans(list)
            sendmailremaindersertificateready(paticipans[index]['email'], paticipans[index]['gelardepan'], paticipans[index]['nama'], paticipans[index]['gelarbelakang'])
           }
            
        }
        
        const UpdateRequest = {
            id: id,
            paticipans: paticipans,
            speakers: speakers,
        }
        updateItem(UpdateRequest)
        setAlert(true);
        setMessage("waiting send mail to member");
        setTimeout(() => {
            setAlert(false);
            }, 8000);
    }

    return (

        <div className="page-content">
            <div className="page-info">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/#">Apps</a></li>
                        <li className="breadcrumb-item active" aria-current="page">List Event</li>
                    </ol>
                </nav>
            </div>
            <div className="main-wrapper">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-transactions">
                            <div className="card-body">
                                <h5 className="card-title">Event Management<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                <nav className="navbar navbar-expand">
                                    <ul className="nav">
                                        <li><NavLink to="/eventeditview" className="nav-link active" href="#0">Event Detail</NavLink></li>
                                        <li><NavLink to="/eventparticipant" className="nav-link">Participant</NavLink></li>
                                        <li><NavLink to="/" className="nav-link">Certificate Template</NavLink></li>
                                        <li><NavLink to="/eventrequester" className="nav-link">Requester</NavLink></li>
                                    </ul>
                                </nav>
                                <hr />
                                <form onSubmit={submitHandler}>
                                    <div className="row mb-30">
                                        <div className="col-lg-12">
                                            <div style={alert ? {} : { display: 'none' }}   className="alert alert-danger" id="success-alert">
                                                <div className="d-flex align-items-center">
                                                    <strong><strong>Warning! </strong> {message} ...</strong>
                                                    <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                                    <div className="single-input mb-25">
                                                        <div className="form-group row">
                                                            <label htmlFor="first-name" className="col-sm-2 col-form-label">Sertificate <span>*</span></label>
                                                            <div className="col-sm-6">
                                                                <span>file sertificate jpeg/jpg/png only & Max size file 5mb </span>
                                                                <input
                                                                    className="form-control"
                                                                    onChange={(e) => postDetails(e.target.files[0])}
                                                                    id="custom-file"
                                                                    type="file"
                                                                    label="Upload Profile Picture"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2 py-4">
                                                                <div style={loading ? {} : { display: 'none' }} className="spinner-border text-primary col-form-label br-2" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                                <a style={{ width:'50px' }} href="#0" target="_blank" rel="noreferrer" id="previewsertificate" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                 {sertificate === "-"  && <button className="btn btn-info" onClick={(e) => remaindercertificatenotready(e)}><i className="fas fa-mail-bulk"></i> Reminder Certificate NOT Ready</button> }
                                                 {sertificate !== "-" &&  <button className="btn btn-warning" onClick={(e) => remaindercertificateready(e)}><i className="fas fa-mail-bulk"></i> Reminder Certificate Is Ready</button> }
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="profile-action-btn d-flex flex-wrap align-content-center justify-content-between">
                                                <button className="btn btn-primary" type="submit"><i className="fas fa-save"></i> Update Sertificate</button>
                                            </div>
                                        </div>
                                    </div>
                                    {loadingCreate && <Loading />}
                                </form>

                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )
}
export default Edit;
