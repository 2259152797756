import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserAction, upload, deletecontent, getUserbyid } from "../../actions/userActions";
import MenuMember from "../../components/MenuMember";
import $ from 'jquery';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

function Edit({ history }) {
    const dispatch = useDispatch();
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");
    const [tlp, setTlp] = useState("");
    const [nip, setNip] = useState("");
    const [email, setEmail] = useState("");
    const [profile, setProfile] = useState("");
    const [str, setStr] = useState("");
    const [verifikasi, setVerifikasi] = useState("");
    const [npkidi, setNpkidi] = useState("");
    const [nik, setNik] = useState("");
    const [tempatlahir, setTempatlahir] = useState("");
    const [tanggallahir, setTanggallahir] = useState("");
    const [profesi, setProfesi] = useState("");
    const [spesialisasi, setSpesialisasi] = useState("");
    const [consltant, setConsltant] = useState("");
    const [role, setRole] = useState("");
    const [address, setAddress] = useState([]);
    const [education, setEducation] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [sertifikat, setSertifikat] = useState([{ name: "", file: "-" }]);
    const [cv, setCv] = useState("");
    const [strfile, setStrfile] = useState("");
    const [bank, setBank] = useState("");
    const userUpdate = useSelector((state) => state.userUpdate);
    const userLogin = useSelector((state) => state.userLogin);
    const bucket = process.env.REACT_APP_API_KEY

    const {
        success: successUpdate,
    } = userUpdate;
    const { userInfo } = userLogin;
        
    useEffect(() => {
        var prod = JSON.parse(localStorage.getItem('recentmember'));
    getUserbyid(prod[0]['id']).then(result =>{

        setId(result.data.id);
        setName(result.data.name);
        setGelardepan(result.data.gelardepan);
        setGelarbelakang(result.data.gelarbelakang);
        setTlp(result.data.tlp);
        setEmail(result.data.email);
        setNip(result.data.nip);
        setStr(result.data.str);
        setNpkidi(result.data.npkidi);
        setNik(result.data.nik);
        setTempatlahir(result.data.tempatlahir);
        setTanggallahir(result.data.tanggallahir);
        setProfesi(result.data.profesi);
        setSpesialisasi(result.data.spesialisasi);
        setConsltant(result.data.consltant);
        setVerifikasi(result.data.verifikasi);
        setCv(result.data.cv);
        setStrfile(result.data.strfile);
        setBank(result.data.bank);
        setRole(result.data.role);
        setProfile(result.data.profile);
        
        if(result.data.cv !== undefined){
            setCv(result.data.cv);
            document.getElementById("previewcv").style.display = 'block';
            document.getElementById("previewcv").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/cv/"+result.data.cv.toString();
        }
        if(result.data.strfile !== undefined){
            setStrfile(result.data.strfile);
            document.getElementById("previewstr").style.display = 'block';
            document.getElementById("previewstr").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/str/"+result.data.strfile.toString();
        }
         if(result.data.bank !== undefined){
            setBank(result.data.bank);
            document.getElementById("previewbank").style.display = 'block';
            document.getElementById("previewbank").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/bank/"+result.data.bank.toString();
        }
           

        if(result.data.address.length !== 0){
            setAddress(result.data.address);
        }
        if(result.data.education.length !== 0){
            setEducation(result.data.education);
        }
        if(result.data.jobs.length !== 0){
            setJobs(result.data.jobs);
        }
        if(result.data.sertifikat.length !== 0 ){
            setSertifikat(result.data.sertifikat);
        }

    })
        
        if (userInfo) {
            history.push("/attachmentFile");
          }else{
            history.push("/login");
          }
    }, [
        history,
        userInfo,
        successUpdate,
        bucket
    ]);

    

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateUserAction(role, id, name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, strfile, bank, cv, sertifikat, verifikasi, profile ));       
        history.push("/memberlist");
    };

   

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...sertifikat];
        if (list[index]['file'] !== undefined) {
                const deleteRequest = {
                    path: bucket+'/sertifikat',
                    file: list[index]['file']
                }
                deletecontent(deleteRequest)
        }
        list.splice(index, 1);
        setSertifikat(list);
    };
    const handleClick = (i) => {
         $("#custom"+i).trigger("click");
    }
    const handleUploadClick = (pics, index) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("upload"+index).classList.remove("fa-upload");
            document.getElementById("upload" + index).classList.add("fa-spinner", "fa-spin");
            const list = [...sertifikat];
           if (list[index]['file'] !== undefined) {
                const deleteRequest = {
                    path: bucket+'/sertifikat',
                    file: list[index]['file']
                }
                deletecontent(deleteRequest)
            }
             const sertifikatRequest = {
                path: bucket+'/sertifikat',
                myFile: pics
            }

            upload(sertifikatRequest).then((res) => {
                document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
                document.getElementById("upload"+index).classList.add("fa-upload");
                const list = [...sertifikat];
                list[index]['file'] = res.data
                setSertifikat(list);
                document.getElementById("preview"+index).href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/sertifikat/"+res.data  
             }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })
            
        } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    };

    const uploadCv = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("previewcv").style.display = 'none';
            document.getElementById("cv").style.display = 'block';
            if (cv !== undefined) {
                const deleteRequest = {
                    path: bucket+'/cv',
                    file: cv
                }
                deletecontent(deleteRequest)
            }
             const cvRequest = {
                path: bucket+'/cv',
                myFile: pics
            }

            upload(cvRequest).then((res) => {
                document.getElementById("cv").style.display = 'none';
                setCv(res.data)
                document.getElementById("previewcv").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/cv/"+res.data
                document.getElementById("previewcv").style.display = 'block';
            }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })
            
        } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    };

    const uploadStr = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("previewstr").style.display = 'none';
            document.getElementById("str").style.display = 'block';

            if (strfile !== undefined) {
                const deleteRequest = {
                    path: bucket+'/str',
                    file: strfile
                }
                deletecontent(deleteRequest)
            }

             const strRequest = {
                path: bucket+'/str',
                myFile: pics
            }

            upload(strRequest).then((res) => {
                document.getElementById("str").style.display = 'none';
                setStrfile(res.data)
                document.getElementById("previewstr").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/str/"+res.data
                document.getElementById("previewstr").style.display = 'block';
            }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })

            .catch((err) => {
                console.log(err);
            });
        } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    }

    const uploadBank = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("previewbank").style.display = 'none';
            document.getElementById("bank").style.display = 'block';

             if (bank !== undefined) {
                const deleteRequest = {
                    path: bucket+'/bank',
                    file: bank
                }
                deletecontent(deleteRequest)
            }

            const bankRequest = {
                path: bucket+'/bank',
                myFile: pics
            }
            upload(bankRequest).then((res) => {
                document.getElementById("bank").style.display = 'none';
                setBank(res.data)
                document.getElementById("previewbank").href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/bank/"+res.data
                document.getElementById("previewbank").style.display = 'block';
            }).catch((err) =>{
                MySwal.fire('Oops...', err , 'info')
            })
           
        } else {
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    }

     // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...sertifikat];
        list[index][name] = value;
        setSertifikat(list);
    };


  
    // handle click event of the Add button
    const handleAddClick = () => {
        setSertifikat([...sertifikat, { name: "", file: "-"  }]);
    };
  
    return (


<div className="page-content">

<div className="page-info">
    <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/memberlist">Member</a></li>
            <li className="breadcrumb-item active" aria-current="page">Detail Member</li>
        </ol>
    </nav>
</div>
<div className="main-wrapper">
    <div className="row">
        <div className="col-lg-12">

        </div>
        <div className="col-lg-12">
            <div className="card card-transactions">
                <div className="card-body">
                    <h5 className="card-title">Member Management<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                    <MenuMember />
                    <hr/>
                    <form onSubmit={submitHandler}>
                        <div className="row mb-30">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-4 col-form-label">Upload Curriculum Vitae (CV)</label>
                                                <div className="col-sm-4">
                                                    <input
                                                        type="hidden"
                                                        className="form-control"
                                                        value={id}
                                                        name="id"
                                                        placeholder="Enter the Link"
                                                        onChange={(e) => setId(e.target.value)}
                                                    />
                                                    <input
                                                        className="form-control"
                                                        onChange={(e) => uploadCv(e.target.files[0])}
                                                        id="custom-file-cv"
                                                        type="file"
                                                        name="file"
                                                        label="Upload Profile Picture"
                                                    />
                                                    <span style={{fontSize:"6px"}}>Makismal ukuruan file 5mb dengan format JEPG/PNG/PDF</span>
                                                </div>
                                                <div className="col-sm-2 py-3">
                                                    <div style={{ display: 'none' }} id="cv" className="spinner-border text-primary col-form-label br-2" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <a style={{ display: 'none', width:'50px' }} href="#0" target="_blank" rel="noreferrer" id="previewcv" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-4 col-form-label">Upload Surat Tanda Registrasi (STR) </label>
                                                <div className="col-sm-4">
                                                    <input
                                                        className="form-control"
                                                        onChange={(e) => uploadStr(e.target.files[0])}
                                                        id="custom-file-str"
                                                        type="file"
                                                        name="file"
                                                        label="Upload Profile Picture"
                                                    />
                                                    <span style={{fontSize:"6px"}}>Makismal ukuruan file 5mb dengan format JEPG/PNG/PDF</span>
                                                </div>
                                                <div className="col-sm-2 py-3">
                                                    <div style={{ display: 'none' }} id="str" className="spinner-border text-primary col-form-label br-2" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <a style={{ display: 'none', width:'50px' }} href="#0" target="_blank" rel="noreferrer" id="previewstr" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-4 col-form-label">Upload Buku Bank </label>
                                                <div className="col-sm-4">
                                                    <input
                                                        className="form-control"
                                                        onChange={(e) => uploadBank(e.target.files[0])}
                                                        id="custom-file-bank"
                                                        type="file"
                                                        name="file"
                                                        label="Upload Profile Picture"
                                                    />
                                                    <span style={{fontSize:"6px"}}>Makismal ukuruan file 5mb dengan format JEPG/PNG/PDF</span>
                                                </div>
                                                <div className="col-sm-2 py-3">
                                                    <div style={{ display: 'none' }} id="bank" className="spinner-border text-primary col-form-label br-2" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <a style={{ display: 'none', width:'50px' }} href="#0" target="_blank" rel="noreferrer" id="previewbank" className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />                                    
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                {/* <label htmlFor="first-name" className="col-sm-6 col-form-label">Riwayat Pendidikan <span>*</span></label> */}
                                                <strong className="mb-1">Upload Sertifikat</strong>
                                                <div className="col-sm-12 alert alert-success">
                                                    <label htmlFor="first-name" className="col-sm-4 col-form-label">Name <span>*</span></label>
                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">File <span>*</span></label>
                                                    {sertifikat.map((x, i) => {
                                                        return (
                                                            <div className="box row" key={i}>
                                                                <div className="col-sm-4">
                                                                    <input
                                                                        name="name"
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Pelatihan xxx"
                                                                        value={x.name}
                                                                        onChange={e => handleInputChange(e, i)}
                                                                    />
                                                                </div>
                                                                
                                                                <div>
                                                                    {x.file !== "-" && <><a href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/sertifikat/"+x.file} target="_blank" rel="noreferrer" id={"preview" + i} className="btn btn-primary btn-sm" ><i className="fas fa-image"></i></a>&nbsp;</>}
                                                                    <input
                                                                    className="btn btn-info btn-sm mb-2"
                                                                    onChange={(e) => handleUploadClick(e.target.files[0], i)}
                                                                    id={"custom"+i}
                                                                    type="file"
                                                                    name="file"
                                                                    style={{display: "none"}}
                                                                    label="Upload Profile Picture"
                                                                   />
                                                                    <a href="#0"  className="btn btn-info btn-sm mb-2" onClick={() => handleClick(i)}><i id={"upload"+i} className="fas fa-upload"></i></a>&nbsp;

                                                                </div>
                                                                    {sertifikat.length !== 1 &&
                                                                        <span>
                                                                        <button className="btn btn-danger btn-sm mb-2" onClick={() => handleRemoveClick(i)}><i className="fas fa-trash-alt"></i></button>&nbsp;
                                                                        </span>
                                                                    }

                                                                    {sertifikat.length - 1 === i &&
                                                                    <>
                                                                        <button className="btn btn-warning btn-sm mb-2" onClick={handleAddClick}><i className="fas fa-plus-square"></i></button>
                                                                    </>}


                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="profile-action-btn d-flex flex-wrap align-content-center justify-content-between">
                                    <button className="btn btn-primary" type="submit"><i className="fas fa-save"></i> Update Attachment File</button>
                                </div>
                            </div>
                        </div>
                    </form>

                    <br />
                </div>
            </div>
        </div>
    </div>
</div>


</div>



    )
}
export default Edit;
