import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import { forgetPasswordAction } from "../../actions/userActions";


function Forgetpassword({ history }) {
    const [email, setEmail] = useState("");

    const dispatch = useDispatch();

    const forgetPassword = useSelector((state) => state.forgetPassword);
    const { loading, error, success, userInfo } = forgetPassword;
    useEffect(() => {
        if (userInfo) {
            history.push("/");
            // window.location.href = '/';
        }
    }, [history, userInfo]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(forgetPasswordAction(email));
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-5">
                    <div className="auth-form">
                        <div className="row">
                            <div className="col">
                                <div className="logo-box"><a href="/#" className="logo-text">Forget Password</a></div>
                                {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
                                {success && <ErrorMessage variant="warning">{success}</ErrorMessage>}
                                <form onSubmit={submitHandler}>
                                    <div className="form-group">
                                        <input className="form-control" id="email" aria-describedby="emailHelp"
                                            type="email"
                                            value={email}
                                            placeholder="Enter email"
                                            onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block btn-submit">Submit</button>
                                </form>
                                {loading && <Loading />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 d-none d-lg-block d-xl-block">
                    <div className="auth-image" />
                </div>
            </div>
        </div>

    )
}
export default Forgetpassword;
