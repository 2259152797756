import axios from "axios";
 

export const getList = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .get('/api/v1/cms/list', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(res => {
      res.data.status = 'success'
      return res.data
    }).catch(err => {
      return {
        error: 'Please login again!',
        status: 'failed',
        message: err.message
      }
    })
}

// export const Syncgeneral = () => {
//   var token = JSON.parse(localStorage.getItem('userInfo'));
//   return axios
//     .get('/xml/general', {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': token['data']['token']
//       }
//     })
//     .then(res => {
//       return res.data
//     }).catch(err => {
//       return {
//         error: 'Please login again!',
//         status: 'failed',
//         message: err.message
//       }
//     })
// }

// export const Syncevent = () => {
//   var token = JSON.parse(localStorage.getItem('userInfo'));
//   return axios
//     .get('/xml/event', {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': token['data']['token']
//       }
//     })
//     .then(res => {
//       return res.data
//     }).catch(err => {
//       return {
//         error: 'Please login again!',
//         status: 'failed',
//         message: err.message
//       }
//     })
// }

// export const Syncjob = () => {
//   var token = JSON.parse(localStorage.getItem('userInfo'));
//   return axios
//     .get('/xml/job', {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': token['data']['token']
//       }
//     })
//     .then(res => {
//       return res.data
//     }).catch(err => {
//       return {
//         error: 'Please login again!',
//         status: 'failed',
//         message: err.message
//       }
//     })
// }

export const getListxml = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .get('/api/v1/auth/xml/list', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(res => {
      res.data.status = 'success'
      return res.data
    }).catch(err => {
      return {
        error: 'Please login again!',
        status: 'failed',
        message: err.message
      }
    })
}

export const addToList = cms => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .post(
      '/api/v1/cms/add',
      {
        title: cms.title,
        description: cms.description,
        url: cms.url,
        active: cms.active,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}


export const addEventXml = xml => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .post(
      '/api/v1/xml/add',
      {
        url: xml.url,
        type: xml.type
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}

export const deleteItemxml = (id) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .delete(`/api/v1/xml/delete/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}

export const updateItem = cms => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/cms/update/${cms.id}`,
      {
        title: cms.title,
        description: cms.description,
        url: cms.url,
        active: cms.active
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}


export const deleteItem = (id) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .delete(`/api/v1/cms/delete/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}


