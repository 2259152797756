import axios from "axios";
export const checkview = (id, type, date) => {
    return axios
        .get(`/api/v1/viewerelib/list?documentid=${id}&memberid=&date=${date}&type=${type}&jenis=&page=1`, {
        headers: { 
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
            res.data.status = 'success'
            return res.data
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
  }

  export const checkviewpaginet = (id, type, date, page) => {
    return axios
        .get(`/api/v1/viewerelib/list?documentid=${id}&memberid=&date=${date}&type=${type}&jenis=pagination&page=${page}`, {
        headers: { 
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
            res.data.status = 'success'
            return res
        }).catch(err => {
            return {
                error:'Please login again!',
                status:'failed',
                message:err.message
            }
        })
  }