import {
    ARTICLE_LIST_FAIL,
    ARTICLE_LIST_REQUEST,
    ARTICLE_LIST_SUCCESS,
    LIST_ARTICLE_FAIL,
    LIST_ARTICLE_REQUEST,
    LIST_ARTICLE_SUCCESS,
    ARTICLE_DELETE_FAIL,
    ARTICLE_DELETE_REQUEST,
    ARTICLE_DELETE_SUCCESS,
    ARTICLE_CREATE_FAIL,
    ARTICLE_CREATE_REQUEST,
    ARTICLE_CREATE_SUCCESS,
  } from "../constants/articleConstants";
  
  export const articleListReducer = (state = { articles: [] }, action) => {
    switch (action.type) {
      case ARTICLE_LIST_REQUEST:
        return { loading: true };
      case ARTICLE_LIST_SUCCESS:
        return { loading: false, articles: action.payload };
      case ARTICLE_LIST_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };

  export const listArticleReducer = (state = { articlelist: [] }, action) => {
    switch (action.type) {
      case LIST_ARTICLE_REQUEST:
        return { loading: true };
      case LIST_ARTICLE_SUCCESS:
        return { loading: false, articlelist: action.payload };
      case LIST_ARTICLE_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };

  export const articleDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case ARTICLE_DELETE_REQUEST:
        return { loading: true };
      case ARTICLE_DELETE_SUCCESS:
        return { loading: false, success: true };
      case ARTICLE_DELETE_FAIL:
        return { loading: false, error: action.payload, success: false };
      default:
        return state;
    }
  };

  export const articleCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case ARTICLE_CREATE_REQUEST:
        return { loading: true };
      case ARTICLE_CREATE_SUCCESS:
        return { loading: false, success: true };
      case ARTICLE_CREATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  