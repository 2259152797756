import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { getUserbyid, updatelevelmember } from "../actions/userActions";
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function MenuProfile() {
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");
    const [profile, setProfile] = useState("");
    const [level, setLevel] = useState("");
    const [statuslevel, setStatuslevel] = useState("");
    const [notelevel, setNotelevel] = useState("");
    const bucket = process.env.REACT_APP_API_KEY

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    useEffect(() => {
        var prod = JSON.parse(localStorage.getItem('recentmember'));
        getUserbyid(prod[0]['id']).then(result =>{
            setId(result.data.id);
            setName(result.data.name);
            setGelardepan(result.data.gelardepan);
            setGelarbelakang(result.data.gelarbelakang);
            setProfile(result.data.profile);
            setLevel(result.data.level);
            setStatuslevel(result.data.statuslevel);
            setNotelevel(result.data.notelevel ? result.data.notelevel : '');
        })
    }, [
        userInfo
    ]);

    const handleverifikasi = (e) =>{
        setStatuslevel(e.target.value)
        if(e.target.value === '2'){
        $("#note").trigger("click")
        }else if(e.target.value === '3'){
            let data = {
                id:id,
                statuslevel:'3',
                notelevel:'-'
            }
            updatelevelmember(data)  
        }
    }

    const handlesubmit = () => {
        let data = {
            id:id,
            statuslevel:statuslevel,
            notelevel:notelevel
        }
        updatelevelmember(data)
    }
    
    return (
     <>
        <nav className="navbar navbar-expand">
            <div className="row">
                <div className="col-md-2">
                {(() => {
                    if (profile === undefined || profile === "-") {
                        return (
                            <><img src="http://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/profile/2263a021-a46c-4bf1-9210-e47dada02735.png" id="profile" alt="profile" className="rounded-circle" height="100px" /></>
                        )
                    } else if (profile !== undefined || profile !== "-") {
                        return (
                            <><img src={"http://prosehat-digihealth.s3.us-east-2.amazonaws.com/" + bucket + "/profile/" + profile} id="profile" alt="profile" className="rounded-circle" height="100px" /></>
                        )
                    }
                })()}
                </div>
                <div className="col-md-3" style={{marginTop:'auto', marginBottom:'auto'}}>
                    <strong>{gelardepan}{name}{gelarbelakang}</strong> <br/>
                    {statuslevel !== '3' && statuslevel !== '2' && <><label>Level </label> <strong>{level}</strong></> }                                                    
                    {statuslevel === '3' && <><label>Level </label> <strong>{level} Terverifikasi</strong></>}                                                   
                    {statuslevel === '2' && <><label>Level </label> <strong>{level} <br/>Pending Verifikasi</strong></>}                                                   
                </div>
                {statuslevel !== '3' && level === 'EARNER' && <><div className="col-md-3"  style={{marginTop:'auto', marginBottom:'auto'}}>
                    <select className="form-control" name="profesi" value={statuslevel} onChange={(e) => handleverifikasi(e)} style={{width:'200px'}}>
                        <option value="1" disabled>Waiting Verifikasi</option>
                        <option  value="2" >Pending Verifikasi</option>
                        <option value="3">Vertifikasi Success</option>
                    </select>                                                 
                </div>
                </>}
                {statuslevel === '2' && level === 'EARNER' && <div className="col-md-1" style={{marginTop:'auto', marginBottom:'auto'}}>
                    <button  className="btn btn-info btn-sm text-right" data-toggle="modal" data-target="#notestatus"><i className="fas fa-info"></i></button>
                </div> }
                <div className="col-md-12">
                <br/><br/>
                    <ul className="nav">
                        <li><NavLink to="/memberdetailprofile" className="nav-link active">Profile</NavLink></li>
                        <li><NavLink to="/daftar_activity_admin" className="nav-link">Daftar Activity</NavLink></li>
                        <li><NavLink to="/listaddress" className="nav-link">Address</NavLink></li>
                        <li><NavLink to="/attachmentFile" className="nav-link">Attachment File</NavLink></li>
                    </ul>
                </div>
            </div>
        </nav>
        <button style={{display:'none'}} id="note" data-toggle="modal" data-target="#notestatus" />
        <div className="modal fade in" id="notestatus" tabIndex={-1} role="dialog" aria-labelledby="notestatus" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Note Status Verifikasi Pending</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <CKEditor
                            editor={ClassicEditor}
                            data={notelevel}
                            config={{
                                mediaEmbed: {
                                    previewsInData: false
                                }
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setNotelevel(data);
                                console.log({ event, editor, data });
                            }}
                            
                        />
                        <br/>
                        <button onClick={handlesubmit} data-dismiss="modal" aria-label="Close" className="btn btn-info btn-sm text-right" >Submit</button> 
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
     </>
  );
}

export default MenuProfile;
