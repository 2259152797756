import React, { Component, Fragment } from 'react';
import { addToList, getList, deleteItem, updateItem, updateItemorder } from "../../actions/footerAction";
import { getList as listpage } from "../../actions/pagemanajemenAction";
import ReactPaginate from 'react-paginate';
import FormValidator from '../../components/FormValidator'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import $ from 'jquery';
const style = {
    color: 'red',
};
const MySwal = withReactContent(Swal)


export default class App extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([

            {
                field: 'title',
                method: 'isEmpty',
                validWhen: false,
                message: 'name title is required'
            },
            {
                field: 'url',
                method: 'isEmpty',
                validWhen: false,
                message: 'url is required'
            },
            {
                field: 'position',
                method: 'isEmpty',
                validWhen: false,
                message: 'position is required'
            },
            {
                field: 'relative',
                method: 'isEmpty',
                validWhen: false,
                message: 'relative to is required'
            },
            {
                field: 'page',
                method: 'isEmpty',
                validWhen: false,
                message: 'page is required'
            }
        ]);
        this.state = {
            offset: 0,
            data: [],
            child: [],
            list: [],
            perPage: 25,
            level1: false,
            level2: false,
            level3: false,
            isFilter: false,
            level: "",
            order: '',
            first: '',
            last: '',
            headertitle: "",
            relative: "-",
            parent: "-",
            type: "Empty",
            url: "Empty",
            active: true,
            title: "",
            page: "Empty",
            levelup: "level1",
            position: "Empty",
            currentPage: 0,
            searchTitle: '',
            searchActive: '',
            isUpdate: false,
            listpages: [],
            listnavigation: [],
            message: "",
            errorMessage: '',
            validation: this.validator.valid(),

        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCreate = this.onCreate.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onEdit = this.onEdit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);

    }
    filterspesialisasi = (key) => {
        console.log(key)
        getList().then(res => {
            const data = res['data'] ? res['data'] : [];
            var filterData
            if (key === 'level1') {
                filterData = data.filter(item => (item.level1 === true) && (item.active === true))
            } else {
                filterData = data
            }

            this.setState({ listnavigation: [...filterData] })
        })
    }

    filterpage = () => {
        listpage().then(res => {
            const data = res['data'] ? res['data'] : [];
            const result = data.filter(item => (item.active === true))

            this.setState({ listpages: [...result] })
        })
    }
    onChange(e) {

        this.setState({ [e.target.name]: e.target.value })
        if (e.target.name === 'type') {
            if (document.getElementById("type").value === 'Empty') {

                document.getElementById("url").disabled = true;
                document.getElementById("page").disabled = true;

            } else if (document.getElementById("type").value === 'URL Path') {

                document.getElementById("page").disabled = true;
                document.getElementById("url").disabled = false;


            } else if (document.getElementById("type").value === 'Page') {
                document.getElementById("url").disabled = true;
                document.getElementById("page").disabled = false;

            }
        }

        if (e.target.name === 'position') {
            console.log(document.getElementById("position").value)
            if (document.getElementById("position").value === 'Empty') {
                document.getElementById("parent").disabled = true;
                this.setState({
                    level1: true,
                    level2: false,
                    level3: false,
                    parent: "",
                    levelup: "level1",
                })
            }
            if (document.getElementById("position").value === 'Child Of') {
                document.getElementById("parent").disabled = false;
                this.setState({
                    level1: false,
                    level2: true,
                    level3: false,
                })
                this.filterspesialisasi("level1")

            }
            if (document.getElementById("position").value === 'Left / Before' || document.getElementById("position").value === 'Right / After') {
                document.getElementById("parent").disabled = false;
                this.setState({
                    level1: true,
                    level2: false,
                    level3: false,
                })
                this.filterspesialisasi("level1")
            }

        }

        if (e.target.name === 'parent') {
            var sel = document.getElementById('parent')
            var text = sel.options[sel.selectedIndex].text;
            var uid = sel.options[sel.selectedIndex].getAttribute('data-uid');
            var order = sel.options[sel.selectedIndex].getAttribute('data-order');
            if (document.getElementById("position").value === 'Left / Before') {
                console.log(order - 1)
                this.setState({
                    first: parseInt(order - 1)
                })

            } else if (document.getElementById("position").value === 'Right / After') {
                console.log(order)
                this.setState({
                    first: parseInt(order)
                })
            }

            if (uid === "level2" && document.getElementById("position").value === 'Child Of') {
                this.setState({
                    relative: text,
                    levelup: "level3",
                    level1: false,
                    level2: false,
                    level3: true
                })
            } else if (uid === "level1" && document.getElementById("position").value === 'Child Of') {
                this.setState({
                    relative: text,
                    levelup: "level2",
                })
            }
            else {
                this.setState({
                    relative: text,
                    levelup: "level1"
                    // levelup:uid
                })
            }

        }

        if (e.target.name === 'active') {
            this.setState({
                active: !this.state.active,
            });
        }

        // console.log('LAST STATE', this.state);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    onCreate(e) {
        e.preventDefault()
        this.onReset();
        this.filterpage();
        this.setState({
            headertitle: "Tambah Navigation Footer"
        });
    }


    onEdit = (id, title, parent, level1, level2, level3, levelup, type, url, relative, active, position, page, e) => {
        if (type === 'Empty') {

            document.getElementById("url").disabled = true;
            document.getElementById("page").disabled = true;

        } else if (type === 'URL Path') {

            document.getElementById("page").disabled = true;
            document.getElementById("url").disabled = false;


        } else if (type === 'Page') {
            document.getElementById("url").disabled = true;
            document.getElementById("page").disabled = false;
            $("#page").val(page);

        }

        if (position === 'Child Of') {
            document.getElementById("parent").disabled = false;
            this.filterspesialisasi("level3")

        }
        if (position === 'Left / Before' || position === 'Right / After') {
            document.getElementById("parent").disabled = false;
            this.filterspesialisasi("level1")
        }

        this.setState({
            id: id,
            headertitle: "Edit Page Navigation",
            title: title,
            parent: parent,
            level1: level1,
            level2: level2,
            level3: level3,
            levelup: levelup,
            type: type,
            url: url,
            page: page,
            position: position,
            relative: relative,
            isUpdate: true,
            active: active
        })
    }


    onReset() {
        this.setState({
            id: "",
            headertitle: "",
            title: "",
            parent: "Empty",
            level1: true,
            level2: false,
            level3: false,
            levelup: "level1",
            type: "Empty",
            url: "Empty",
            page: "Empty",
            position: "Empty",
            relative: "Empty",
            isUpdate: false,
            active: true
        })
    }


    receivedData() {
        getList().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('userInfo')
                this.props.history.push(`/login`)
            } else {
                const data = res['data'] ? res['data'] : [];
                var result
                var results
                var slice
                var filterData
                var active = (this.state.searchActive ? this.state.searchActive === "true" : null);
                if (active !== null) {
                    results = []
                    result = data.filter(item => (this.state.searchTitle ? item.title === this.state.searchTitle : item.title) && (item.active === active))
                    slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.title === this.state.searchTitle : item.title) && (item.active === active))
                    this.setState({ isFilter: true })
                } else if (active === null && this.state.searchTitle !== "") {
                    results = []
                    result = data.filter(item => (this.state.searchTitle ? item.title === this.state.searchTitle : item.title))
                    slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.title === this.state.searchTitle : item.title))
                    this.setState({ isFilter: true })
                }
                else if (active === null && this.state.searchTitle === "") {
                    this.setState({ isFilter: false })
                    results = data.filter(item => (this.state.searchTitle ? item.title === this.state.searchTitle : item.title))
                    result = data.filter(item => (item.level1 === true))
                    slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.title === this.state.searchTitle : item.title))

                }
                else {
                    results = data.filter(item => (this.state.searchTitle ? item.title === this.state.searchTitle : item.title))
                    result = data.filter(item => (item.level1 === true))
                    slice = result.slice(this.state.offset, this.state.offset + this.state.perPage)
                    filterData = slice.filter(item => (this.state.searchTitle ? item.title === this.state.searchTitle : item.title))
                }
                this.setState(
                    {
                        list: [...data],
                        data: [...filterData],
                        child: [...results]
                    }
                )

                this.setState({
                    pageCount: Math.ceil(result.length / this.state.perPage),
                    filterData
                })


                this.check()
            }
        })


    }


    handlePageClick = (e) => {
        var selectedPage
        if (e.selected === undefined) {
            selectedPage = 1;
        } else {
            selectedPage = e.selected;
        }
        const offset = selectedPage * this.state.perPage;
        this.forceUpdate()
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
            this.check()
        });

    };

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
            this.filterpage()
            document.getElementById("url").disabled = true;
            document.getElementById("page").disabled = true;
        } else {
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }

    onChangeOrder = () => {
        if (this.state.position === "Right / After" || this.state.position === "Left / Before") {

            this.receivedData()
            var filter = this.state.list
                .filter(one => (one.level1 === true))
                .sort((a, b) => a.order > b.order ? 1 : -1);

            console.log('first ' + this.state.first)
            console.log('last ' + parseInt(this.state.last))
            for (let index = 0; index < filter.length; index++) {
                //  console.log(this.state.first)
                // mulai dari 1 soalnya first = 0
                // 
                if (parseInt(filter[index]['order']) > parseInt(this.state.first)) {
                    var y = index + 1
                    console.log(y.toString())
                    // update 1,2,3 = 2,3,4
                    const pageRequest = {
                        id: filter[index]['id'],
                        order: y.toString()
                    }
                    updateItemorder(pageRequest)
                    if (parseInt(filter[index]['order']) === parseInt(this.state.last)) { break; }
                }

            }
            this.check()
        }


        //  console.log('ini first '+(this.state.first+1).toString())
        //  this.receivedData()
        //  var filternew = this.state.list.filter(one => (one.title === this.state.title));
        //  console.log(filternew)
        //  const pageRequest = {
        //      id: filternew[0]['id'],
        //      order: (this.state.first+1).toString()
        //  }
        //  updateItemorder(pageRequest)
        //   this.onReset();


    }

    onCheckpositionUpdate = e => {
        e.preventDefault();
        var filter = this.state.list.filter(one => (one.level1 === true));
        if (this.state.type === "Empty") {
            if (this.state.position === "Empty" && filter.length === 0) {
                this.onUpdate()
            } else if (this.state.position === "Empty" && filter.length !== 0) {
                MySwal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: 'Harap lengkapi Posisi',
                })
            } else {
                this.onUpdate()
            }
        } else if (this.state.type === "URL Path") {
    
            if (this.state.url !== "Empty") {
                if (this.state.position === "Empty" && filter.length === 0) {
                    this.onUpdate()
                } else if (this.state.position === "Empty" && filter.length !== 0) {
                    MySwal.fire({
                        icon: 'info',
                        title: 'Oops...',
                        text: 'Harap lengkapi Posisi',
                    })
                } else {
                    this.onUpdate()
                }
    
            } else {
    
                MySwal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: 'Harap lengkapi Url ',
                })
    
            }
    
    
        } else if (this.state.type === "Page") {
    
            if (this.state.page !== "Empty") {
                if (this.state.position === "Empty" && filter.length === 0) {
                    this.onUpdate()
                } else if (this.state.position === "Empty" && filter.length !== 0) {
                    MySwal.fire({
                        icon: 'info',
                        title: 'Oops...',
                        text: 'Harap lengkapi Posisi',
                    })
                } else {
                    this.onUpdate()
                }
    
            } else {
    
                MySwal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: 'Harap lengkapi Page ',
                })
    
            }
    
        }
    
    
    }

    onCheckposition = e => {
        e.preventDefault();
        var filter = this.state.list.filter(one => (one.level1 === true));
        if (this.state.type === "Empty") {
            if (this.state.position === "Empty" && filter.length === 0) {
                this.onSubmit()
            } else if (this.state.position === "Empty" && filter.length !== 0) {
                MySwal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: 'Harap lengkapi Posisi',
                })
            } else {
                this.onSubmit()
            }

        } else if (this.state.type === "URL Path") {

            if (this.state.url !== "Empty") {
                if (this.state.position === "Empty" && filter.length === 0) {
                    this.onSubmit()
                } else if (this.state.position === "Empty" && filter.length !== 0) {
                    MySwal.fire({
                        icon: 'info',
                        title: 'Oops...',
                        text: 'Harap lengkapi Posisi',
                    })
                } else {
                    this.onSubmit()
                }

            } else {

                MySwal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: 'Harap lengkapi Url ',
                })

            }


        } else if (this.state.type === "Page") {

            if (this.state.page !== "Empty") {
                if (this.state.position === "Empty" && filter.length === 0) {
                    this.onSubmit()
                } else if (this.state.position === "Empty" && filter.length !== 0) {
                    MySwal.fire({
                        icon: 'info',
                        title: 'Oops...',
                        text: 'Harap lengkapi Posisi',
                    })
                } else {
                    this.onSubmit()
                }

            } else {

                MySwal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: 'Harap lengkapi Page ',
                })

            }

        }else{
            if (this.state.position === "Empty" && filter.length === 0) {
                this.onSubmit()
            } else if (this.state.position === "Empty" && filter.length !== 0) {
                MySwal.fire({
                    icon: 'info',
                    title: 'Oops...',
                    text: 'Harap lengkapi Posisi',
                })
            } else {
                this.onSubmit()
            }
        }


    }

    onSubmit = () => {
        const validation = this.validator.validate(this.state);
        this.setState({ validation });

        var filter = this.state.list.filter(one => (one.level1 === true));
        this.setState({ last: filter.length })
        var level1
        if (filter.length !== 0) {

            $("#urut").trigger("click");
            level1 = this.state.level1
        } else if (filter.length === 0) {
            level1 = true


        }

        var order
        if (this.state.position === "Child Of") {
            order = ''
        } else if (this.state.position !== "Child Of" && this.state.position === "Empty") {
            order = "0"
        } else {
            order = (this.state.first + 1).toString()
        }

        // var levelup 
        // if(this.state.position === "Empty" || this.state.position === "")
        // {
        //      levelup = 'level1'
        // }else{

        //     levelup = this.state.levelup

        // }

        if (validation.isValid) {
            const pageRequest = {
                level1: level1,
                level2: this.state.level2,
                level3: this.state.level3,
                levelup: this.state.levelup,
                title: this.state.title,
                parent: this.state.parent,
                relative: this.state.relative,
                position: this.state.position,
                order: order,
                page: this.state.page,
                type: this.state.type,
                url: this.state.url,
                active: this.state.active,
            }

            addToList(pageRequest).then(() => {
                $(".close").trigger("click");
                this.receivedData()
                this.check()
                if (this.state.position === "Right / After" || this.state.position === "Left / Before") {
                    this.onChangeOrder()
                    this.check()

                } else {
                    this.check()
                    this.onReset();
                }

            }).catch(err => {
                this.setState({ editDisabled: false, errorMessage: err.message })
            })
            this.setState({ editDisabled: false })
        }

    }

    onUpdate = () => {
        // e.preventDefault()
        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        $("#urut").trigger("click");
        if (validation.isValid) {
            var filter = this.state.list.filter(one => (one.level1 === true));
            this.setState({ last: filter.length })
            var order
            if (this.state.position === "Child Of") {
                order = ""
            } else {
                order = (this.state.first + 1).toString()

            }

            const pageRequest = {
                id: this.state.id,
                level1: this.state.level1,
                level2: this.state.level2,
                level3: this.state.level3,
                levelup: this.state.levelup,
                title: this.state.title,
                parent: this.state.parent,
                relative: this.state.relative,
                position: this.state.position,
                order: order,
                page: this.state.page,
                type: this.state.type,
                url: this.state.url,
                active: this.state.active,
            }

            updateItem(pageRequest).then(() => {
                this.check()
                this.receivedData();
                $(".close").trigger("click");
            }).catch(err => {
                this.setState({ isUpdate: false, errorMessage: err.message })
            })

            this.setState({ isUpdate: false })
        }
    }

    onDelete = (val, e) => {
        e.preventDefault()
        deleteItem(val).then(() => {
            this.receivedData();
            this.check()
        }).catch(err => {
            this.setState({ editDisabled: false, errorMessage: err.message })
            // this.setState({ errorMessage:err.data.message })
        })
    }

    check = () => {
        var toggler = document.getElementsByClassName("caret");
        var i;

        for (i = 0; i < toggler.length; i++) {
            toggler[i].addEventListener("click", function () {
                this.parentElement.querySelector(".nested").classList.toggle("active");
                this.classList.toggle("caret-down");
            });
        }
    }

    render() {

        return (
            <div className="page-content">

                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/dashbaord">Dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Footer Navigation</li>
                        </ol>
                    </nav>

                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Footer Navigation<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group row">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.searchTitle}
                                                    name="searchTitle"
                                                    placeholder="Cari nama ..."
                                                    onChange={this.onChange}
                                                />
                                            </div></div>
                                        <div className="col-md-4">
                                            <div className="form-group row">
                                                <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Status</label>
                                                <div className="col-sm-9">
                                                    <select className="form-control" name="searchActive" value={this.state.searchActive} onChange={this.onChange}>
                                                        <option value="">All</option>
                                                        <option value="true">Aktif</option>
                                                        <option value="false">Tidak Aktif</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group row">
                                                <button className="btn btn-primary btn-sm btn-block" data-dismiss="modal" aria-label="Close" onClick={this.receivedData.bind(this)}><i className="fas fa-search"></i> Filter</button>
                                                <button className="btn btn-primary btn-sm" style={{ display: "none" }} id="urut" onClick={this.onChangeOrder}><i className="fas fa-search"></i> before</button>

                                            </div>
                                        </div>
                                        &nbsp;&nbsp;
                                        <div className="col-md-2">
                                            <div className="form-group row">
                                                <button className="btn btn-success btn-sm btn-block" data-toggle="modal" data-target="#paycard" onClick={this.onCreate.bind(this)}><i className="fas fa-plus"></i> Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive container">

                                        {(() => {
                                            if (this.state.searchActive === "" && this.state.searchTitle === "" && this.state.isFilter === false) {
                                                return (
                                                    <>
                                                        <table className="table" id="tree-table" >
                                                            <thead>
                                                                <tr>
                                                                    <th>Title</th>
                                                                    <th>Link To</th>
                                                                    <th>Position</th>
                                                                    <th style={{ width: "240px" }}>Position Relative To</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                        {this.state.data
                                                            .filter(tree => tree.level1 === true)
                                                            .map(function (one, induk) {
                                                                return <>

                                                                    <li><span class="caret">
                                                                        <tr className="application-item" data-id={one.id} data-parent={0} data-level={one.id} data-width="1" key={induk}>
                                                                            <td style={{ width: "100px" }}>
                                                                                {one.level1 === true ? <> {one.title}</> : null}
                                                                            </td>
                                                                            <td style={{ width: "170px" }}> {one.type}</td>
                                                                            <td style={{ width: "250px" }}> {one.position}</td>
                                                                            <td style={{ width: "204px" }}> Empty </td>
                                                                            <td style={{ width: "140px" }}>
                                                                                {one.active === true || one.active === "on" ?
                                                                                    <div className="productText">
                                                                                        Active
                                                                                    </div>
                                                                                    : null}
                                                                                {one.active === false || one.active === "" ?
                                                                                    <div className="productText">
                                                                                        Nonactive
                                                                                    </div>
                                                                                    : null} </td>
                                                                            <td><button className="btn btn-danger btn-sm mb-3 " style={{ width: "50px" }} onClick={this.onDelete.bind(this, one.id)} ><i className="fas fa-trash" ></i></button>&nbsp;&nbsp;
                                                                                <button className="btn btn-warning btn-sm mb-3" style={{ width: "50px" }} data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, one.id, one.title, one.parent, one.level1, one.level2, one.level3, one.levelup, one.type, one.url, one.relative, one.active, one.position, one.page)}><i className="fas fa-edit" ></i></button>
                                                                            </td>
                                                                        </tr>


                                                                    </span>
                                                                        <ul class="nested">
                                                                            {this.state.child
                                                                                .filter(two => two.level2 === true && two.parent === one.id)
                                                                                .map(function (two, index) {
                                                                                    return <>
                                                                                        <li key={induk}><span class="caret">
                                                                                            <tr className="application-item" data-id={two.id} data-parent={one.id} data-level={two.id} data-width="2" key={index}>
                                                                                                <td style={{ width: "100px" }}>
                                                                                                    &nbsp;{two.title}
                                                                                                </td>
                                                                                                <td style={{ width: "140px" }}> {two.type}</td>
                                                                                                <td style={{ width: "250px" }}> {two.position}</td>
                                                                                                <td style={{ width: "210px" }}> {one.title} </td>
                                                                                                <td style={{ width: "140px" }}>
                                                                                                    {two.active === true || two.active === "on" ?
                                                                                                        <div className="productText">
                                                                                                            Active
                                                                                                        </div>
                                                                                                        : null}
                                                                                                    {two.active === false || two.active === "" ?
                                                                                                        <div className="productText">
                                                                                                            Nonactive
                                                                                                        </div>
                                                                                                        : null}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <button className="btn btn-danger btn-sm mb-3" style={{ width: "50px" }} onClick={this.onDelete.bind(this, two.id)} ><i className="fas fa-trash" ></i></button>&nbsp;&nbsp;
                                                                                                    <button className="btn btn-warning btn-sm mb-3" style={{ width: "50px" }} data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, two.id, two.title, two.parent, two.level1, two.level2, two.level3, two.levelup, two.type, two.url, two.relative, two.active, two.position, two.page)}><i className="fas fa-edit" ></i></button>
                                                                                                </td>
                                                                                            </tr>

                                                                                        </span>
                                                                                            <ul class="nested">
                                                                                                {this.state.child
                                                                                                    .filter(tree => tree.level3 === true && tree.parent === two.id)
                                                                                                    .map(function (tree, idx) {

                                                                                                        return <Fragment>
                                                                                                            <tr className="application-item" data-id={idx + 3} data-parent={two.id} data-level={idx + 3} data-width="3" key={idx}>
                                                                                                                <td style={{ width: "100px" }}>
                                                                                                                    {tree.title}
                                                                                                                </td>
                                                                                                                <td style={{ width: "120px" }}> {tree.type}</td>
                                                                                                                <td style={{ width: "250px" }}> {tree.position}</td>
                                                                                                                <td style={{ width: "210px" }}> {two.title} </td>
                                                                                                                <td style={{ width: "140px" }}>
                                                                                                                    {tree.active === true || tree.active === "on" ?
                                                                                                                        <div className="productText">
                                                                                                                            Active
                                                                                                                        </div>
                                                                                                                        : null}
                                                                                                                    {tree.active === false || tree.active === "" ?
                                                                                                                        <div className="productText">
                                                                                                                            Nonactive
                                                                                                                        </div>
                                                                                                                        : null}
                                                                                                                </td>
                                                                                                                <td><button className="btn btn-danger btn-sm mb-3" style={{ width: "50px" }} onClick={this.onDelete.bind(this, tree.id)} ><i className="fas fa-trash" ></i></button>&nbsp;&nbsp;
                                                                                                                    <button className="btn btn-warning btn-sm mb-3" style={{ width: "50px" }} data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, tree.id, tree.title, tree.parent, tree.level1, tree.level2, tree.level3, tree.levelup, tree.type, tree.url, tree.relative, tree.active, tree.position, tree.page)}><i className="fas fa-edit" ></i></button>
                                                                                                                </td>
                                                                                                            </tr></Fragment>;

                                                                                                    }.bind(this))}
                                                                                            </ul>
                                                                                        </li>
                                                                                    </>

                                                                                }.bind(this)
                                                                                )}
                                                                        </ul>
                                                                    </li>


                                                                </>;
                                                            }.bind(this))}
                                                    </>
                                                )
                                            } else if (this.state.isFilter === true) {


                                                return (
                                                    <table className="table" id="tree-table" >
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Link To</th>
                                                                <th>Position</th>
                                                                <th style={{ width: "240px" }}>Position Relative To</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {this.state.data
                                                                .map((one, index) => (
                                                                    <tr className="application-item" key={index}>
                                                                        <td > {one.title}</td>
                                                                        <td > {one.type}</td>
                                                                        <td > {one.position}</td>
                                                                        <td > {one.relative} </td>
                                                                        <td>
                                                                            {one.active === true || one.active === "on" ?
                                                                                <div className="productText">
                                                                                    Active
                                                                                </div>
                                                                                : null}
                                                                            {one.active === false || one.active === "" ?
                                                                                <div className="productText">
                                                                                    Nonactive
                                                                                </div>
                                                                                : null}</td>
                                                                        <td>
                                                                            <button className="btn btn-danger btn-sm " style={{ width: "50px" }} onClick={this.onDelete.bind(this, one.id)} ><i className="fas fa-trash" ></i></button>&nbsp;&nbsp;
                                                                            <button className="btn btn-warning btn-sm mb-3" style={{ width: "50px" }} data-toggle="modal" data-target="#paycard" onClick={this.onEdit.bind(this, one.id, one.title, one.parent, one.level1, one.level2, one.level3, one.levelup, one.type, one.url, one.relative, one.active, one.position, one.page)}><i className="fas fa-edit" ></i></button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                )
                                            }
                                        })()}

                                        <ReactPaginate
                                            previousLabel={<i className="fa fa-angle-left"></i>}
                                            nextLabel={<i className="fa fa-angle-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-end asrt-pagination"}
                                            subContainerClassName={"page pagination"}
                                            activeClassName={"active"} />
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.headertitle}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">Title</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.title}
                                                    name="title"
                                                    onChange={this.onChange}
                                                />
                                                <span className="help-block" style={style}>{this.state.validation.title.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="title">Link To</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <select className="form-control" name="type" id="type" value={this.state.type} onChange={this.onChange}>
                                                    <option value="Empty">Empty</option>
                                                    <option value="URL Path">URL Path</option>
                                                    <option value="Page">Page</option>
                                                </select>
                                                {/* <span className="help-block" style={style}>{this.state.validation.type.message}</span> */}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">URL Path</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    id="url"
                                                    className="form-control"
                                                    value={this.state.url}
                                                    name="url"
                                                    onChange={this.onChange}
                                                />
                                                <span className="help-block" style={style}>{this.state.validation.url.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="title">Page</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <select className="form-control" name="page" id="page" value={this.state.pege} onChange={this.onChange}>
                                                    <option value="Empty">Empty</option>
                                                    {this.state.listpages.map((item) => (
                                                        <option value={item.id}>{item.title}</option>
                                                    ))}
                                                </select>
                                                <span className="help-block" style={style}>{this.state.validation.page.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="title">Position</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <select className="form-control" name="position" id="position" value={this.state.position} onChange={this.onChange}>
                                                    <option value="Empty">Empty</option>
                                                    <option value="Child Of">Child Of</option>
                                                    <option value="Left / Before">Left / Before</option>
                                                    <option value="Right / After">Right / After</option>
                                                </select>
                                                <span className="help-block" style={style}>{this.state.validation.position.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="pare">Position Relative To</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <select className="form-control" name="parent" id="parent" value={this.state.parent} onChange={this.onChange}>
                                                    <option value="Empty">Empty</option>
                                                    {this.state.listnavigation.map((item) => (
                                                        <option value={item.id} data-uid={item.levelup} data-order={item.order}>{item.title} </option>
                                                    ))}
                                                </select>
                                                {/* <span className="help-block" style={style}>{this.state.validation.parent.message}</span> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="kd_promo"></label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" name="active" checked={this.state.active} onChange={this.handleInputChange} type="checkbox" id="autoSizingCheck" />
                                                    <label className="form-check-label" htmlFor="autoSizingCheck">
                                                        Active
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button className="btn btn-primary btn-block" onClick={this.onCheckpositionUpdate.bind(this)}
                                        style={this.state.isUpdate ? {} : { display: 'none' }}
                                    >
                                        Update
                                    </button>
                                    <button type="submit" onClick={this.onCheckposition.bind(this)} className="btn btn-success btn-block"
                                        style={this.state.isUpdate ? { display: 'none' } : {}}
                                    >
                                        Submit
                                    </button>
                                    <br></br>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        )
    }
}
