import React, { Component } from "react";
import Chart from "react-apexcharts";
import { getList } from "../../actions/eventActions";
import { getListdashboard as job } from "../../actions/jobAction";
import { getListuser as member } from "../../actions/userActions";
import { getList as profession } from "../../actions/professionAction";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: "",
      jobs: "",
      member: "",
      level:false,
      options: {
        chart: {
          id: "area-datetime",
          zoom: false,
        },
        xaxis: {
          categories: [],
        },
      },
      optionspie: {
        chart: {
          width: "100%",
          type: "pie",
        },
        legend: {
          fontSize: "10px"
        },
        
        labels: [ 
        ],
         
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },

              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      optionspie2: {
        chart: {
          width: "100%",
          height: "100%",
          type: "pie",
        },
        legend: {
          fontSize: "10px"
        },
        labels: [
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },

              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      optionspie3: {
        chart: {
          width: "100%",
          type: "pie",
        },
        legend: {
          fontSize: "10px"
        },
        labels: [
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },

              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      seriespie2: [],
      seriespie3: [],
      seriespie: [],

      series: [
        {
          name: "event",
          data: [],
        },
      ],
    };
  }
  componentDidMount() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo && userInfo["data"]["user"]["role"] === "ADMIN") {
      this.props.history.push("/dashboard");
      this.receivedData();
      this.setState({});
    } else {
      localStorage.removeItem("userInfo");
      this.props.history.push("/login");
    }
  }

  receivedData() {
    getList().then((res) => {
      let names = [];
      let datas = [];
      const data = res["data"] ? res["data"] : [];
      for (var i = 0; i < data.length; i++) {
        names.push("event " + data[i]["date"]);
        datas.push(data[i]["paticipans"].length);
        // console.log(data[0]['paticipans'].length)
      }
      // console.log(datas)
      this.setState({
        event: data.length,
        options: {
          xaxis: {
            categories: names,
          },
        },
        series: [
          {
            name: "event",
            data: datas,
          },
        ],
      });
    });

    let label1 = ["undefined"];
    let label2 = ["undefined"];
    let label3 = ["undefined"];
    let data1 = [];
    let data2 = [];
    let data3 = [];
    

    profession().then((res) => {
      const data = res["data"] ? res["data"] : [];
      const result = data.filter((item) => item.level1 === true);
      for (var i = 0; i < result.length; i++) {
        label1.push(result[i]["name"]);
      }

      const result2 = data.filter((item) => item.level2 === true);
      for (var x = 0; x < result2.length; x++) {
        label2.push(result2[x]["name"]);
      }

      const result3 = data.filter((item) => item.level3 === true);
      for (var y = 0; y < result3.length; y++) {
        label3.push(result3[y]["name"]);
      }
    });

    job().then((res) => {
      const data = res["data"] ? res["data"] : [];
      this.setState({ jobs: data.length });
    });
    member().then((res) => {
      const data = res["data"] ? res["data"] : [];
      for (let index = 0; index < label1.length; index++) {
        let label;
        if (label1[index] === "undefined") {
          label = "-";
        } else {
          label = label1[index];
        }
        const check1 = data.filter((item) => item.profesi === label);
        data1.push(check1.length);
        label1[index] = label1[index]+' <br> total '+check1.length+ ' = '+parseFloat((check1.length/data.length * 100).toFixed(1))+'%';

      }
      for (let index = 0; index < label2.length; index++) {
        let label;
        if (label2[index] === "undefined") {
          label = "-";
        } else {
          label = label2[index];
        }
        const check2 = data.filter((item) => item.spesialisasi === label);
        data2.push(check2.length);
        label2[index] = label2[index]+' <br> total '+check2.length+ ' = '+parseFloat((check2.length/data.length * 100).toFixed(1))+'%';

      }
      for (let index = 0; index < label3.length; index++) {
        let label;
        if (label3[index] === "undefined") {
          label = "-";
        } else {
          label = label3[index];
        }
        const check3 = data.filter((item) => item.consltant === label);
        data3.push(check3.length);
        label3[index] = label3[index]+' <br> total '+check3.length+ ' = '+parseFloat((check3.length/data.length * 100).toFixed(1))+'%';
      }
      this.setState({ member: data.length });
    });
   
          this.setState({
            optionspie: {
              labels: label1, 
            },
            seriespie: data1,
            optionspie2: {              
              labels: label2,

            },
            seriespie2: data2,

            optionspie3: {             
              labels: label3,

            },
            seriespie3: data3,
          });
          setInterval(() => {
            if (label1.length !== 0 && label2.length !== 0 && label3.length !== 0 && data1.length !== 0 && data2.length !== 0 && data3.length !== 0) { 
                this.show()
            }
          }, 1000);
          
        
      }
      
show(){
  this.setState({level:true})
}
  render() {
    return (
      <div className="page-content">
        <div className="page-info">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/#">Apps</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard
              </li>
            </ol>
          </nav>
        </div>
        <div className="main-wrapper">
          <div className="row stats-row">
            <div className="col-lg-4 col-md-12">
              <div className="card card-transparent stats-card">
                <div className="card-body">
                  <div className="stats-info">
                    <h5 className="card-title">{this.state.event}</h5>
                    <p className="stats-text">Total Event</p>
                  </div>
                  <div className="stats-icon change-success">
                    <i className="material-icons">trending_up</i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card card-transparent stats-card">
                <div className="card-body">
                  <div className="stats-info">
                    <h5 className="card-title">{this.state.jobs}</h5>
                    <p className="stats-text">Total job opportunity</p>
                  </div>
                  <div className="stats-icon change-success">
                    <i className="material-icons">trending_up</i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card card-transparent stats-card">
                <div className="card-body">
                  <div className="stats-info">
                    <h5 className="card-title">{this.state.member}</h5>
                    <p className="stats-text">Total member</p>
                  </div>
                  <div className="stats-icon change-success">
                    <i className="material-icons">trending_up</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card savings-card">
                <div className="card-body">
                  <h5 className="card-title">Event</h5>
                  <div className="savings-stats">
                    <span>Total participant/event</span>
                  </div>
                  <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height={350}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12" style={this.state.level ? {} : { display: 'none' }}>
              <div className="card savings-card">
                <div className="card-body">
                  <h5 className="card-title">Profession</h5>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="savings-stats">
                        <span>Level 1</span>
                      </div>
                      <Chart
                        options={this.state.optionspie}
                        series={this.state.seriespie}
                        type="pie"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="savings-stats">
                        <span>Level 2</span>
                      </div>
                      <Chart
                        options={this.state.optionspie2}
                        series={this.state.seriespie2}
                        type="pie"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="savings-stats">
                        <span>Level 3</span>
                      </div>
                      <Chart
                        options={this.state.optionspie3}
                        series={this.state.seriespie3}
                        type="pie"

                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}