import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/ErrorMessage";
import { resetPasswordAction } from "../../actions/userActions";


function Forgetpassword({ history }) {
    const [password, setPassword] = useState("");
    const [konfirmasi, setKonfirmasi] = useState("");

    const dispatch = useDispatch();

    const resetPassword = useSelector((state) => state.resetPassword);
    const { loading, error, success, userInfo } = resetPassword;
    useEffect(() => {
        if (userInfo) {
            history.push("/");
            // window.location.href = '/';
        }
    }, [history, userInfo]);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const query = params.get('digihealth');
    var id;
    const submitHandler = (e) => {
        e.preventDefault();
        if (query !== null) {
            id = query
        } else {
            id = 'digihealth'
        }
        dispatch(resetPasswordAction(id, password, konfirmasi));
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-5">
                    <div className="auth-form">
                        <div className="row">
                            <div className="col">
                                <div className="logo-box"><a href="/#" className="logo-text">Reset Password</a></div>
                                {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
                                {success && <ErrorMessage variant="warning">{success}</ErrorMessage>}
                                <form onSubmit={submitHandler}>
                                    <div className="form-group">
                                        <input placeholder="New Password" name="password"
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <input placeholder="Konfirmasi New Password" name="konfirmasi"
                                            type="password"
                                            value={konfirmasi}
                                            onChange={(e) => setKonfirmasi(e.target.value)} />
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block btn-submit">Submit</button>
                                </form>
                                {loading && <Loading />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 d-none d-lg-block d-xl-block">
                    <div className="auth-image" />
                </div>
            </div>
        </div>

    )
}
export default Forgetpassword;
