import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import { updateUserAction, upload, deletecontent, getUserbyid } from "../../actions/userActions";
import { getList } from "../../actions/professionAction";
import { getListbyuser, updateItem } from "../../actions/eventActions";
import $ from 'jquery';
import Swal from 'sweetalert2'
import MenuMember from "../../components/MenuMember";
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

function Edit({ history }) {
    const dispatch = useDispatch();
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [gelardepan, setGelardepan] = useState("");
    const [gelarbelakang, setGelarbelakang] = useState("");
    const [tlp, setTlp] = useState("");
    const [nip, setNip] = useState("");
    const [email, setEmail] = useState("");
    const [str, setStr] = useState("");
    const [verifikasi, setVerifikasi] = useState("");
    const [npkidi, setNpkidi] = useState("");
    const [nik, setNik] = useState("");
    const [tempatlahir, setTempatlahir] = useState("");
    const [tanggallahir, setTanggallahir] = useState("");
    const [profesi, setProfesi] = useState("-");
    const [spesialisasi, setSpesialisasi] = useState("-");
    const [consltant, setConsltant] = useState("-");
    const [role, setRole] = useState("");
    const [profile, setProfile] = useState("");
    const [address, setAddress] = useState([]);
    const [education, setEducation] = useState([{ mulai: "", lulus: "", institusi: "", jenjang: "" }]);
    const [jobs, setJobs] = useState([{ mulai: "", berhenti: "", institusi: "", jabatan: "" }]);
    const userUpdate = useSelector((state) => state.userUpdate);
    const userLogin = useSelector((state) => state.userLogin);
    const [listspelialisasi, setListspelialisasi] = useState([]);
    const [listconsultant, setListconsultant] = useState([]);
    const [listpropesi, setListpropesi] = useState([]);
    const [event, setEvent] = useState([]);
    const [sertifikat, setSertifikat] = useState([]);
    const [cv, setCv] = useState("");
    const [strfile, setStrfile] = useState("");
    const [bank, setBank] = useState("");
    const bucket = process.env.REACT_APP_API_KEY

    const {
        loading: loadingUpdate,
        success: successUpdate,
    } = userUpdate;
    const { userInfo } = userLogin;
    const prop = () => {
        getList().then(res => {
                const data = res['data'] ? res['data'] : [];
                const filterData = data.filter(item => (true ? item.level1 === true : item.level1) &&  (item.active === true))
                setListpropesi([...filterData])
        })
    }
    const getevents = () => {
        var member = JSON.parse(localStorage.getItem('recentmember'));
        var id = member[0]['id'];
        getListbyuser(id).then(res => {
           const data = res['data'] ? res['data'] : [];
        //    let newDate = new Date()
        //    let date = newDate.getDate();
        //    let month = newDate.getMonth() + 1;
        //    let year = newDate.getFullYear();
        //    let separator = "-"
        //    let datenow = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
        //    const filterData = data.filter(item => item.date > datenow)
           setEvent([...data])

        })
    }
    const handleChange = (evt) => {
        var index = evt.target.selectedIndex;
        var optionElement = evt.target.childNodes[index]
        var option =  optionElement.getAttribute('data-order');
        setSpesialisasi("-");
        setConsltant("-");
        filterspesialisasi(option)
        setProfesi(evt.target.value)
      }

      const handleChangeconsul = (evt) => {
        var index = evt.target.selectedIndex;
        var optionElement = evt.target.childNodes[index]
        var option =  optionElement.getAttribute('data-order');
        filterlistconsultant(option)
        setSpesialisasi(evt.target.value)
      }
 
    const filterspesialisasi = (key) => {
        if(key !== null){
            getList().then(res => {
                const data = res['data'] ? res['data'] : [];
                const filterData = data.filter(item => (key ? item.parent === key : []) &&  (item.active === true))
                    setListspelialisasi([...filterData])
                    setListconsultant([])
            })
        }else{
            setListspelialisasi([])
            setListconsultant([])
        }
    }
    const filterlistconsultant = (key) => {
        if(key !== null){
        getList().then(res => {
                const data = res['data'] ? res['data'] : [];
                const filterData = data.filter(item => (key ? item.parent === key : item.parent) &&  (item.active === true))
                setListconsultant([...filterData])
        })
        }else{
            setListconsultant([]) 
        }
    }
    useEffect(() => {
        var prod = JSON.parse(localStorage.getItem('recentmember'));
        getUserbyid(prod[0]['id']).then(result =>{
        prop();
        getevents();
        setId(result.data.id);
        setName(result.data.name);
        setGelardepan(result.data.gelardepan);
        setGelarbelakang(result.data.gelarbelakang);
        setTlp(result.data.tlp);
        setEmail(result.data.email);
        setNip(result.data.nip);
        setStr(result.data.str);
        setNpkidi(result.data.npkidi);
        setNik(result.data.nik);
        setTempatlahir(result.data.tempatlahir);
        setTanggallahir(result.data.tanggallahir);
        setProfesi(result.data.profesi);
        setSpesialisasi(result.data.spesialisasi);
        setConsltant(result.data.consltant);
        setVerifikasi(result.data.verifikasi);
        setCv(result.data.cv);
        setStrfile(result.data.strfile);
        setBank(result.data.bank);
        setRole(result.data.role);
        setProfile(result.data.profile);
        getList().then(res => {
            const data = res['data'] ? res['data'] : [];
            const filterData = data.filter(item => (result.data.profesi ? item.name === result.data.profesi : []) &&  (item.active === true) && (item.level1 === true))
           if(filterData.length !== 0){ filterspesialisasi(filterData[0]['id'])}
        })
        getList().then(res => {
            const data = res['data'] ? res['data'] : [];
            const filterData = data.filter(item => (result.data.spesialisasi ? item.name === result.data.spesialisasi : []) &&  (item.active === true) && (item.level2 === true))
            if(filterData.length !== 0){ filterlistconsultant(filterData[0]['id'])}
        })
        if(result.data.address.length !== 0){
            setAddress(result.data.address);
        }
        if(result.data.education.length !== 0){
            setEducation(result.data.education);
        }
        if(result.data.jobs.length !== 0){
            setJobs(result.data.jobs);
        }
        if(result.data.sertifikat !== null){
            setSertifikat(result.data.sertifikat);
        }
    })
        
        if (userInfo) {
            history.push("/memberdetailprofile");
          }else{
            history.push("/login");
          }
    }, [
        history,
        userInfo,
        successUpdate
    ]);

    const resetHandler = () => {
        setId("");
        setName("");
        setGelardepan("");
        setGelarbelakang("");
        setTlp("");
        setEmail("");
        setNip("");
        setStr("");
        setNpkidi("");
        setNik("");
        setTempatlahir("");
        setTanggallahir("");
        setProfesi("-");
        setSpesialisasi("-");
        setConsltant("-");
        setRole("");
        setAddress([{ domisili: "", alamat: "", provinsi: "", kabupaten: "", kecamatan: "", kelurahan: "" }]);
        setEducation([{ mulai: "", lulus: "", institusi: "", jenjang: "" }]);
        setJobs([{ mulai: "", berhenti: "", institusi: "", jabatan: "" }]);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateUserAction(role, id, name, email, gelardepan, gelarbelakang, tlp, nip, str, npkidi, nik, tempatlahir, tanggallahir, profesi, spesialisasi, consltant, address, education, jobs, strfile, bank, cv, sertifikat, verifikasi, profile ));
        var member = JSON.parse(localStorage.getItem('recentmember'));
        var idmember = member[0]['id'];
        event.map((value) => {
            var data = [...value.paticipans]
            for (let index = 0; index < data.length; index++) {
                if(idmember === data[index]['idmember']){
                data[index]["nama"] = name;
                data[index]["gelarbelakang"] = gelarbelakang;
                data[index]["gelardepan"] = gelardepan;
                data[index]["email"] = email;
                data[index]["tlp"] = tlp;

                const UpdateRequest = {
                    id: value.id,
                    paticipans: data,
                    speakers: value.speakers,
                }
                updateItem(UpdateRequest)
            }
                
            }
            data = []
            // console.log(UpdateRequest)

            return value;
        })
        if (!name) return;
        resetHandler();
        history.push("/memberlist");
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...education];
        list[index][name] = value;
        setEducation(list);
    };
    const handleInputChangetwo = (e, index) => {
        const { name, value } = e.target;
        const list = [...jobs];
        list[index][name] = value;
        setJobs(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...education];               
        if (list[index]['ijazah'] !== undefined) {
                const deleteRequest = {
                    path: bucket+'/ijazah',
                    file: list[index]['ijazah']
                }
                deletecontent(deleteRequest)
            }
        list.splice(index, 1);
        setEducation(list);
    };
    const handleClick = (i) => {
         $("#custom"+i).trigger("click");
    }
    const handleDeleteProfile = () => {
       if (profile !== null || profile !== undefined || profile !== "-")
       {
            document.getElementById("profile").src = "http://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/profile/2263a021-a46c-4bf1-9210-e47dada02735.png"
            const deleteRequest = {
                path: bucket+'/profile',
                file: profile
            }
           deletecontent(deleteRequest)
           setProfile("-");
        } 
    }
    const handleUploadProfile = (pics) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png")) {
             document.getElementById("uploadprofile").classList.remove("fa-upload");
             document.getElementById("uploadprofile").classList.add("fa-spinner", "fa-spin");
            if (profile !== null || profile !== undefined || profile !== "-")
            {
               const deleteRequest = {
                    path: bucket+'/profile',
                    file: profile
                }
                deletecontent(deleteRequest)
            }

            const profileRequest = {
                path: bucket+'/profile',
                myFile: pics
            }
            upload(profileRequest).then((res) => {
                
                document.getElementById("uploadprofile").classList.remove("fa-spinner", "fa-spin");
                document.getElementById("uploadprofile").classList.add("fa-upload");
                setProfile(res.data);
                document.getElementById("profile").src = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/profile/"+res.data

            }).catch((err) => {
                document.getElementById("uploadprofile").classList.remove("fa-spinner", "fa-spin");
                document.getElementById("uploadprofile").classList.add("fa-upload");
                MySwal.fire('Oops...', 'sorry server busy please tray again' , 'info')
            })

        } else {

            document.getElementById("uploadprofile").classList.remove("fa-spinner", "fa-spin");
            document.getElementById("uploadprofile").classList.add("fa-upload");
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
            
        }
    }

    const handleUploadClick = (pics, index) => {
        if (pics.size < 5100000 && (pics.type === "image/jpeg" || pics.type === "image/png" || pics.type === "application/pdf")) {
            document.getElementById("upload"+index).classList.remove("fa-upload");
            document.getElementById("upload" + index).classList.add("fa-spinner", "fa-spin");
            const list = [...education];
           if (list[index]['ijazah'] !== undefined) {
                const deleteRequest = {
                    path: bucket+'/ijazah',
                    file: list[index]['ijazah']
                }
                deletecontent(deleteRequest)
            }
            
            const ijazahRequest = {
                path: bucket+'/ijazah',
                myFile: pics
            }
            upload(ijazahRequest).then((res) => {
                
                document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
                document.getElementById("upload"+index).classList.add("fa-upload");
                const list = [...education];
                list[index]['ijazah'] = res.data
                setEducation(list);
                document.getElementById("preview"+index).href = "https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/ijazah/"+res.data

            }).catch((err) => {
                document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
                document.getElementById("upload"+index).classList.add("fa-upload");
                MySwal.fire('Oops...', 'sorry server busy please tray again' , 'info')
            })

            
        } else {
            document.getElementById("upload"+index).classList.remove("fa-spinner", "fa-spin");
            document.getElementById("upload"+index).classList.add("fa-upload");
            MySwal.fire('Oops...', 'Mohon maaf sepertinya type atau ukuran file yang anda masukan tidak cocok', 'info')
        }
    };

    const handleRemoveClicktwo = index => {
        const list = [...jobs];
        list.splice(index, 1);
        setJobs(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setEducation([...education, { mulai: "", lulus: "", institusi: "", jenjang: "", ijazah:"-" }]);
    };
    const handleAddClicktwo = () => {
        setJobs([...jobs, { mulai: "", berhenti: "", institusi: "", jabatan: "" }]);
    };

    return (


<div className="page-content">

<div className="page-info">
    <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/memberlist">Member</a></li>
            <li className="breadcrumb-item active" aria-current="page">Detail Member</li>
        </ol>
    </nav>
</div>
<div className="main-wrapper">
    <div className="row">
        <div className="col-lg-12">
            <div className="card card-transactions">
                <div className="card-body">
                    <h5 className="card-title">Member Management<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                    <MenuMember />
                    <hr/>
                    <form onSubmit={submitHandler}>
                        <div className="row mb-30">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <strong className="mb-5">Profile Umum</strong><br/><br/>
                                        <div className="form-group row">
                                                <div className="col-sm-2">
                                                    {(() => {
                                                        if (profile === undefined || profile === "-") {
                                                            return (
                                                                <><img src="http://prosehat-digihealth.s3.us-east-2.amazonaws.com/prod/profile/2263a021-a46c-4bf1-9210-e47dada02735.png" id="profile" alt="profile" className="rounded-circle" height="100px" /></>
                                                            )
                                                        } else if (profile !== undefined || profile !== "-") {
                                                            return (
                                                                <><img src={"http://prosehat-digihealth.s3.us-east-2.amazonaws.com/" + bucket + "/profile/" + profile} id="profile" alt="profile" className="rounded-circle" height="100px" /></>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                            <input
                                                onChange={(e) => handleUploadProfile(e.target.files[0])}
                                                id="custom-file-profile"
                                                type="file"
                                                name="file"
                                                label="Upload Profile Picture"
                                                style={{display: "none"}}
                                            />
                                            <div className="col-sm-4 py-4">
                                                <button type="button" className="btn btn-outline-primary" onClick={() =>  $("#custom-file-profile").trigger("click")}><i className="fas fa-cloud-upload-alt" id="uploadprofile"></i> Upload</button>&nbsp;&nbsp;
                                                <button type="button" className="btn btn-outline-danger" onClick={() => handleDeleteProfile()}> <i className="fas fa-trash-alt" id="deleteprofile"></i> Remove</button>
                                                <br/><span style={{fontSize:"6px"}}>Makismal ukuruan file 5mb dengan format JPG/PNG</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Nama Lengkap <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="hidden"
                                                        className="form-control"
                                                        value={id}
                                                        name="id"
                                                        placeholder="Enter the Link"
                                                        onChange={(e) => setId(e.target.value)}
                                                    />
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={name}
                                                        name="name"
                                                        placeholder="Enter the Link"
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Gelar Depan <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={gelardepan}
                                                        name="gelardepan"
                                                        placeholder="Enter the Link"
                                                        onChange={(e) => setGelardepan(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Gelar Belakang <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={gelarbelakang}
                                                        name="gelarbelakang"
                                                        placeholder="Enter the Link"
                                                        onChange={(e) => setGelarbelakang(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Email <span>*</span></label>
                                                <div className="col-sm-5">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        value={email}
                                                        readOnly={true}
                                                        name="email"
                                                        placeholder="Enter the Link"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-sm-5">
                                                {verifikasi === "true"?
                                                <div className="col-form-label">
                                                    Akun Sudah Terverifikasi
                                                </div>
                                                : null}
                                                {verifikasi === "false"?
                                                <div className="col-form-label">
                                                    Akun Belum Terverifikasi
                                                </div>
                                                : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Nomor Telpon <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={tlp}
                                                        name="tlp"
                                                        placeholder="Enter the Link"
                                                        onChange={(e) => setTlp(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <strong className="mb-1">Profile Pribadi</strong>
                                        <br/><br/>
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">NIP <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={nip}
                                                        name="nip"
                                                        placeholder="Enter NIP"
                                                        onChange={(e) => setNip(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">STR <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={str}
                                                        name="str"
                                                        placeholder="Enter the Event Title"
                                                        onChange={(e) => setStr(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">NPA IDI <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={npkidi}
                                                        name="npkidi"
                                                        placeholder="Enter the Event Date"
                                                        onChange={(e) => setNpkidi(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">NIK / Passport <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={nik}
                                                        name="nik"
                                                        placeholder="Enter the Event Time"
                                                        onChange={(e) => setNik(e.target.value)}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Tempat Lahir <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={tempatlahir}
                                                        name="tempatlahir"
                                                        placeholder="Enter the Event Moderator"
                                                        onChange={(e) => setTempatlahir(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Tanggal Lahir <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={tanggallahir}
                                                        name="tanggallahir"
                                                        placeholder="Enter the Event Moderator"
                                                        onChange={(e) => setTanggallahir(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Profesi <span>*</span></label>
                                                <div className="col-sm-10">
                                                     <select className="form-control" name="profesi" value={profesi}  onChange={handleChange}>
                                                        <option value="">All</option>
                                                        {listpropesi
                                                        .map((one, index) => (
                                                            <option value={one.name} data-order={one.id} key={index}>{one.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Spesialisasi <span>*</span></label>
                                                <div className="col-sm-10">
                                                    <select className="form-control" name="spesialisasi" value={spesialisasi}   onChange={handleChangeconsul}>
                                                        <option>All</option>
                                                        {listspelialisasi
                                                        .map((one, index) => (
                                                            <option value={one.name} data-order={one.id} key={index}>{one.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                <label htmlFor="first-name" className="col-sm-2 col-form-label">Sub-Spesialisasi <span>*</span></label>
                                                <div className="col-sm-10">
                                                     <select className="form-control" name="consltant" value={consltant} onChange={(e) => setConsltant(e.target.value)}>
                                                        <option>All</option>
                                                        {listconsultant
                                                        .map((one, index) => (
                                                            <option value={one.name} data-order={one.id} key={index}>{one.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                {/* <label htmlFor="first-name" className="col-sm-6 col-form-label">Riwayat Pendidikan <span>*</span></label> */}
                                                <strong className="mb-1">Riwayat Pendidikan</strong>
                                                <div className="col-sm-12 alert alert-success">
                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Tahun Mulai <span>*</span></label>
                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Tahun Lulus <span>*</span></label>
                                                    <label htmlFor="first-name" className="col-sm-3 col-form-label">Institusi Pendidikan <span>*</span></label>
                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Jenjang Pendidikan <span>*</span></label>
                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Dokumen Ijazah <span>*</span></label>

                                                    {education.map((x, i) => {
                                                        return (
                                                            <div className="box row" key={i}>
                                                                <div className="col-sm-2">
                                                                    <input
                                                                        name="mulai"
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Tahun Mulai"
                                                                        value={x.mulai}
                                                                        onChange={e => handleInputChange(e, i)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <input
                                                                        name="lulus"
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Tahun Lulus "
                                                                        value={x.lulus}
                                                                        onChange={e => handleInputChange(e, i)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <input
                                                                        name="institusi"
                                                                        className="form-control"
                                                                        placeholder="Institusi Pendidikan "
                                                                        value={x.institusi}
                                                                        onChange={e => handleInputChange(e, i)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <select className="form-control" name="jenjang"  value={x.jenjang}  onChange={e => handleInputChange(e, i)}>
                                                                        <option value="">All</option>
                                                                        <option value="TK">TK</option>
                                                                        <option value="SD">SD</option>
                                                                        <option value="SMP">SMP</option>
                                                                        <option value="SMA">SMA</option>
                                                                        <option value="D1">D1</option>
                                                                        <option value="D2">D2</option>
                                                                        <option value="D2">D2</option>
                                                                        <option value="D3">D3</option>
                                                                        <option value="D4">D4</option>
                                                                        <option value="S1">S1</option>
                                                                        <option value="S2">S2</option>
                                                                        <option value="S3">S3</option>
                                                                    </select>
                                                                </div>
                                                                <div>
                                                                    {x.ijazah !== "-" && <><a href={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/ijazah/"+x.ijazah} target="_blank" rel="noreferrer" id={"preview" + i} className="btn btn-primary btn-sm mb-2" ><i className="fas fa-image"></i></a>&nbsp;</>}
                                                                    <input
                                                                    className="btn btn-info btn-sm mb-2"
                                                                    onChange={(e) => handleUploadClick(e.target.files[0], i)}
                                                                    id={"custom"+i}
                                                                    type="file"
                                                                    name="file"
                                                                    style={{display: "none"}}
                                                                    label="Upload Profile Picture"
                                                                   />
                                                                    <a href="#0"  className="btn btn-info btn-sm mb-2" onClick={() => handleClick(i)}><i id={"upload"+i} className="fas fa-upload"></i></a>&nbsp;

                                                                </div>
                                                                    {education.length !== 1 &&
                                                                        <span>
                                                                        <button className="btn btn-danger btn-sm mb-2" onClick={() => handleRemoveClick(i)}><i className="fas fa-trash-alt"></i></button>&nbsp;
                                                                        </span>
                                                                    }

                                                                    {education.length - 1 === i &&
                                                                    <>
                                                                        <button className="btn btn-warning btn-sm mb-2" onClick={handleAddClick}><i className="fas fa-plus-square"></i></button>
                                                                    </>}


                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="single-input ">
                                            <div className="form-group row">
                                                {/* <label htmlFor="first-name" className="col-sm-6 col-form-label">Riwayat Pendidikan <span>*</span></label> */}
                                                <strong className="mb-1">Riwayat Pekerjaan</strong>
                                                <div className="col-sm-12 alert alert-success">
                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Tahun Mulai <span>*</span></label>
                                                    <label htmlFor="first-name" className="col-sm-2 col-form-label">Tahun Berhenti <span>*</span></label>
                                                    <label htmlFor="first-name" className="col-sm-4 col-form-label">Nama Instansi <span>*</span></label>
                                                    <label htmlFor="first-name" className="col-sm-3 col-form-label">Jabatan <span>*</span></label>

                                                    {jobs.map((y, l) => {
                                                        return (
                                                            <div className="box row" key={l}>
                                                                <div className="col-sm-2">
                                                                    <input
                                                                        name="mulai"
                                                                        className="form-control"
                                                                        placeholder="Tahun Mulai"
                                                                        type="number"
                                                                        value={y.mulai}
                                                                        onChange={e => handleInputChangetwo(e, l)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-2">
                                                                    <input
                                                                        name="berhenti"
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Tahun Berhenti "
                                                                        value={y.berhenti}
                                                                        onChange={e => handleInputChangetwo(e, l)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <input
                                                                        name="institusi"
                                                                        className="form-control"
                                                                        placeholder="Nama Instansi "
                                                                        value={y.institusi}
                                                                        onChange={e => handleInputChangetwo(e, l)}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <input
                                                                        name="jabatan"
                                                                        className="form-control"
                                                                        placeholder="Jabatan "
                                                                        value={y.jabatan}
                                                                        onChange={e => handleInputChangetwo(e, l)}
                                                                    />
                                                                </div>
                                                                <div className="btn-box col-sm-1">
                                                                    {jobs.length !== 1 && <span><button
                                                                        className="btn btn-danger btn-sm mb-2"
                                                                        onClick={() => handleRemoveClicktwo(l)}><i className="fas fa-trash-alt"></i></button><br /></span>}

                                                                    {jobs.length - 1 === l && <button className="btn btn-warning btn-sm" onClick={handleAddClicktwo}><i className="fas fa-plus-square"></i></button>}

                                                                </div>

                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="profile-action-btn d-flex flex-wrap align-content-center justify-content-between">
                                    <button className="btn btn-primary" type="submit"><i className="fas fa-save"></i> Update Profile</button>
                                </div>
                            </div>
                        </div>
                        {loadingUpdate && <Loading />}
                    </form>

                    <br />
                </div>
            </div>
        </div>
    </div>
</div>


</div>



    )
}
export default Edit;
