import axios from "axios";
 
export const getList = (title, active, download, page) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .get(`/api/v1/documentmanajemen/list?title=${title}&active=${active}&download=${download}&page=${page}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(res => {
      res.data.status = 'success'
      return res
    }).catch(err => {
      return {
        error: 'Please login again!',
        status: 'failed',
        message: err.message
      }
    })
}

export const getListoptions = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .get('/api/v1/documentmanajemen/listoption', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(res => {
      res.data.status = 'success'
      return res.data
    }).catch(err => {
      return {
        error: 'Please login again!',
        status: 'failed',
        message: err.message
      }
    })
}

export const addToList = documentmanajemen => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .post(
      '/api/v1/documentmanajemen/add',
      {
        title: documentmanajemen.title,
        category: documentmanajemen.category,
        author: documentmanajemen.author,
        page: documentmanajemen.page,
        banner: documentmanajemen.banner,
        document: documentmanajemen.document,
        video: documentmanajemen.video,
        synopsis: documentmanajemen.synopsis,
        download: documentmanajemen.download,
        active: documentmanajemen.active,
        slug: documentmanajemen.slug,
        total:'0',
        viewdownload:'0',
        viewread:'0',
        viewvideo:'0'
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}

export const updateItem = documentmanajemen => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .patch(
      `/api/v1/documentmanajemen/update/${documentmanajemen.id}`,
      {
        title: documentmanajemen.title,
        category: documentmanajemen.category,
        author: documentmanajemen.author,
        page: documentmanajemen.page,
        banner: documentmanajemen.banner,
        document: documentmanajemen.document,
        video: documentmanajemen.video,
        synopsis: documentmanajemen.synopsis,
        download: documentmanajemen.download,
        active: documentmanajemen.active,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}


export const deleteItem = (id) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .delete(`/api/v1/documentmanajemen/delete/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}


