import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { getListparticipant, updateItem } from "../../../actions/eventActions";
import ReactDatatable from '@ashvin27/react-datatable';
import * as XLSX from 'xlsx';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            data: [],
            perPage: 1,
            sertificat:'',
            skp:'',
            idevent:'',
            index:'',
            speakers:[],
            currentPage: 0,
            searchTitle: '',
            searchActive: '',
            statusdownload:true,
            cols: [] ,
            content: []
        };
        this.handleFile = this.handleFile.bind(this);
        this.columns = [
            {
                key: "nomorpendaftaran",
                text: "Nomor pendaftaran",
                className: "nomorpendaftaran",
                align: "left",
                sortable: true,
            },
            {
                key: "nama",
                text: "Nama",
                className: "nama",
                align: "left",
                width: 5,
                sortable: true
            },
            {
                key: "gelarbelakang",
                text: "Gelar belakang",
                className: "gelarbelakang",
                width: 5,
                sortable: true
            },
            {
                key: "email",
                text: "Email",
                width: 5,
                className: "email",
                align: "left",
                sortable: true
            },
            {
                key: "tlp",
                text: "tlp",
                className: "tlp",
                sortable: true,
                align: "left"
            },
            {
                key: "statusdownload",
                text: "Sertificate Status",
                className: "sertificate",
                width: 50,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            {record.statusdownload === false || record.statusdownload === "" || record.statusdownload === null ?
                                <div className="productText">
                                    Belum Tersedia
                                </div>
                                : null}
                            {record.statusdownload !== false && record.statusdownload !== "" && record.statusdownload !== null?
                                <div className="productText">
                                    Sertificate Tersedia
                                </div>
                                : null}
                        </Fragment>
                    );
                }
            },
            {
                key: "sertificate",
                text: "Sertificate",
                className: "tlp",
                sortable: true,
                align: "left"
            },
            {
                key: "skp",
                text: "skp",
                className: "tlp",
                sortable: true,
                align: "left"
            },

            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                
                    return (
                        <Fragment>
                            <button
                                onClick={() => this.handleEditClick(record.idmember, record.sertificate, record.skp, record.statusdownload)}
                                className="btn btn-primary btn-sm"
                                data-toggle="modal" data-target="#paycard"
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                        </Fragment>
                        
                    );
                }
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
        }
        this.onChange = this.onChange.bind(this)
    }

    handleFile = (file) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			/* Parse data */
			const ab = e.target.result;
			const wb = XLSX.read(ab, {type:'array'});
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const dataxl = XLSX.utils.sheet_to_json(ws, {header:2});
			/* Update state */
            // console.log(data)


            this.state.data.map((value, key) => {
           // check if this is the value to be edited
           
            var result = dataxl.filter(item => (item.Email.toLowerCase().indexOf(value.email.toLowerCase()) > -1))
           if (result.length !== 0 ) {
             const list = [...this.state.data];
                list[key]['skp'] = result[0]['SKP'].toString();
                list[key]['sertificate'] = result[0]['Sertifikat'].toString();
                list[key]['remainder'] = 'R'
                list[key]['statusdownload']= true
                this.setState({
                    data:list
                })
           } 
           // otherwise return the original value without editing
           return value;
      })


            
		};
		reader.readAsArrayBuffer(file);
	}

	
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.getAll()
        }else{
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }

    handleEditClick = (id, sertificates, skp,statusdownload, e) => {
        var idupdate 
        for (let index = 0; index < this.state.data.length; index++) {
            if(this.state.data[index]['idmember'] === id)
            {
              idupdate = index  
            }
        }
        this.setState({
                index: idupdate,
                sertificat:sertificates,
                skp:skp,
                statusdownload:statusdownload ? statusdownload : false
            })
    };

    updateHandler = (e) => {
        // e.preventDefault();
        const list = [...this.state.data];
        list[this.state.index]['sertificate'] = this.state.sertificat;
        list[this.state.index]['skp'] = this.state.skp;
        list[this.state.index]['remainder'] = 'R';
        list[this.state.index]['statusdownload']= this.state.statusdownload
        this.setState({
            data:list
        })
        const UpdateRequest = {
            id: this.state.idevent,
            paticipans: this.state.data,
            speakers: this.state.speakers,
            statusdownload: this.state.statusdownload
        }
        updateItem(UpdateRequest)
    };

    updateBulk = () =>
    {
        const UpdateRequest = {
            id: this.state.idevent,
            paticipans: this.state.data,
            speakers: this.state.speakers,
            statusdownload: this.state.statusdownload
        }
        updateItem(UpdateRequest)
    }



    getAll = () => {
        getListparticipant().then(data => {
            if (data.status !== 'success') {
                localStorage.removeItem('usertoken')
                this.props.history.push(`/login`)
            } else {
                var result = data['data']['paticipans']
                var resultspeaker = data['data']['speakers']
                var idevent = data['data']['id']
                this.setState(
                    {
                        data: [...result],
                        speakers: [...resultspeaker],
                        idevent: idevent

                    },
                    () => {
                        // console.log(this.state.items)
                    }
                )
            }
        })
    }
    handleChange = (e) => {
		const files = e.target.files;
		if(files && files[0]) this.handleFile(files[0]);
	};
    
    render() {
        return (
            <div className="page-content">
                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/#">Apps</a></li>
                            <li className="breadcrumb-item active" aria-current="page">List Event</li>
                        </ol>
                    </nav>
                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Participant Management<button className="btn btn-primary card-title-helper blockui-transactions" data-toggle="modal" data-target="#blukupload" type="submit" style={{color:"white"}}><i className="fas fa-upload"></i> Bulk Upload</button></h5>
                                    <nav className="navbar navbar-expand">
                                        <ul className="nav">
                                            <li><NavLink to="/eventeditview" className="nav-link active" href="#0">Event Detail</NavLink></li>
                                            <li><NavLink to="/eventparticipant" className="nav-link">Participant</NavLink></li>
                                            <li><NavLink to="/eventcertificate" className="nav-link">Certificate Template</NavLink></li>
                                            <li><NavLink to="/eventrequester" className="nav-link">Requester</NavLink></li>
                                        </ul>
                                    </nav>
                                    <hr />
                                    <div className="table-responsive container">
                                        <ReactDatatable
                                            className="table"
                                            config={this.config}
                                            records={this.state.data}
                                            columns={this.columns}
                                            extraButtons={this.extraButtons}
                                        />
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="blukupload" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Bulk Upload</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">File Xlsx, Klik link berikut untuk download contoh file <a href="/assets/file/excel.xlsx">link</a></label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="file"
                                                    // accept={SheetJSFT}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-block" data-dismiss="modal" aria-label="Close"  onClick={() => this.updateBulk()}>
                                        Update
                                    </button>
                                    <br></br>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Participant</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">No Sertificate</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.sertificat}
                                                    name="sertificat"
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="kd_promo">SKP</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.skp}
                                                    name="skp"
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" name="statusdownload" checked={this.state.statusdownload}
                                        onChange={e => this.setState({statusdownload:e.target.checked})} type="checkbox" id="autoSizingCheckhome" />
                                        <label className="form-check-label" htmlFor="autoSizingCheckhome">
                                            Status Sertifikat?
                                        </label>
                                    </div>
                                    <button className="btn btn-primary btn-block" data-dismiss="modal" aria-label="Close"  onClick={() => this.updateHandler()}>
                                        Update
                                    </button>
                                    <br></br>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        )
    }
}
