import axios from "axios";
 

export const getList = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .get('/api/v1/footer/list', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(res => {
      res.data.status = 'success'
      return res.data
    }).catch(err => {
      return {
        error: 'Please login again!',
        status: 'failed',
        message: err.message
      }
    })
}



export const addToList = footer => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .post(
      '/api/v1/footer/add',
      {
        title: footer.title,
        type: footer.type,
        url: footer.url,
        page: footer.page,
        parent:footer.parent,
        position: footer.position,
        relative: footer.relative,
        level1: footer.level1,
        level2: footer.level2,
        level3: footer.level3,
        levelup: footer.levelup,
        order:footer.order,
        active: footer.active
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}


export const updateItem = footer => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/footer/update/${footer.id}`,
      {
        title: footer.title,
        type: footer.type,
        url: footer.url,
        page: footer.page,
        parent:footer.parent,
        position: footer.position,
        relative: footer.relative,
        level1: footer.level1,
        level2: footer.level2,
        level3: footer.level3,
        levelup: footer.levelup,
        order:footer.order,
        active: footer.active
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}

export const updateItemorder = footer => {
    var token = JSON.parse(localStorage.getItem('userInfo'));
    return axios
      .put(
        `/api/v1/footer/update/${footer.id}`,
        {
          order:footer.order,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token['data']['token']
          }
        }
      )
      .then(function (response) {
        return response.data;
      })
  }


export const deleteItem = (id) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .delete(`/api/v1/footer/delete/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}


