import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
//master component
// import Footer from "./components/Footer";
import Header from "./components/Header";
import Menu from "./components/Menu";
import Dashboard from "./screens/Dashboard/dashboard";
//user
import ListUser from "./screens/User/list";
import Login from "./screens/Login/login";
import Forgetpassword from "./screens/Login/forgetpassword";
import Resetpassword from "./screens/Login/resetpassword";
//profession
import Professionlist from "./screens/Profession/list";
//categorylibrary
import Categorylibrary from "./screens/Categorylibrary/list";
//member 
import Memberlist from "./screens/Member/list";
import MemberDetailProfile from "./screens/Member/detail_profile";
import MemberDetailaddressadmin from "./screens/Member/detail_address";
import Daftar_activity_admin from "./screens/Member/daftar_activity";
import Eventdetailadmin from "./screens/Member/event_detail";
import AttachmentFile from "./screens/Member/attachmentFile";
// event
import Eventlist from "./screens/Event/list";
import Eventaddview from "./screens/Event/add/add";
import Eventeditview from "./screens/Event/edit/event";
import Eventparticipantview from "./screens/Event/edit/participant";
import Eventcertificate from "./screens/Event/edit/cartificate";
import Eventrequester from "./screens/Event/edit/requester";

//job
import Job from "./screens/Job/list";
import Jobcandidate from "./screens/Job/candidate";
// page manajemen 
import Pagemanajemen from "./screens/Page_managemen/index";
// page Footer manajemen 
import Footermanajemen from "./screens/Footer_manajemen/index";
// document manajemen for elibrary
import Documentmanajemen from "./screens/Documentmanajemen/index";
// repot elibrary
import Repotelib from "./screens/Reportelibrary/index";
import Repotelibday from "./screens/Reportelibrary/perday";
import Repotelibdetail from "./screens/Reportelibrary/detail";
// partner collaboration
import Partner from "./screens/Partner_managemen/index";


function App() {

  return (
    <Router>
      <Route path="/" component={Login} exact />
      <Route path="/login" component={Login} exact />
      <Menu  />
      <div className="page-container">
        <Header/>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/listuser" component={ListUser} />
          <Route path="/professionlist" component={Professionlist}  />
          <Route path="/categorylibrary" component={Categorylibrary}/>
          <Route path="/memberlist" component={Memberlist}  />
          <Route path="/memberdetailprofile" component={MemberDetailProfile}  />
          <Route path="/daftar_activity_admin" component={Daftar_activity_admin}  />
          <Route path="/listaddress" component={MemberDetailaddressadmin}  />
          <Route path="/eventlist" component={Eventlist}  />
          <Route path="/eventaddview" component={Eventaddview}  />
          <Route path="/eventeditview" component={Eventeditview}  />
          <Route path="/eventparticipant" component={Eventparticipantview}  />
          <Route path="/eventcertificate" component={Eventcertificate}  />
          <Route path="/managejob" component={Job}  />
          <Route path="/jobcandidate" component={Jobcandidate}  />
          <Route path="/forgetpassword" component={Forgetpassword}  />
          <Route path="/resetpassword" component={Resetpassword}  />
          <Route path="/eventdeltail" component={Eventdetailadmin}  />
          <Route path="/eventrequester" component={Eventrequester}  />
          <Route path="/attachmentFile" component={AttachmentFile}  />
          <Route path="/page-manajemen" component={Pagemanajemen}  />
          <Route path="/footer-manajemen" component={Footermanajemen}  />
          <Route path="/document-manajemen" component={Documentmanajemen}  />
          <Route path="/repotelib" component={Repotelib}  />
          <Route path="/repotelibday/:id" component={Repotelibday}  />
          <Route path="/repotelibdetail/:id" component={Repotelibdetail}  />
          <Route path="/partner" component={Partner} />
      </div>
    </Router>
  );
} 

export default App;
