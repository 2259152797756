import axios from "axios";
 

export const getList = () => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .get('/api/v1/partners/list', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(res => {
      res.data.status = 'success'
      return res.data
    }).catch(err => {
      return {
        error: 'Please login again!',
        status: 'failed',
        message: err.message
      }
    })
}

export const addToList = cms => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .post(
      '/api/v1/partners/add',
      {
        title: cms.title,
        logo: cms.logo,
        priority: cms.priority,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}


export const updateItem = cms => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .put(
      `/api/v1/partners/update/${cms.id}`,
      {
        title: cms.title,
        logo: cms.logo,
        priority: cms.priority,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token['data']['token']
        }
      }
    )
    .then(function (response) {
      return response.data;
    })
}


export const deleteItem = (id) => {
  var token = JSON.parse(localStorage.getItem('userInfo'));
  return axios
    .delete(`/api/v1/partners/delete/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token['data']['token']
      }
    })
    .then(function (response) {
      return response.data;
    }).catch(err => {
      return {
        error: 'Error to add',
        status: 'failed',
        message: err.message
      }
    })
}