import React, { Component } from 'react';
import { getDetail } from "../../actions/eventActions";
import { PDFDownloadLink, Page, Text, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Parser from 'html-react-parser';
const bucket = process.env.REACT_APP_API_KEY


const styles = StyleSheet.create({
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    aset:{
        width: 750, height: 550, padding: 20, textAlign: 'center', border: '5px solid #787878'
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
      },
  });

  

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: '',
            title: '',
            type: '',
            date: '',
            time: '',
            user:'',
            moderator: '',
            skp: '',
            nosertificate:'',
            jmlhmateri: '',
            nomorpendaftaran: '',
            linkmeet: '',
            meetid: '',
            passcode: '',
            sertificate: '',
            skppeserta:'',
            speakers: [],
            desc: '',
            gelardepan:'',
            gelarbelakang:''
        };
    }
    componentDidMount() {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo && userInfo['data']['user']['role'] === "ADMIN") {
            this.receivedData()
        }else{
            localStorage.removeItem('userInfo')
            this.props.history.push("/login");
        }
    }
     Br = () => "\n";

    MyDoc = () => (
        <Document>
        <Page size="LETTER" orientation="landscape">
          <Image src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/sertificate/"+this.state.sertificate} style={styles.pageBackground} />
            <Text>
            <Text style={styles.aset}>
            {this.Br()}{this.Br()}{this.Br()}{this.Br()}{this.Br()}{this.Br()}{this.Br()}
            {/* <Text style={{fontSize: 50, fontWeight: 'bold'}}>Certificate of Completion</Text>{this.Br()}{this.Br()}{this.Br()} */}
            <Text style={{fontSize: 20}}>{this.state.nosertificate}</Text>
            {this.Br()}{this.Br()}{this.Br()}{this.Br()}
            <Text style={{fontSize: 20}}><b>{this.state.gelardepan && this.state.gelardepan+'.' } {this.state.user}{this.state.gelarbelakang && ', '+this.state.gelarbelakang}</b></Text>{this.Br()}{this.Br()}{this.Br()}{this.Br()}
            {/* <Text style={{fontSize: 25}}><i>has completed the course</i></Text> {this.Br()}{this.Br()} */}
            {/* <Text style={{fontSize: 25}}>{this.state.title}</Text>{this.Br()}{this.Br()} */}
            </Text>
            </Text>
        </Page>
      </Document>
      );
    receivedData() {

        getDetail().then(res => {
            if (res.status !== 'success') {
                localStorage.removeItem('recent')
                this.props.history.push(`/`)
            } else {
              

                this.setState({
                    banner: res['data']['banner'],
                    title: res['data']['title'],
                    type: res['data']['type'],
                    date: res['data']['date'],
                    time: res['data']['time'],
                    moderator: res['data']['moderator'],
                    skp: res['data']['skp'],
                    jmlhmateri: res['data']['speakers'].length,
                    speakers: res['data']['speakers'],
                    desc: res['data']['desc'],
                    meetid: res['data']['meetid'],
                    passcode: res['data']['passcode'],
                    linkmeet: res['data']['linkmeet'],
                    sertificate: res['data']['sertificate'],
                })
                var participan = res['data']['paticipans'] ? res['data']['paticipans'] : [];
                var masterinfo = JSON.parse(localStorage.getItem('recentmember'));
                var id = masterinfo[0]['id']
                for (let index = 0; index < participan.length; index++) {
                    if (res['data']['paticipans'][index]['idmember'] === id) {
                        this.setState({
                            nomorpendaftaran: res['data']['paticipans'][index]['nomorpendaftaran'],
                            nosertificate: res['data']['paticipans'][index]['sertificate'],
                            skppeserta: res['data']['paticipans'][index]['skp'],
                            gelardepan: res['data']['paticipans'][index]['gelardepan'],
                            gelarbelakang: res['data']['paticipans'][index]['gelarbelakang'],
                            user: res['data']['paticipans'][index]['nama']

                            
                        })
                    }
                }


            }

        })

    }


    render() {
        return (
            <div className="page-content">
                <div className="page-info">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                             <li className="breadcrumb-item"><a href="/memberlist">Member</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail Event Member</li>
                        </ol>
                    </nav>
                </div>
                <div className="main-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-transactions">
                                <div className="card-body">
                                    <h5 className="card-title">Detail Event<a href="/#" className="card-title-helper blockui-transactions"><i className="material-icons">refresh</i></a></h5>
                                     <h5 className="card-title">Nomor Pendaftaran : {this.state.nomorpendaftaran}</h5>
                                    <br/>
                                     <div className="row">
                                        <div className="col-md-6">
                                            <img src={"https://prosehat-digihealth.s3.us-east-2.amazonaws.com/"+bucket+"/event/"+this.state.banner} className="img-fluid" alt="Responsive"/>
                                            <br/><br/>
                                            <h5 className="card-title">Deskripsi</h5>
                                            <p>{Parser(this.state.desc)}.</p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <h4 htmlFor="inputEmail3" className="card-title col-sm-12 ">{this.state.title}</h4>
                                            </div>
                                            <div className="row">
                                                 <h5 htmlFor="inputEmail3" className="card-title col-sm-6 "><i class="fa fa-calendar" aria-hidden="true"></i> {this.state.date}</h5>
                                                <h5 htmlFor="inputEmail3" className="card-title col-sm-6 "><i class="fa fa-clock" aria-hidden="true"></i> {this.state.time}</h5>
                                            </div>
                                            <div className="row">
                                                 <h5 htmlFor="inputEmail3" className="card-title col-sm-4 ">Speaker</h5>
                                                <h5 htmlFor="inputEmail3" className="card-title col-sm-8"> 
                                                {this.state.speakers.map((value, key) =>
                                                    <strong>{value.name}</strong>
                                                )}</h5>
                                            </div>
                                            <div className="row">
                                                 <h5 htmlFor="inputEmail3" className="card-title col-sm-4">Moderator</h5>
                                                <h5 htmlFor="inputEmail3" className="card-title col-sm-8">{this.state.moderator}</h5>
                                            </div>
                                            <div className="row">
                                                 <h5 htmlFor="inputEmail3" className="card-title col-sm-4">Point SKP</h5>
                                                <h5 htmlFor="inputEmail3" className="card-title col-sm-8">{this.state.skp}</h5>
                                            </div>
                                            <div className="row">
                                                 <h5 htmlFor="inputEmail3" className="card-title col-sm-4">Jumlah Materi</h5>
                                                <h5 htmlFor="inputEmail3" className="card-title col-sm-8">{this.state.jmlhmateri}</h5>
                                            </div>
                                            <hr/>
                                            <h5 htmlFor="inputEmail3" className="card-title">Materi</h5>
                                            {this.state.speakers.map((value, key) =>
                                            <div className="row" key={key}>
                                                 <h5 htmlFor="inputEmail3" className="card-title col-sm-4">{value.name}</h5>
                                                <h5 htmlFor="inputEmail3" className="card-title col-sm-8">{value.materi}</h5>
                                            </div>
                                            )}
                                            <hr/>
                                            <div className="row">
                                                 <h5 htmlFor="inputEmail3" className="card-title col-sm-4">Meeting Room</h5>
                                                <h5 htmlFor="inputEmail3" className="card-title col-sm-8">{this.state.linkmeet}</h5>
                                            </div>
                                            <div className="row">
                                                 <h5 htmlFor="inputEmail3" className="card-title col-sm-4">Meeting ID</h5>
                                                <h5 htmlFor="inputEmail3" className="card-title col-sm-8">{this.state.meetid}</h5>
                                            </div>
                                            <div className="row">
                                                 <h5 htmlFor="inputEmail3" className="card-title col-sm-4">Passcode</h5>
                                                <h5 htmlFor="inputEmail3" className="card-title col-sm-8">{this.state.passcode}</h5>
                                            </div>
                                            <div className="form-group row">
                                            {this.state.sertificate !== "-" || this.state.sertificate !== "" ?
                                                <PDFDownloadLink document={this.MyDoc()} className="btn btn-primary btn-sm btn-block" style={{color:"white"}}  fileName={'sertificate '+this.state.type+' '+this.state.title+'.pdf'} >
                                                {({ blob, url, loading, error }) => (loading ? 'Sertificate Belum Tersedia' : 'Download Certificate')}
                                                </PDFDownloadLink>
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
